/** @format */

import { useEffect, useRef } from "react";

const Checkbox = ({
  indeterminate,
  checked,
  ...rest
}: {
  indeterminate: boolean;
  checked: boolean;
  [x: string]: any;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    if (!checked) {
      ref.current.indeterminate = indeterminate;
    } else {
      ref.current.indeterminate = false;
    }
  }, [ref, indeterminate, checked]);
  return <input type="checkbox" ref={ref} checked={checked} {...rest} />;
};

export default Checkbox;
