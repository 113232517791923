/** @format */

import { useActiveFeatureFlags } from "posthog-js/react";
import { createContext, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDriverSelector from "../../utils/DetailDriverSelector";
import { componentStateDefault } from "./grid/gridWrapperDefaults";
import componentMap from "./widgets/WidgetsMap";
import FilterLaps from "./widgets/FilterLaps";

export const GridContext = createContext<{
  componentState: ComponentState;
  setComponentState: React.Dispatch<React.SetStateAction<ComponentState>>;
}>({
  componentState: componentStateDefault,
  setComponentState: () => {},
});

const WidgetWrapper = () => {
  const { widget } = useParams();
  const [componentState, setComponentState] =
    useState<ComponentState>(componentStateDefault);
  const flags = useActiveFeatureFlags();
  // find component in componentMap based on lowercase component key
  const cKey = Object.keys(componentMap).find(
    key => key.toLocaleLowerCase() === widget?.toLocaleLowerCase()
  );
  if (!cKey) {
    return <div>Component not found</div>;
  }
  const { Component, flag, includeDetailDriver, includeFilterLaps } =
    componentMap[cKey];
  if (flag && !flags?.includes(flag)) {
    return <div>Component not found</div>;
  }
  if (includeDetailDriver) {
    return (
      <div>
        <DetailDriverSelector />
        <Component />
      </div>
    );
  }
  if (includeFilterLaps) {
    return (
      <GridContext.Provider value={{ componentState, setComponentState }}>
        <div className="grid grid-cols-4">
          <div className="col-span-1">
            <FilterLaps detailDriver />
          </div>
          <div className="col-span-3">
            <Component />
          </div>
        </div>
      </GridContext.Provider>
    );
  }
  return <Component />;
};

export default WidgetWrapper;
