/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/sharp-regular-svg-icons";
import { useSortBy, useTable } from "react-table";

const StandingsTable = ({
  columns: userColumns,
  tableClassName,
  data,
  initialState = {},
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data,
      initialState,
    },
    useSortBy
    // for sub components too!
  );
  return (
    <table {...getTableProps()} className={`${tableClassName} table-auto table-hover`}>
      <thead className="table-head">
        {headerGroups.map((headerGroup, idx) => {
          return (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="sticky top-0 bg-black"
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={(column.className || "") + (idx !== 0 ? " ff" : "")}
                >
                  {column.ff ? (
                    <div className="rotated-header-container">
                      <div className="rotated-header-content">
                        <div className="chevron inline-block">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                size="sm"
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faChevronUp}
                                size="sm"
                                className="mr-1"
                              />
                            )
                          ) : null}
                        </div>
                        <div className="name inline-block">
                          {column.render("Header")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              size="sm"
                              className="mr-1"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              size="sm"
                              className="mr-1"
                            />
                          )
                        ) : null}
                      </span>
                      {column.render("Header")}
                    </>
                  )}
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()} className="h-full">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="even:bg-black odd:bg-gray-900 hover:bg-gray-600"
            >
              {row.cells.map((cell, idx) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`py-0.5 px-1 w-12 ${
                      idx === 0 ? "border-r" : "border-x"
                    } ${cell.column.className || ""}`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default StandingsTable;
