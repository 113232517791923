/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/sharp-regular-svg-icons";
import { isEqual } from "lodash";
import { useActiveFeatureFlags } from "posthog-js/react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useVehiclesDetail } from "../../../api/runsApi";
import {
  useAddFavoriteDriver,
  useFavoriteVehicleIDsForRun,
  useRemoveFavoriteDriver,
} from "../../../api/userAPI";
import FormatArrow from "../../../utils/FormatArrow";
import FormatDelta from "../../../utils/FormatDelta";
import Loading from "../../../utils/Loading";
import { PIT_STOP_TYPES } from "../../../utils/constants";
import useLiveParams from "../../../utils/useLiveParams";
import { getLogo } from "../../../utils/utility";

const DriverDetails: React.FC<WidgetProps> = () => {
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const { runID } = useLiveParams();
  const {
    data: vehicleData,
    status: vehicleStatus,
    error: vehicleError,
  } = useVehiclesDetail(runID, detailVehicleID);
  const flags = useActiveFeatureFlags();
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const addFavoriteDriver = useAddFavoriteDriver(runID);
  const removeFavoriteDriver = useRemoveFavoriteDriver(runID);

  const isFaveDriver = vehicleData
    ? favoriteVehicleIDs?.includes(vehicleData?.id)
    : false;
  const toggleFavoriteDriver = () => {
    if (!vehicleData) throw new Error("No vehicle data");
    if (isFaveDriver) {
      removeFavoriteDriver(vehicleData.id);
    } else {
      addFavoriteDriver(vehicleData.id);
    }
  };
  return detailVehicleID ? (
    <Loading
      statuses={[vehicleStatus, favoriteVehiclesStatus]}
      errors={[vehicleError, favoriteVehiclesError]}
    >
      {!detailVehicleID ? (
        <h1 className="text-center mt-2 text-xl">
          Select a driver by clicking on their row
        </h1>
      ) : (
        <>
          <div className="mb-1 mx-1">
            <img
              className="w-auto object-contain object-center float-right mt-1 max-h-[150px] max-w-[25%]"
              src={vehicleData?.driver?.image}
              alt={`${vehicleData?.driver?.name}`}
            />
            <h3 className="text-2xl py-1">
              {flags?.includes("faveVehicles") && (
                <button
                  title="Toggle Favorite Driver"
                  type="button"
                  onClick={toggleFavoriteDriver}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    size="sm"
                    className={`cursor-pointer mr-1 ${
                      isFaveDriver ? "text-yellow-500" : "text-gray-200"
                    }`}
                  />
                </button>
              )}
              {vehicleData?.number === vehicleData?.driver?.badge ? (
                <img
                  className="h-6 w-auto object-contain inline bg-black rounded"
                  src={vehicleData?.driver?.badgeImage}
                  alt={vehicleData?.driver?.badge}
                />
              ) : (
                <b>{vehicleData?.number}</b>
              )}{" "}
              <span className="">{vehicleData?.driver?.name}</span>
            </h3>
            <h4 className="text-xl">
              <img
                src={getLogo(vehicleData?.manufacturer) || ""}
                alt={vehicleData?.manufacturer}
                className="object-contain object-center mr-1 h-5 w-auto inline filter"
              />
              {vehicleData?.team}
            </h4>
            <h4 className="italic">{vehicleData?.sponsor}</h4>
          </div>

          {(vehicleData?.pitstops?.length ?? 8) > 0 && (
            <div className="mt-2 overflow-x-auto w-full">
              <h5 className="text-xl leading-none mt-1 ml-2 mb-1">Pit Stops</h5>
              <table className="text-center font-mono min-w-full border border-collapse border-gray-500">
                <thead>
                  <tr className="whitespace-nowrap sticky top-0 text-base bg-black border-b border-gray-500">
                    <th className="border-r border-gray-500">Lap</th>
                    <th className="border-x border-gray-500">Type</th>
                    <th className="border-x border-gray-500">Time</th>
                    <th className="border-x border-gray-500">Stopped</th>
                    <th className="border-x border-gray-500">Δ Pos.</th>
                    <th className="border-l border-gray-500">Laps</th>
                  </tr>
                </thead>
                <tbody className="text-sm leading-tight">
                  {vehicleData?.pitstops?.map(
                    (
                      {
                        lap,
                        type,
                        time,
                        flag,
                        duration,
                        runningTime,
                        pitOutPosition,
                        positionChange,
                      }: VehicleDetailPitStop,
                      index: number
                    ) => (
                      <tr key={lap} className="even:bg-black odd:bg-gray-900">
                        <td className="border-x border-gray-500">
                          <div className="rounded px-1 inline-block" data-flag={flag}>
                            {lap}
                          </div>
                        </td>
                        <td className="border-x border-gray-500">
                          {PIT_STOP_TYPES[type] || "-"}
                        </td>
                        <td className="border-x border-gray-500">
                          {time?.toFixed(2) || "-"}
                        </td>
                        <td className="border-x border-gray-500">
                          {duration?.toFixed(2) || "-"}
                        </td>
                        <td className="border-x border-gray-500">
                          {pitOutPosition?.toFixed(0)?.padStart(2, "\u2007") || "-"}
                          <FormatArrow className="text-xs inline-block" showEq>
                            {-(positionChange || 0)}
                          </FormatArrow>
                        </td>
                        <td className="border-x border-gray-500">
                          {lap - (vehicleData.pitstops[index + 1]?.lap || 0) || "-"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
          {(vehicleData?.laps?.length ?? 0) > 0 && (
            <div className="mt-2 overflow-x-auto w-full">
              <h5 className="text-xl mt-1 ml-2 mb-2">All Laps</h5>
              <table className="text-center min-w-full font-mono border-collapse border-gray-500 border">
                <thead>
                  <tr className="whitespace-nowrap text-base sticky top-0 bg-black border-b border-gray-500">
                    <th className="border border-gray-500">Lap</th>
                    <th className="border border-gray-500">Pos</th>
                    <th className="border border-gray-500">Time</th>
                    <th className="border border-gray-500">Δ Avg.</th>
                    <th className="border border-gray-500">Δ Best</th>
                  </tr>
                </thead>
                <tbody className="text-sm leading-tight">
                  {vehicleData?.laps
                    ?.sort((a, b) => b.lapNumber - a.lapNumber)
                    .map(
                      ({
                        lapNumber,
                        runningPosition,
                        flag,
                        positionChange,
                        lapTime,
                        deltaToPersonalBest,
                        deltaToPersonalAverage,
                        inPit,
                        inLap,
                        outLap,
                        slowLap,
                        status,
                      }: VehicleDetailVehicleLap) => (
                        <tr key={lapNumber} className="even:bg-black odd:bg-gray-900">
                          <td className="border-x border-gray-500 bg-opacity-50">
                            <div
                              className={`rounded px-1 inline-block
                             ${
                               inPit || inLap || outLap
                                 ? "border-blue-500 border"
                                 : slowLap
                                 ? "border-pink-500 border"
                                 : ""
                             }
                            `}
                              data-flag={flag}
                            >
                              {lapNumber}
                            </div>
                          </td>
                          <td className="border-x border-gray-500 text-center whitespace-nowrap">
                            {runningPosition.toFixed(0).padStart(2, "\u2007")}
                            <FormatArrow className="inline-block">
                              {positionChange}
                            </FormatArrow>
                          </td>
                          <td
                            className={`border-x border-gray-500 text-center ${
                              deltaToPersonalBest === 0 ? "text-green-500" : ""
                            }`}
                          >
                            {lapTime?.toFixed(2)?.padStart(6, "\u2007")}
                          </td>
                          <td className={"border-x border-gray-500"}>
                            {!slowLap && (
                              <FormatDelta
                                fixed={2}
                                formatOptions={{
                                  padStart: 5,
                                  zero: "-",
                                }}
                                className="text-center"
                              >
                                {deltaToPersonalAverage}
                              </FormatDelta>
                            )}
                          </td>
                          <td className={`border-x border-gray-500`}>
                            {!slowLap && (
                              <FormatDelta
                                fixed={2}
                                formatOptions={{
                                  padStart: 5,
                                  zero: "-",
                                }}
                                gtColor=""
                                className="text-center"
                              >
                                {deltaToPersonalBest}
                              </FormatDelta>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  {/* {vehicle.flagPeriods.map(period => (
                <React.Fragment key={period?.start}>
                  <tr
                    className={`${
                      period.flag === 1
                        ? "bg-green-700"
                        : period.flag === 2
                        ? "bg-yellow-400 text-black"
                        : ""
                    } border-t font-bold `}
                  >
                    <td colSpan={3} className="border-r">
                      {period?.start} - {period?.end}
                    </td>
                    <td className="text-center border-x">
                      {period.periodLaps?.medianTime?.toFixed(3)}
                    </td>
                    <td className="text-center border-l">
                      {period.periodLaps?.bestTime?.toFixed(3)}
                    </td>
                  </tr>
                  {period?.periodLaps?.laps?.map(
                    ({ Lap, pitLap, RunningPos, posChange, LapTime }) => {
                      const medDelta = LapTime - period.periodLaps.medianTime;
                      const bestDelta = LapTime - period.periodLaps.bestTime;
                      return (
                        <tr
                          key={Lap}
                          className="even:bg-white even:dark:bg-black odd:bg-gray-100 odd:dark:bg-gray-900"
                        >
                          <td className={`border-r ${pitLap ? "text-pink-500" : ""}`}>
                            {Lap}
                          </td>
                          <td className="border-x text-center">
                            {RunningPos.toFixed(0).padStart(2, "\u2007")}
                            <span
                              className={`text-xs ml-1 ${
                                posChange < 0
                                  ? "text-green-700"
                                  : posChange > 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatPlus(-posChange, 0, "").padEnd(3, "\u2007")}
                            </span>
                          </td>
                          <td
                            className={`border-x text-center ${
                              bestDelta === 0 ? "text-green-500" : ""
                            }`}
                          >
                            {LapTime?.toFixed(3)?.padStart(7, "\u2007")}
                          </td>
                          <td
                            className={`border-x text-center ${
                              medDelta <= 0 ? "text-green-700" : "text-red-500"
                            }`}
                          >
                            {formatPlus(medDelta)?.padStart(7, "\u2007")}
                          </td>
                          <td className={`border-l text-center`}>
                            {formatPlus(bestDelta)?.padStart(7, "\u2007")}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </React.Fragment>
              ))} */}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </Loading>
  ) : (
    <h1 className="text-center mt-2 text-xl">
      Select a driver by clicking on their row
    </h1>
  );
};

export default React.memo(DriverDetails, isEqual);
