/** @format */

import React from "react";
import * as Sentry from "@sentry/react";
import { Disclosure } from "@headlessui/react";
import Crash from "../assets/crash_low.png";

interface ErrorBoundaryProps {
  error?: Error;
  componentStack?: string;
  eventId?: string;
  resetError?: any;
  children?: any;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  error,
  componentStack,
  resetError,
  eventId,
  children = null,
}) =>
  error ? (
    <div className="absolute inset-0 flex justify-center align-middle">
      <div className="bg-white rounded-3xl items-center flex flex-col justify-center h-min my-auto">
        <h1 className="text-center text-2xl font-bold mb-1 text-red-600">
          Something went wrong
        </h1>
        <h2 className="text-center text-1xl mb-1 text-red-600 mx-2">
          {error.toString()}
        </h2>
        <Disclosure>
          {({ open }) => (
            <>
              <div className="w-full flex justify-between mx-10 px-10 mb-5">
                <button
                  className="text-red-600 border border-red-600 rounded-md px-2 py-1"
                  onClick={() => {
                    resetError();
                  }}
                >
                  Reload
                </button>
                <Disclosure.Button className="text-red-600 border border-red-600 rounded-md px-2 py-1">
                  {open ? "Hide" : "Show"} stack trace
                </Disclosure.Button>
                {error && (
                  <button
                    className="bg-red-500 rounded-md text-white px-2 py-1"
                    onClick={() => Sentry.showReportDialog({ eventId: eventId })}
                  >
                    Report feedback
                  </button>
                )}
              </div>
              <div
                className={`grid ${
                  open ? "grid-cols-2" : "grid-cols-1"
                } grid-flow-col auto-cols-min auto-rows-min grid-rows-1 align-middle justify-center w-full shrink`}
              >
                <img src={Crash} alt="error" className="object-contain w-1/2 m-auto" />
                <Disclosure.Panel>
                  <pre className="text-red-600 text-xs">{componentStack}</pre>
                </Disclosure.Panel>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  ) : (
    children
  );

export default ErrorBoundary;
