/** @format */

import { useQuery } from "@tanstack/react-query";
import galaxieClient from "./galaxieClient";

export const useRacesList = (seriesID: string, year: string, options = {}) =>
  useQuery<Race[]>({
    queryKey: ["races", seriesID, year],
    queryFn: async () =>
      await galaxieClient
        .get(`/races/?series=${seriesID}&year=${year}`)
        .then(res => res.data),
    refetchInterval: 1000 * 60,
    ...options,
  });

export const useStandings = (seriesID: string, year: string, options = {}) =>
  useQuery<Standings[]>({
    queryKey: ["standings", seriesID, year],
    queryFn: async () =>
      await galaxieClient
        .get(`/standings/?series=${seriesID}&year=${year}`)
        .then(res => res.data),
    refetchInterval: 1000 * 60,
    ...options,
  });
