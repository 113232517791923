/** @format */

import { faObjectUngroup } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectGroup, faSort } from "@fortawesome/sharp-regular-svg-icons";
import {
  faCaretDown,
  faCaretRight,
  faSortDown,
  faSortUp,
} from "@fortawesome/sharp-solid-svg-icons";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { usePitStops } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import FormatArrow from "../../../utils/FormatArrow";
import Loading from "../../../utils/Loading";
import { PIT_STOP_TYPES } from "../../../utils/constants";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus } from "../../../utils/utility";
import FormatDelta from "../../../utils/FormatDelta";
import { sortBy } from "lodash";
import { useMemo } from "react";

type AdjustedPitStop = PitStop & {
  primaryColor: string | undefined;
  secondaryColor: string | undefined;
};

const columnHelper = createColumnHelper<AdjustedPitStop>();

const columns = [
  columnHelper.accessor("leaderLap", {
    header: "Lap",
    aggregationFn: "extent",
    cell: ({ getValue, row: { original: pitStop } }) => (
      <div className="rounded px-1 inline-block" data-flag={pitStop.flag}>
        {getValue().toFixed(0)}
      </div>
    ),
  }),
  columnHelper.accessor("vehicleNumber", {
    header: "#",
    cell: ({ row: { original: pitStop } }) => (
      <div
        className={`w-4 h-4 leading-4 text-[.65rem] rounded border overflow-hidden font-sans text-center font-bold tracking-tighter align-middle ml-auto inline-block`}
        style={{
          color: pitStop.secondaryColor,
          backgroundColor: pitStop.primaryColor,
          borderColor: pitStop.secondaryColor,
        }}
      >
        {pitStop.vehicleNumber}
      </div>
    ),
  }),
  columnHelper.accessor("type", {
    header: "Type",
    cell: ({ getValue }) => (
      <div className="text-center">{PIT_STOP_TYPES[getValue()]}</div>
    ),
    meta: {
      filterVariant: "select",
    },
    aggregationFn: "uniqueCount",
    enableSorting: false,
  }),
  columnHelper.accessor("pitInPosition", {
    header: "In",
    cell: ({ row: { original: pitStop } }) => (
      <div className="text-right">{pitStop.pitInPosition?.toFixed(0)}</div>
    ),
    enableGrouping: false,
    aggregationFn: "extent",
    aggregatedCell: props => {
      const value = props.getValue();
      const [min = 0, max = 0] = Array.isArray(value) ? value : [];
      return (
        <div>
          {min === max ? (
            <div className="text-center">{min}</div>
          ) : (
            <>
              <div className="w-1/2 text-left inline-block">{min}</div>
              <div className="w-1/2 text-right inline-block">{max}</div>
            </>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("positionChange", {
    header: "Δ",
    cell: ({ row: { original: pitStop } }) => (
      <FormatArrow showEq className="text-center">
        {-(pitStop.positionChange || 0)}
      </FormatArrow>
    ),
    aggregationFn: "extent",
    enableGrouping: false,
    aggregatedCell: props => {
      const value = props.getValue();
      const [min = 0, max = 0] = Array.isArray(value) ? value : [];
      return (
        <div>
          {" "}
          {min === max ? (
            <FormatArrow showEq>{-min}</FormatArrow>
          ) : (
            <>
              <FormatArrow showEq className="text-left inline-block">
                {-min}
              </FormatArrow>
              <FormatArrow showEq className="text-right inline-block">
                {-max}
              </FormatArrow>
            </>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("pitOutPosition", {
    header: "Out",
    cell: ({ row: { original: pitStop } }) => (
      <div className="text-left">{pitStop.pitOutPosition?.toFixed(0)}</div>
    ),
    aggregationFn: "extent",
    enableGrouping: false,
    aggregatedCell: props => {
      const value = props.getValue();
      const [min = 0, max = 0] = Array.isArray(value) ? value : [];
      return (
        <div>
          {min === max ? (
            <div className="text-center">{min}</div>
          ) : (
            <>
              <div className="w-1/2 text-left inline-block">{min}</div>
              <div className="w-1/2 text-right inline-block">{max}</div>
            </>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("time", {
    header: "Tot",
    cell: ({ row: { original: pitStop } }) => (
      <div className="text-right">
        {pitStop.time?.toFixed(2).padStart(6, "\u00A0").padEnd(7, "\u00A0")}
      </div>
    ), // ToDo Add BarCell
    aggregationFn: "median",
    aggregatedCell: ({ getValue }) => (
      <span>{getValue().toFixed(2).padStart(6, "\u00A0").padEnd(7, "\u00A0")}</span>
    ),
    enableGrouping: false,
  }),
  columnHelper.accessor("duration", {
    header: "Stop",
    cell: ({ row: { original: pitStop } }) => (
      <span>
        {pitStop.duration?.toFixed(2).padStart(6, "\u00A0").padEnd(7, "\u00A0")}
      </span>
    ), // ToDo Add BarCell
    aggregationFn: "median",
    aggregatedCell: ({ getValue }) => (
      <span>{getValue().toFixed(2).padStart(6, "\u00A0").padEnd(7, "\u00A0")}</span>
    ),
    enableGrouping: false,
  }),
  columnHelper.accessor("deltaFastestTypeStop", {
    header: "Δ Fast",
    cell: ({ row: { original: pitStop } }) => (
      <FormatDelta fixed={2}>{pitStop.deltaFastestTypeStop}</FormatDelta>
    ), // ToDo Add BarCell
    aggregationFn: "median",
    aggregatedCell: ({ getValue }) => <span>{formatPlus(getValue(), 2)}</span>,
    enableGrouping: false,
  }),
];

// ToDo Add Coloring For Fastest Stops in Group

const PitStopsTable: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const {
    data: favoriteVehicleIDs = [] as string[],
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const {
    data: pitStops = [] as AdjustedPitStop[],
    error: pitStopsError,
    status: pitStopsStatus,
  } = usePitStops(runID);

  return (
    <Loading
      statuses={[favoriteVehiclesStatus, pitStopsStatus]}
      errors={[favoriteVehiclesError, pitStopsError]}
    >
      <Table data={pitStops} favoriteVehicleIDs={favoriteVehicleIDs} />
    </Loading>
  );
};

export default PitStopsTable;

interface TableProps {
  data: AdjustedPitStop[];
  favoriteVehicleIDs: string[];
}

const Table: React.FC<TableProps> = ({ data, favoriteVehicleIDs }) => {
  const stops = useMemo(() => data, [data]);
  const table = useReactTable({
    columns,
    data: stops,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //provide a sorting row model
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      sorting: [
        { id: "leaderLap", desc: true },
        { id: "pitOutPosition", desc: false },
      ],
      grouping: ["leaderLap"],
      //   expanded: true,
    },
  });

  return (
    <table className="w-full font-mono text-sm overflow-x-auto leading-tight border border-collapse border-gray-500">
      <thead className="sticky text-base top-0 bg-black">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th
                className="border-x border-gray-500 whitespace-nowrap w-0"
                key={header.id}
              >
                {header.isPlaceholder ? null : (
                  <div
                    className={
                      header.column.getCanSort() ? "cursor-pointer select-none" : ""
                    }
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === "asc"
                          ? "Sort ascending"
                          : header.column.getNextSortingOrder() === "desc"
                          ? "Sort descending"
                          : "Clear sort"
                        : undefined
                    }
                  >
                    {header.column.getCanGroup() ? (
                      // If the header can be grouped, let's add a toggle
                      <button
                        {...{
                          onClick: header.column.getToggleGroupingHandler(),
                          style: {
                            cursor: "pointer",
                          },
                        }}
                      >
                        {header.column.getIsGrouped() ? (
                          <>
                            <FontAwesomeIcon icon={faObjectUngroup} />{" "}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faObjectGroup} />
                          </>
                        )}
                      </button>
                    ) : null}
                    {"\u00A0"}
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <button onClick={header.column.getToggleSortingHandler()}>
                      {"\u00A0"}
                      {{
                        asc: <FontAwesomeIcon icon={faSortUp} />,
                        desc: <FontAwesomeIcon icon={faSortDown} />,
                      }[header.column.getIsSorted() as string] ??
                        (header.column.getCanSort() && (
                          <FontAwesomeIcon icon={faSort} />
                        ))}
                    </button>
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr
              key={row.id}
              className={`even:bg-black odd:bg-gray-900
              ${favoriteVehicleIDs?.includes(row.original.vehicleId) ? "border" : ""}
                hover:bg-gray-800 hover:brightness-150 transition-colors duration-200 ease-in-out
            `}
              style={{
                borderColor: favoriteVehicleIDs?.includes(row.original.vehicleId)
                  ? row.original.primaryColor
                  : "",
              }}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    key={cell.id}
                    className="px-1 border-x border-gray-500 w-0 whitespace-nowrap"
                  >
                    {cell.getIsGrouped() ? (
                      // If it's a grouped cell, add an expander and row count
                      <>
                        <button
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: {
                              cursor: row.getCanExpand() ? "pointer" : "normal",
                            },
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretRight} />
                          )}{" "}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())} (
                          {row.subRows.length})
                        </button>
                      </>
                    ) : cell.getIsAggregated() ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map(footerGroup => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.footer, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};
