/** @format */

import { faArrowDown, faArrowUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/sharp-regular-svg-icons";

interface FormatArrowProps {
  className?: string;
  ltColor?: string;
  gtColor?: string;
  eqColor?: string;
  showEq?: boolean;
  children: number | null;
}

const FormatArrow: React.FC<FormatArrowProps> = ({
  className = "",
  ltColor = "text-green-500",
  gtColor = "text-red-500",
  eqColor = "text-gray-500",
  showEq = false,
  children,
}) => {
  if (children === undefined || children === null || isNaN(children)) {
    return (
      <div className={eqColor + " whitespace-nowrap " + className}>
        {"\u2007\u2007\u2007\u2007"}
      </div>
    );
  } else if (children === 0) {
    return showEq ? (
      <div className={eqColor + " whitespace-nowrap " + className}>
        <FontAwesomeIcon icon={faEquals} fixedWidth />
        {"\u2007\u2007"}
      </div>
    ) : (
      <div className={eqColor + " whitespace-nowrap " + className}>
        {"\u2007\u2007\u2007\u2007"}
      </div>
    );
  } else if (typeof children === "number") {
    const color = children > 0 ? gtColor : ltColor;
    return (
      <div className={color + " whitespace-nowrap " + className}>
        {children > 0 ? (
          <FontAwesomeIcon icon={faArrowDown} fixedWidth />
        ) : (
          <FontAwesomeIcon icon={faArrowUp} fixedWidth />
        )}
        {Math.abs(children).toFixed(0).padStart(2, "\u2007")}
      </div>
    );
  }
  return children;
};

export default FormatArrow;
