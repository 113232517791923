/** @format */

export const isDev = import.meta.env.MODE === "development";

export const devices = {
  ultrawide: {
    landscape: {
      width: 3440,
      height: 1440,
      cols: 16,
    },
  },
  desktop: {
    landscape: {
      width: 2560,
      height: 1440,
      cols: 12,
    },
  },
  laptop: {
    landscape: {
      width: 1640,
      height: 1000,
      cols: 10,
    },
  },
  iPadPro: {
    landscape: {
      width: 1366,
      height: 959,
      cols: 8,
    },
    portrait: {
      width: 918,
      height: 1301,
      cols: 4,
    },
  },
  iPad: {
    landscape: {
      width: 1024,
      height: 703,
      cols: 8,
    },
    portrait: {
      width: 768,
      height: 959,
      cols: 4,
    },
  },
  iPhone: {
    landscape: {
      width: 795,
      height: 393,
      cols: 3,
    },
    portrait: {
      width: 393,
      height: 795,
      cols: 1,
    },
  },
} as GridConfig;

export const landscape = {
  breakpoints: {} as Record<DeviceType, number>,
  cols: {} as Record<DeviceType, number>,
  margin: {} as Record<DeviceType, [number, number]>,
};

export const portrait = {
  breakpoints: {} as Record<DeviceType, number>,
  cols: {} as Record<DeviceType, number>,
  margin: {} as Record<DeviceType, [number, number]>,
};

export const emptyLayout = {
  landscape: {} as Record<DeviceType, GridComponent[]>,
  portrait: {} as Record<DeviceType, GridComponent[]>,
};

const deviceNames = Object.keys(devices) as DeviceType[];

deviceNames.forEach((device: DeviceType) => {
  const { landscape: landscapeDimensions, portrait: portraitDimensions } =
    devices[device];
  if (landscapeDimensions) {
    landscape.breakpoints[device] = landscapeDimensions.width;
    landscape.cols[device] = landscapeDimensions?.cols || 12;
    landscape.margin[device] = landscapeDimensions?.margin || [5, 5];
    emptyLayout.landscape[device] = [];
  }
  if (portraitDimensions) {
    portrait.breakpoints[device] = portraitDimensions.width;
    portrait.cols[device] = portraitDimensions?.cols || 12;
    portrait.margin[device] = portraitDimensions?.margin || [5, 5];
    emptyLayout.portrait[device] = [];
  }
});

// offset breakpoints by 1 to adjust for the 0px breakpoint
const offsetBreakpoints = (breakpoints: Record<DeviceType, number>) => {
  const widths = [...Object.values(breakpoints), 0];
  return Object.keys(breakpoints).reduce(
    (acc: Record<string, number>, key: string, i: number) => {
      acc[key] = widths[i + 1];
      return acc;
    },
    {} as Record<string, number>
  );
};

landscape.breakpoints = offsetBreakpoints(landscape.breakpoints);
portrait.breakpoints = offsetBreakpoints(portrait.breakpoints);

export const dimensionsTest = {
  name: "Dimensions Test",
  id: "727d182e-9513-46ef-bb93-af00d716be43",
  order: Infinity,
  default: true,
  landscape: {
    ultrawide: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    desktop: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    laptop: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPadPro: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPad: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPhone: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
  },
  portrait: {
    ultrawide: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    desktop: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    laptop: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPadPro: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPad: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
    iPhone: [
      {
        w: 12,
        h: 100,
        x: 0,
        y: 0,
        i: "dTest",
        comps: [
          {
            key: "dTest",
            name: "Dimensions Test",
            config: {},
          },
        ],
      },
    ],
  },
} as RunView;

export const defaultViews = [
  {
    name: "Primary",
    default: true,
    id: "acc16d83-e92e-4d3b-aeab-6da4724d4baf",
    order: 1,
    landscape: {
      ultrawide: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            { key: "runDataWidget", name: "Race Name", config: { data: "raceName" } },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: { data: "raceProgress" },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [{ key: "vehicleDataWidget", name: "Detail Vehicle", config: {} }],
        },
        {
          i: "Line Map",
          x: 4,
          y: 0,
          w: 12,
          h: 5,
          comps: [{ key: "lineMap", name: "Line Map", config: {} }],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 10,
          h: 56,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Filter Laps",
          x: 0,
          y: 61,
          w: 1,
          h: 39,
          comps: [{ key: "filterLaps", name: "Filter Laps", config: {} }],
        },
        {
          i: "Lap Details",
          x: 1,
          y: 61,
          w: 3,
          h: 39,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            { key: "lapTimesViolinChart", name: "LT Violin", config: {} },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
          ],
        },
        {
          i: "Positions Details",
          x: 4,
          y: 61,
          w: 4,
          h: 39,
          comps: [
            { key: "positionsLineChart", name: "Pos. Line", config: {} },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Detail Driver Video",
          x: 8,
          y: 61,
          w: 4,
          h: 39,
          comps: [
            {
              key: "video",
              name: "Detail Driver Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Driver Details",
          x: 10,
          y: 5,
          w: 3,
          h: 56,
          comps: [{ key: "driverDetails", name: "Driver Details", config: {} }],
        },
        {
          i: "Pit Stops",
          x: 13,
          y: 5,
          w: 3,
          h: 56,
          comps: [
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 12,
          y: 61,
          w: 2,
          h: 39,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "Circle Map",
          x: 14,
          y: 61,
          w: 2,
          h: 39,
          comps: [{ key: "circleMap", name: "Circle Map", config: {} }],
        },
      ],
      desktop: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            { key: "runDataWidget", name: "Race Name", config: { data: "raceName" } },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: { data: "raceProgress" },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [{ key: "vehicleDataWidget", name: "Detail Vehicle", config: {} }],
        },
        {
          i: "Line Map",
          x: 4,
          y: 0,
          w: 8,
          h: 5,
          comps: [{ key: "lineMap", name: "Line Map", config: {} }],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 8,
          h: 56,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Filter Laps",
          x: 0,
          y: 61,
          w: 1,
          h: 39,
          comps: [{ key: "filterLaps", name: "Filter Laps", config: {} }],
        },
        {
          i: "Details",
          x: 1,
          y: 61,
          w: 4,
          h: 39,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            { key: "lapTimesViolinChart", name: "LT Violin", config: {} },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            { key: "positionsLineChart", name: "Pos. Line", config: {} },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Pit Stops",
          x: 5,
          y: 61,
          w: 3,
          h: 39,
          comps: [
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 8,
          y: 5,
          w: 2,
          h: 61,
          comps: [{ key: "driverDetails", name: "Driver Details", config: {} }],
        },
        {
          i: "Scanner",
          x: 10,
          y: 34,
          w: 2,
          h: 32,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "Circle Map",
          x: 10,
          y: 5,
          w: 2,
          h: 29,
          comps: [{ key: "circleMap", name: "Circle Map", config: {} }],
        },
        {
          i: "Detail Video",
          x: 8,
          y: 66,
          w: 4,
          h: 34,
          comps: [
            {
              key: "video",
              name: "video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
      ],
      laptop: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 7,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 12,
          w: 7,
          h: 89,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "driverDetails",
          x: 9,
          y: 12,
          w: 3,
          h: 61,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 9,
          y: 73,
          w: 3,
          h: 28,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 5,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 5,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 5,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 5,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 12,
          w: 6,
          h: 90,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "Driver Details",
          x: 9,
          y: 12,
          w: 2,
          h: 60,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 9,
          y: 73,
          w: 2,
          h: 30,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 6,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 5,
          w: 1,
          h: 6,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 5,
          w: 1,
          h: 6,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 6,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 5,
          h: 12,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 12,
          w: 6,
          h: 88,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "Driver Details",
          x: 6,
          y: 12,
          w: 2,
          h: 60,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 6,
          y: 73,
          w: 2,
          h: 28,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPhone: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 2,
          h: 90,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 2,
          y: 5,
          w: 1,
          h: 90,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
    },
    portrait: {
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 10,
          w: 3,
          h: 55,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "Filter Laps",
          x: 0,
          y: 65,
          w: 1,
          h: 35,
          comps: [{ key: "filterLaps", name: "Filter Laps", config: {} }],
        },
        {
          i: "Details",
          x: 1,
          y: 65,
          w: 2,
          h: 35,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            {
              key: "lapTimesTable",
              name: "LT Table",
              config: {},
            },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            {
              key: "deltaLineChart",
              name: "∆ Line",
              config: {},
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 10,
          w: 3,
          h: 55,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "Filter Laps",
          x: 0,
          y: 65,
          w: 1,
          h: 35,
          comps: [{ key: "filterLaps", name: "Filter Laps", config: {} }],
        },
        {
          i: "Details",
          x: 1,
          y: 65,
          w: 2,
          h: 35,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            {
              key: "lapTimesTable",
              name: "LT Table",
              config: {},
            },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            {
              key: "deltaLineChart",
              name: "∆ Line",
              config: {},
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPhone: [
        {
          w: 1,
          h: 8,
          x: 0,
          y: 0,
          i: "Stage Progress",
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "All Info",
          x: 0,
          y: 5,
          w: 1,
          h: 62,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "pitStops", name: "Pit Stops", config: {} },
            { key: "points", name: "Points", config: {} },
            { key: "weather", name: "Weather", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 73,
          w: 1,
          h: 30,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    name: "Details",
    id: "bd78c846-3ae6-43ab-921e-b9c232cfc658",
    order: 2,
    default: true,
    landscape: {
      ultrawide: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 4,
          y: 0,
          w: 12,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 2,
          h: 60,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 65,
          w: 2,
          h: 35,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
        {
          i: "Lap LT Violin Chart",
          x: 2,
          y: 5,
          w: 6,
          h: 48,
          comps: [
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
          ],
        },
        {
          i: "Lap LT Line",
          x: 8,
          y: 5,
          w: 6,
          h: 48,
          comps: [
            {
              name: "LT Line",
              config: {},
              key: "lapTimesLineChart",
            },
            {
              name: "LT Table",
              config: {},
              key: "lapTimesTable",
            },
          ],
        },
        {
          i: "Position Line Chart",
          x: 2,
          y: 53,
          w: 6,
          h: 47,
          comps: [
            {
              name: "Pos. Line",
              config: {},
              key: "positionsLineChart",
            },
          ],
        },
        {
          i: "∆ Line",
          x: 8,
          y: 53,
          w: 6,
          h: 47,
          comps: [
            {
              name: "∆ Line",
              config: {},
              key: "deltaLineChart",
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 14,
          y: 5,
          w: 2,
          h: 95,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
          ],
        },
      ],
      desktop: [
        {
          w: 1,
          h: 5,
          x: 0,
          y: 0,
          i: "Race Name",
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: { data: "raceName" },
            },
          ],
        },
        {
          w: 1,
          h: 5,
          x: 1,
          y: 0,
          i: "Race Progress",
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: { data: "raceProgress" },
            },
          ],
        },
        {
          w: 1,
          h: 5,
          x: 2,
          y: 0,
          i: "Stage Progress",
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          w: 1,
          h: 5,
          x: 3,
          y: 0,
          i: "Detail Vehicle",
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          w: 8,
          h: 5,
          x: 4,
          y: 0,
          i: "Line Map",
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 10,
          w: 2,
          h: 60,
          comps: [
            { key: "runTable", name: "Run Table", config: {} },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 70,
          w: 2,
          h: 35,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "Details",
          x: 2,
          y: 10,
          w: 8,
          h: 95,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 11,
          y: 10,
          w: 2,
          h: 95,
          comps: [{ key: "driverDetails", name: "Driver Details", config: {} }],
        },
      ],
      laptop: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 7,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 10,
          w: 2,
          h: 60,
          comps: [
            { key: "runTable", name: "Run Table", config: {} },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 65,
          w: 2,
          h: 30,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "Lap LT Violin Chart",
          x: 2,
          y: 10,
          w: 6,
          h: 90,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 11,
          y: 5,
          w: 2,
          h: 90,
          comps: [{ key: "driverDetails", name: "Driver Details", config: {} }],
        },
      ],
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 5,
          y: 0,
          w: 5,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Lap LT Violin Chart",
          x: 0,
          y: 10,
          w: 6,
          h: 90,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 6,
          y: 10,
          w: 2,
          h: 55,
          comps: [
            { key: "driverDetails", name: "Driver Details", config: {} },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 6,
          y: 65,
          w: 2,
          h: 35,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 5,
          y: 0,
          w: 5,
          h: 12,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Lap LT Violin Chart",
          x: 0,
          y: 10,
          w: 6,
          h: 88,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 6,
          y: 10,
          w: 2,
          h: 55,
          comps: [
            { key: "driverDetails", name: "Driver Details", config: {} },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 6,
          y: 65,
          w: 2,
          h: 33,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
      ],
      iPhone: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Lap LT Violin Chart",
          x: 0,
          y: 5,
          w: 2,
          h: 90,
          comps: [
            { key: "filterLaps", name: "Filter Laps", config: {} },
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            { key: "lapTimesTable", name: "LT Table", config: {} },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            { key: "deltaLineChart", name: "∆ Line", config: {} },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 2,
          y: 5,
          w: 1,
          h: 90,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
      ],
    },
    portrait: {
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Details",
          x: 0,
          y: 10,
          w: 3,
          h: 90,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            {
              key: "lapTimesTable",
              name: "LT Table",
              config: {},
            },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            {
              key: "deltaLineChart",
              name: "∆ Line",
              config: {},
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Details",
          x: 0,
          y: 10,
          w: 3,
          h: 90,
          comps: [
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            {
              key: "lapTimesTable",
              name: "LT Table",
              config: {},
            },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            {
              key: "deltaLineChart",
              name: "∆ Line",
              config: {},
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
            { key: "filterLaps", name: "Filter Laps", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPhone: [
        {
          w: 1,
          h: 8,
          x: 0,
          y: 0,
          i: "Stage Progress",
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "allInfo",
          x: 0,
          y: 5,
          w: 1,
          h: 62,
          comps: [
            { key: "filterLaps", name: "Filter Laps", config: {} },
            { key: "lapTimesLineChart", name: "LT Line", config: {} },
            { key: "lapTimeRankLineChart", name: "LT Rank", config: {} },
            {
              key: "lapTimesViolinChart",
              name: "LT Violin",
              config: {},
            },
            { key: "lapTimeRankViolinChart", name: "LT Rank Violin", config: {} },
            {
              key: "lapTimesTable",
              name: "LT Table",
              config: {},
            },
            {
              key: "positionsLineChart",
              name: "Pos. Line",
              config: {},
            },
            {
              key: "deltaLineChart",
              name: "∆ Line",
              config: {},
            },
            {
              key: "deltaToDetailLineChart",
              name: "∆ Driver",
              config: {},
            },
            { key: "jerkLineChart", name: "j Line", config: {} },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 73,
          w: 1,
          h: 30,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    name: "Video",
    flag: "video",
    default: true,
    id: "295bbdf6-f2b7-423e-abae-60b3ccd7a666",
    order: 3,
    landscape: {
      ultrawide: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            { key: "runDataWidget", name: "Race Name", config: { data: "raceName" } },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: { data: "raceProgress" },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [{ key: "vehicleDataWidget", name: "Detail Vehicle", config: {} }],
        },
        {
          i: "Line Map",
          x: 4,
          y: 0,
          w: 12,
          h: 5,
          comps: [{ key: "lineMap", name: "Line Map", config: {} }],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 2,
          h: 62,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Scanner",
          x: 0,
          y: 67,
          w: 1,
          h: 30,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "video1",
          x: 2,
          y: 5,
          w: 7,
          h: 62,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { saveID: "NV:Battle Cam" },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video2",
          x: 6,
          y: 67,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video3",
          x: 3,
          y: 67,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video4",
          x: 9,
          y: 5,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video5",
          x: 9,
          y: 32,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video6",
          x: 12,
          y: 5,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video7",
          x: 9,
          y: 59,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video8",
          x: 12,
          y: 32,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video9",
          x: 12,
          y: 59,
          w: 3,
          h: 27,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "New Component",
          x: 1,
          y: 67,
          w: 2,
          h: 30,
          comps: [{ name: "circleMap", config: {}, key: "circleMap" }],
        },
      ],
      desktop: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 4,
          y: 0,
          w: 8,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 5,
          w: 2,
          h: 65,
          comps: [
            {
              key: "runTable",
              name: "Run Table",
              config: {},
            },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 70,
          w: 2,
          h: 30,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
        {
          i: "Battle Cam",
          x: 2,
          y: 5,
          w: 7,
          h: 58,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "Video",
              props: {},
              config: {
                saveId: "NV:Battle Cam",
              },
            },
          ],
        },
        {
          i: "Detail Driver",
          x: 6,
          y: 63,
          w: 3,
          h: 25,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "Video",
              props: {},
              config: {
                detailOffset: 0,
              },
            },
          ],
        },
        {
          i: "Offset 3",
          x: 9,
          y: 5,
          w: 3,
          h: 24,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "Video",
              props: {},
              config: {
                detailOffset: 3,
              },
            },
          ],
        },
        {
          i: "Offset 2",
          x: 9,
          y: 29,
          w: 3,
          h: 25,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "Video",
              props: {},
              config: {
                detailOffset: 2,
              },
            },
          ],
        },
        {
          i: "Aerial Cam",
          x: 2,
          y: 63,
          w: 4,
          h: 33,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                saveId: "NV:Aerial Cam",
              },
            },
          ],
        },
        {
          i: "Offset 1",
          x: 9,
          y: 54,
          w: 3,
          h: 25,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                detailOffset: 1,
              },
            },
          ],
        },
      ],
      laptop: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 7,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 12,
          w: 2,
          h: 65,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Scanner",
          x: 0,
          y: 70,
          w: 2,
          h: 25,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "video1",
          x: 2,
          y: 10,
          w: 4,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { saveID: "NV:Battle Cam" },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video2",
          x: 6,
          y: 10,
          w: 4,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video3",
          x: 2,
          y: 55,
          w: 4,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video4",
          x: 6,
          y: 55,
          w: 4,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
      ],
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 5,
          h: 10,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 12,
          w: 2,
          h: 65,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Scanner",
          x: 0,
          y: 70,
          w: 2,
          h: 25,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "video1",
          x: 2,
          y: 10,
          w: 3,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { saveID: "NV:Battle Cam" },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video2",
          x: 5,
          y: 10,
          w: 3,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video3",
          x: 2,
          y: 55,
          w: 3,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video4",
          x: 5,
          y: 55,
          w: 3,
          h: 45,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 3,
          h: 7,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 0,
          y: 5,
          w: 1,
          h: 7,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 1,
          y: 5,
          w: 1,
          h: 7,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 2,
          y: 6,
          w: 1,
          h: 7,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 3,
          y: 0,
          w: 5,
          h: 14,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Run Table",
          x: 0,
          y: 10,
          w: 2,
          h: 56,
          comps: [{ key: "runTable", name: "Run Table", config: {} }],
        },
        {
          i: "Scanner",
          x: 0,
          y: 70,
          w: 2,
          h: 30,
          comps: [{ key: "scanner", name: "Scanner", config: {} }],
        },
        {
          i: "video1",
          x: 2,
          y: 14,
          w: 3,
          h: 43,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { saveID: "NV:Battle Cam" },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video2",
          x: 5,
          y: 14,
          w: 3,
          h: 43,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video3",
          x: 2,
          y: 57,
          w: 3,
          h: 43,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "video4",
          x: 5,
          y: 57,
          w: 3,
          h: 43,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
      ],
      iPhone: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Video",
          x: 0,
          y: 5,
          w: 2,
          h: 90,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Scanner",
          x: 2,
          y: 5,
          w: 1,
          h: 90,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
    },
    portrait: {
      iPadPro: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Video 1",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Video 2",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Video 3",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPad: [
        {
          i: "Race Name",
          x: 0,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Name",
              config: {
                data: "raceName",
              },
            },
          ],
        },
        {
          i: "Race Progress",
          x: 1,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Race Progress",
              config: {
                data: "raceProgress",
              },
            },
          ],
        },
        {
          i: "Stage Progress",
          x: 2,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: {
                data: "stageProgress",
              },
            },
          ],
        },
        {
          i: "Detail Vehicle",
          x: 3,
          y: 0,
          w: 1,
          h: 5,
          comps: [
            {
              key: "vehicleDataWidget",
              name: "Detail Vehicle",
              config: {},
            },
          ],
        },
        {
          i: "Line Map",
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          comps: [
            {
              key: "lineMap",
              name: "Line Map",
              config: {},
            },
          ],
        },
        {
          i: "Video 1",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Video 2",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 1 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Video 3",
          x: 0,
          y: 10,
          w: 3,
          h: 30,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: -2 },
              props: {},
              flag: "video",
            },
          ],
        },
        {
          i: "Driver Details",
          x: 3,
          y: 10,
          w: 1,
          h: 65,
          comps: [
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
          ],
        },
        {
          i: "Scanner",
          x: 3,
          y: 75,
          w: 1,
          h: 25,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
      iPhone: [
        {
          w: 1,
          h: 8,
          x: 0,
          y: 0,
          i: "Stage Progress",
          comps: [
            {
              key: "runDataWidget",
              name: "Stage Progress",
              config: { data: "stageProgress" },
            },
          ],
        },
        {
          i: "All Info",
          x: 0,
          y: 5,
          w: 1,
          h: 62,
          comps: [
            {
              key: "video",
              name: "Video",
              config: { detailOffset: 0 },
              props: {},
              flag: "video",
            },
            {
              key: "driverDetails",
              name: "Driver Details",
              config: {},
            },
          ],
        },
        {
          i: "Scanner",
          x: 0,
          y: 73,
          w: 1,
          h: 30,
          comps: [
            {
              key: "scanner",
              name: "Scanner",
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    id: "01d99e69-961f-4c1a-a287-5acdcd2ed994",
    name: "4 Box",
    flag: "video",
    order: 4,
    default: true,
    portrait: {
      iPad: [
        {
          h: 50,
          i: "V1",
          w: 2,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 2,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 2,
          x: 2,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 2,
          x: 2,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      iPhone: [
        {
          h: 25,
          i: "V1",
          w: 1,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 25,
          i: "V2",
          w: 1,
          x: 0,
          y: 25,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 25,
          i: "V3",
          w: 1,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 25,
          i: "v4",
          w: 1,
          x: 0,
          y: 75,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      iPadPro: [
        {
          h: 50,
          i: "V1",
          w: 2,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 2,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 2,
          x: 2,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 2,
          x: 2,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
    },
    landscape: {
      iPad: [
        {
          h: 50,
          i: "V1",
          w: 4,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 4,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 4,
          x: 4,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 4,
          x: 4,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      laptop: [
        {
          h: 50,
          i: "V1",
          w: 5,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 5,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 5,
          x: 5,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 5,
          x: 5,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      desktop: [
        {
          h: 50,
          i: "V1",
          w: 6,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 6,
          x: 6,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 6,
          x: 6,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 6,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      iPhone: [
        {
          h: 50,
          i: "V1",
          w: 1,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 1,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 1,
          x: 1,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 1,
          x: 1,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      iPadPro: [
        {
          h: 50,
          i: "V1",
          w: 4,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 4,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 4,
          x: 4,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 4,
          x: 4,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
      ultrawide: [
        {
          h: 50,
          i: "V1",
          w: 8,
          x: 0,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1d9c2cf8-62f3-4408-8878-373a48f05f04",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V2",
          w: 8,
          x: 0,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "7beb6003-595d-46ab-9a0b-eb7b92efe923",
                detail_offset: null,
              },
            },
          ],
          min_h: null,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "V3",
          w: 8,
          x: 8,
          y: 0,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "ca5b6665-8775-420c-aef2-f45be64fe2c1",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
        {
          h: 50,
          i: "v4",
          w: 8,
          x: 8,
          y: 50,
          comps: [
            {
              key: "video",
              flag: "video",
              name: "video",
              props: {},
              config: {
                save_id: "1788ad5f-b359-4a41-9350-23a0a48e6f73",
                detail_offset: null,
              },
            },
          ],
          min_h: 0,
          min_w: 1,
          moved: false,
          static: false,
        },
      ],
    },
  },
] as RunView[];

if (isDev) {
  defaultViews.push(dimensionsTest);
}
export const componentStateDefault = {
  activeStages: [],
  selectedLapsFilter: "smart",
  timeRange: { min: -Infinity, max: Infinity },
  lapOffset: 10,
  lapFrom: 1,
  filterInPit: true,
  filterInLap: true,
  filterOutLap: true,
  filterCaution: true,
  filterRestarts: true,
  filterImputed: false,
  selectedVehiclesFilter: "detailOffset",
  detailOffset: { ahead: 2, behind: 2 },
  manualVehicles: [],
  showLegend: false,
};
