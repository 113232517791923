/** @format */

import { useActiveFeatureFlags } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useUser, verifyEmail } from "../api/userAPI";
import LoadingCircle from "../utils/LoadingCircle";

const VerifyEmail = () => {
  const { key } = useParams();
  const [result, setResult] = useState<string>();
  const { data: user = {} } = useUser();
  const flags = useActiveFeatureFlags();
  useEffect(() => {
    if (key) {
      verifyEmail(key)
        .then(d => {
          if (d.detail === "ok") {
            setResult(d.detail);
          } else {
            setResult("error");
          }
        })
        .catch(e => {
          setResult("error");
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (user.id || !key || !flags?.includes("register"))
    return <Navigate to="/" replace />;
  switch (result) {
    case "ok":
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full ">
            <div className="mt-2 bg-gray-800 p-4 rounded">
              <h1 className="text-xl mb-2">E-Mail Verified Successfully!</h1>
              <Link
                to="/?modal=login"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      );
    case "error":
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full ">
            <div className="mt-2 bg-gray-800 p-4 rounded">
              <h1 className="text-xl mb-2">E-Mail Verification Failed</h1>
              <Link
                to="/"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                Go Home
              </Link>
            </div>
          </div>
        </div>
      );
    case null:
    default:
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full ">
            <div className="mt-2 bg-gray-800 p-4 rounded flex">
              <h1 className="text-xl mb-2 mx-auto">
                <LoadingCircle className="h-5 w-5 mr-2 inline" />
                Verifying E-Mail
              </h1>
            </div>
          </div>
        </div>
      );
  }
};

export default VerifyEmail;
