/** @format */

import { faSave } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { useRun, useUpdateRun } from "../../../api/runsApi";
import { useUser } from "../../../api/userAPI";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";

const RunDataWidget: React.FC<WidgetProps> = ({ config: { data } = {} }) => {
  const { runID } = useLiveParams();
  const { data: runData, isSuccess, status, error } = useRun(runID);
  const { mutate: updateRun } = useUpdateRun(runID);
  const { data: { isStaff = false } = {} } = useUser();
  const [tempLength, setTempLength] = useState<number>();
  const activeStageFlags = useMemo(() => {
    if (!runData?.currentStage) return [];
    return [
      ...runData?.flagPeriods?.filter(
        flagPeriod =>
          flagPeriod.start >= runData?.stageStart || flagPeriod.end <= runData?.stageEnd
      ),
      { start: runData?.lapNumber + 1, end: runData?.stageEnd, flag: null },
    ].map(flagPeriod => ({
      ...flagPeriod,
      left: `${
        ((flagPeriod.start - runData?.stageStart) / (runData?.stageLaps + 1)) * 100
      }%`,
      width: `${
        ((flagPeriod.end - flagPeriod.start) / (runData?.stageLaps + 1)) * 100
      }%`,
    }));
  }, [runData]);
  // ToDo test this for accuracy
  const stagesAndFlags = useMemo(() => {
    if (!runData?.stages) return [];
    const periods = [
      ...runData?.flagPeriods,
      ...runData?.stages?.filter(stage => stage.end > runData?.stageEnd),
      { start: runData?.lapNumber + 1, end: runData?.stageEnd, flag: null },
    ] as (FlagPeriod & Stage)[];
    return periods.map(flag =>
      flag?.name && flag?.start < runData?.lapNumber // if flag is a stage and stage has started and stage is not over then reduce the start to the current lap
        ? {
            ...flag,
            left: `${((runData?.stageStart - flag.start) / runData?.stageLaps) * 100}%`,
            width: `${(flag.end / runData?.stageLaps) * 100}%`,
          }
        : {
            ...flag,
            left: `${(flag.start / (runData?.totalLaps + 1)) * 100}%`,
            width: `${((flag.end - flag.start) / (runData?.totalLaps + 1)) * 100}%`,
          }
    );
  }, [runData]);
  if (!isSuccess) {
    return <Loading statuses={[status]} errors={[error]} />;
  }
  switch (data) {
    case "raceName":
      return (
        <div
          className={`absolute inset-0 flex flex-wrap text-gray-300 items-center justify-center align-middle`}
          data-flag={runData?.flag}
        >
          <h1 className="text-lg text-center leading-none px-2 py-1 rounded-xl bg-black bg-opacity-70 z-10 max-w-full max-h-full truncate">
            <b>{runData?.name}</b>
            <br />
            <span>{runData?.track}</span>
          </h1>
        </div>
      );
    case "raceProgress":
      return (
        <div className="absolute inset-0 flex flex-wrap text-gray-300 items-center justify-center align-middle">
          <h2 className="text-lg text-center bg-black bg-opacity-70 rounded-xl px-2 py-1 leading-none z-10 max-w-full max-h-full truncate">
            {runData?.type === 3 ? (
              <b>Race</b>
            ) : runData?.type === 2 ? (
              <b>Qualifying</b>
            ) : (
              <b>Practice</b>
            )}
            <br />
            <span>
              {runData?.type === 3 ? (
                <>
                  {runData?.lapNumber}/{runData?.totalLaps} {runData?.lapsRemaining}{" "}
                  left
                </>
              ) : (
                <>
                  Elapsed:{" "}
                  {
                    moment.duration(runData?.elapsedTime, "seconds").asMinutes()
                    // .format("mm:ss", { trim: false })
                  }
                </>
              )}
            </span>
          </h2>
          {stagesAndFlags?.map(({ start, flag, left, width }) => (
            <div
              key={start}
              className="absolute inset-0 z-0"
              data-flag={flag}
              style={{ left, width }}
            />
          ))}
        </div>
      );
    case "stageProgress":
      return (
        <div className="absolute inset-0 flex flex-wrap text-gray-300 items-center justify-center align-middle">
          <h2 className="text-lg text-center bg-black bg-opacity-70 rounded-xl px-2 py-1 leading-none z-10 max-w-full max-h-full truncate">
            {runData?.type === 3 ? (
              <b>{runData?.currentStage || "No Stages"}</b>
            ) : runData?.type === 2 ? (
              <b>Qualifying</b>
            ) : (
              <b>Practice</b>
            )}
            <br />
            {runData?.type === 3 ? (
              runData?.currentStage ? (
                <span>
                  {runData?.stageCompleted}/{runData?.stageLaps}{" "}
                  {runData?.stageRemaining} left
                </span>
              ) : null
            ) : runData?.length ? (
              <span>
                Remaining:{" "}
                {
                  moment
                    .duration(runData?.length - runData?.elapsedTime, "seconds")
                    .asMinutes()
                  // .format("mm:ss", { trim: false })
                }
              </span>
            ) : isStaff ? (
              <>
                <input
                  title="Set Length"
                  type="number"
                  className="bg-black bg-opacity-70 rounded-xl px-1 py-0 mt-1 w-10 text-white leading-none"
                  value={tempLength}
                  onChange={e => {
                    setTempLength(parseInt(e.target.value));
                  }}
                />{" "}
                mins
                <button
                  title="Save Length"
                  onClick={() => {
                    if (!tempLength) throw new Error("Length must be set");
                    updateRun({ length: tempLength * 60 });
                  }}
                >
                  <FontAwesomeIcon className="ml-1" icon={faSave} />
                </button>
              </>
            ) : (
              <span>No Length Set</span>
            )}
          </h2>
          {activeStageFlags?.map(({ start, flag, left, width }) => (
            <div
              key={start}
              className="absolute inset-0 z-0"
              data-flag={flag}
              style={{ left, width }}
            />
          ))}
        </div>
      );
    default: // ToDo Fix for empty data
      return null;
    //   (
    //   <div className={`flex flex-col ${flagClass}`}>
    //     <select
    //       className="bg-black bg-opacity-70 rounded-xl px-2 py-1 leading-none">
    //       <option value="raceName">Race Info</option>
    //       <option value="raceProgress">Race Progress</option>
    //       <option value="stageProgress">Stage Progress</option>
    //     </select>
    //   </div>
    // );
  }
};

export default RunDataWidget;
