/** @format */

import { Disclosure } from "@headlessui/react";
import LoadingCircle from "./LoadingCircle";
import Crash from "../assets/crash_low.png";
import { ReactNode } from "react";

interface LoadingProps {
  statuses?: string[];
  errors?: any[];
  children?: ReactNode;
  wrap?: boolean;
  className?: string;
  fontClassName?: string;
  [key: string]: any;
}

const Loading: React.FC<LoadingProps> = ({
  statuses = ["loading"],
  errors = [],
  children = null,
  wrap = false,
  className = "flex flex-col justify-center flex-grow items-center content-center m-auto mt-4",
  ...props
}) => {
  // status can be "idle", "loading", "success", "error"
  // if all statuses are "success", then status is "success"
  // if any status is "error", then status is "error"
  // if any status is "loading", then status is "loading"
  // if all statuses are "idle", then status is "idle"
  const status = statuses.reduce((acc: string | null, status) => {
    if (acc === null) return status;
    if (acc === "error") return acc;
    if (status === "error") return "error";
    if (acc === "loading") return acc;
    if (status === "loading") return "loading";
    if (acc === "idle") return acc;
    if (status === "idle") return "idle";
    if (acc === "pending") return acc;
    if (status === "pending") return "loading";
    return "success";
  }, null);
  switch (status) {
    case "success":
      return wrap ? (
        <div {...props} className={className}>
          {children}
        </div>
      ) : (
        <>{children}</>
      );
    case "error":
      return (
        <div {...props} className={className}>
          <div className="flex flex-col bg-white rounded-3xl justify-center align-middle shrink text-black my-auto">
            <h2 className="text-center text-3xl font-bold my-3 text-red-600 shrink">
              Something went wrong
            </h2>
            <Disclosure>
              {({ open }) => (
                <div className="text-center">
                  <Disclosure.Button className="text-red-600 border border-red-600 rounded-md px-2 py-1">
                    {open ? "Hide" : "Show"} Details
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <pre className="text-center shrink">
                      {errors
                        .filter(error => error)
                        .map((error, idx) => (
                          <div key={idx}>
                            <a
                              className="text-blue-600"
                              target="_blank"
                              rel="noreferrer"
                              href={error?.config?.url}
                            >
                              {error?.config?.url}
                            </a>
                            :{error?.message}
                          </div>
                        ))}
                    </pre>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
            <img src={Crash} alt="error" className="object-contain w-1/2 m-auto" />
          </div>
        </div>
      );
    case "idle":
    case "loading":
    default:
      return (
        <div {...props} className={className}>
          <div className="flex flex-col grow justify-center items-center h-full w-full">
            {status === "pending" || status === "loading" ? (
              <div className="flex flex-col justify-center items-center">
                <LoadingCircle className="h-auto w-full max-h-[100px]" />
                <h2 className="text-center text-4xl capitalize">Loading</h2>
              </div>
            ) : (
              <h2 className="text-center text-4xl capitalize">status</h2>
            )}
          </div>
        </div>
      );
  }
};

export default Loading;
