/** @format */

import { Combobox } from "@headlessui/react";
import { useVehiclesList } from "../api/runsApi";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useLiveParams from "../utils/useLiveParams";

const DetailDriverSelector = ({ className = "" }) => {
  const { runID } = useLiveParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const detailVehicleID = searchParams.get("detailVehicleID");

  const { data } = useVehiclesList(runID);

  return (
    <Combobox
      className={`${className} relative`}
      value={data?.find(vehicle => vehicle.id === detailVehicleID)?.displayName || ""}
      onChange={e => {
        setSearchParams({ detailVehicleID: e }, { replace: true });
      }}
      as="div"
    >
      <Combobox.Input
        placeholder="Select Driver"
        className="bg-black text-white w-full rounded-md px-2 py-1"
        onChange={e => setQuery(e.target.value)}
      />
      <Combobox.Options className="bg-gray-800 text-white p-1 absolute gap-1 w-full flex z-50 mt-1 max-h-60 flex-wrap overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {data
          ?.filter(vehicle =>
            vehicle.displayName.toLowerCase().includes(query.toLowerCase())
          )
          ?.map(vehicle => (
            <Combobox.Option
              key={vehicle.id}
              value={vehicle.id}
              className={({ active }) =>
                `text-sm whitespace-nowrap border border-collapse rounded p-1 shrink cursor-pointer ${
                  active ? "font-bold" : ""
                }}`
              }
            >
              {vehicle.number} {vehicle.displayName}
            </Combobox.Option>
          ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default DetailDriverSelector;
