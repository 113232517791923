/** @format */

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { keyBy, mapValues } from "lodash";
import { useMemo } from "react";
import galaxieClient from "./galaxieClient";

const refetchInterval = (isLive: boolean) => (!!isLive ? 20 * 1000 : 60 * 5 * 1000);

const getLiveRun = () =>
  galaxieClient.get("/live/").then(res => {
    return { data: res.data, version: res.headers["x-version"] };
  });

const _useLiveRun = (options = {}) =>
  useQuery({
    queryKey: ["liveRuns"],
    queryFn: getLiveRun,
    refetchIntervalInBackground: true,
    ...options,
    //refetchInterval: res => refetchInterval(res), // ToDo What was I doing here?
  });

export const useLiveRun = (options = {}) => {
  const { data: { data = {} } = {}, ...rest } = _useLiveRun(options);
  return { data, ...rest };
};

export function useRun<TQueryFnData extends any = RunDetail>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<RunDetail, Error, TQueryFnData>({
    queryKey: ["run", runID],
    queryFn: async () => galaxieClient.get(`/runs/${runID}/`).then(res => res.data),
    refetchIntervalInBackground: isLive,
    refetchInterval: refetchInterval(isLive),
    ...options,
  });
}

export const useUpdateRun = (runID: string) => {
  const queryClient = useQueryClient();
  return useMutation<RunDetail, Error, Partial<RunDetail>>({
    mutationFn: async run =>
      await galaxieClient.patch(`/runs/${runID}/`, run).then(res => res.data),
    onMutate: run => {
      const oldRun = queryClient.getQueryData<RunDetail>(["run", runID]);
      queryClient.setQueryData(["run", runID], {
        ...oldRun,
        ...run,
      });
      return oldRun;
    },
    onSuccess: (data, run) => {
      queryClient.setQueryData(["run", runID], data);
    },
    onError: (_err, _, run) => {
      queryClient.setQueryData(["run", runID], run);
    },
  });
};

export const useServerVersion = () => {
  const { data: { version } = {}, ...rest } = _useLiveRun();
  return { data: version, ...rest };
};

export const useIsLiveRun = (runID: string, options = {}) => {
  const { data: { id: liveID } = {} } = useLiveRun(options);
  return useMemo(() => liveID === runID, [liveID, runID]);
};

const postLiveFeed = () =>
  galaxieClient.post("/live/", { cache: "no-store" }).then(res => res.data);

export const useFetchLiveFeed = () =>
  useQuery({
    queryKey: ["liveRuns"],
    queryFn: postLiveFeed,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: false,
  });

export function useWeather<TQueryFnData extends any = Weather>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<Weather, Error, TQueryFnData>({
    queryKey: ["run", runID, "weather"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/weather/`).then(res => res.data),
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function useVehiclesList<TQueryFnData extends any = VehicleList[]>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<VehicleList[], Error, TQueryFnData>({
    queryKey: ["run", runID, "vehicles"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/vehicles/`).then(res => res.data),
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function useVehicleLapsList<TQueryFnData extends any = VehicleLapList[]>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<VehicleLapList[], Error, TQueryFnData>({
    queryKey: ["run", runID, "vehicle_laps"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/vehicle_laps/`).then(res => res.data),
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function useVehiclesDetail<TQueryFnData extends any = VehicleDetail>(
  runID: string,
  vehicleID: string | null,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<VehicleDetail, Error, TQueryFnData>({
    queryKey: ["run", runID, "vehicles", vehicleID],
    queryFn: async () =>
      await galaxieClient
        .get(`/runs/${runID}/vehicles/${vehicleID}/`)
        .then(res => res.data),
    enabled: !!vehicleID,
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function usePitStops<TQueryFnData extends any = PitStop[]>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<PitStop[], Error, TQueryFnData>({
    queryKey: ["run", runID, "pitstops"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/pitstops/`).then(res => res.data),
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function usePoints<TQueryFnData extends any = RunPoints[]>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  const isLive = useIsLiveRun(runID);
  return useQuery<RunPoints[], Error, TQueryFnData>({
    queryKey: ["run", runID, "points"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/points/`).then(res => res.data),
    refetchInterval: refetchInterval(isLive),
    refetchIntervalInBackground: isLive,
    ...options,
  });
}

export function useStaffVehicleConfigs<TQueryFnData extends any = VehicleConfig[]>(
  runID: string,
  options = {}
): UseQueryResult<TQueryFnData> {
  return useQuery<VehicleConfig[], Error, TQueryFnData>({
    queryKey: ["run", runID, "vehicle_configs"],
    queryFn: async () =>
      await galaxieClient.get(`/runs/${runID}/vehicle_configs/`).then(res => res.data),
    refetchInterval: false,
    ...options,
  });
}

export const useUpdateColor = (runID: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    VehicleConfig,
    Error,
    Partial<VehicleConfig> & { id: string },
    {
      previousVehicleConfigs: VehicleConfig[];
    }
  >({
    mutationFn: async ({ id, ...rest }) =>
      await galaxieClient
        .patch(`/runs/${runID}/vehicle_configs/${id}/`, { id, ...rest })
        .then(res => res.data),
    onMutate: ({ id, ...rest }) => {
      queryClient.cancelQueries({ queryKey: ["run", runID, "vehicle_configs"] });
      const previousVehicleConfigs = queryClient.getQueryData<
        VehicleConfig[] | undefined
      >(["run", runID, "vehicle_configs"]);
      queryClient.setQueryData(
        ["run", runID, "vehicle_configs"],
        (old: VehicleConfig[]) =>
          old?.map(vehicle => (vehicle.id === id ? { ...vehicle, ...rest } : vehicle))
      );
      return {
        previousVehicleConfigs: previousVehicleConfigs || [],
      }; // Add type assertion here
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["run", runID, "vehicle_configs"] });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["run", runID, "vehicle_configs"] });
    },
  });
};

export const useDriverIDtoVehicleNumber = (runID: string, options = {}) =>
  useVehiclesList<Record<string, string>>(runID, {
    ...options,
    select: (vehiclesList: VehicleList[]) =>
      mapValues(keyBy(vehiclesList || [], "driverId"), "number"),
  });

export const useDriverIDtoNascarID = (runID: string) =>
  useVehiclesList<Record<string, string>>(runID, {
    select: (vehiclesList: VehicleList[]) =>
      mapValues(keyBy(vehiclesList, "driverId"), "driverNascarId"),
  });

export const useDriverIDtoVehicleID = (runID: string, options = {}) =>
  useVehiclesList<Record<string, string>>(runID, {
    ...options,
    select: (vehiclesList: VehicleList[]) =>
      mapValues(keyBy(vehiclesList, "driverId"), "id"),
  });
