/** @format */
import useMeasure from "react-use-measure";
import CircleMap from "./CircleMap";
import DeltaLineChart from "./DeltaLineChart";
import DeltaToDetailLineChart from "./DeltaToDetailLineChart";
import DriverDetails from "./DriverDetails";
import FilterLaps from "./FilterLaps";
import JerkLineChart from "./JerkLineChart";
import LapTimeLineChart from "./LapTimeLineChart";
import LapTimeRankLineChart from "./LapTimeRankLineChart";
import LapTimeRankViolinChart from "./LapTimeRankViolinChart";
import LapTimeTable from "./LapTimeTable";
import LapTimeViolinChart from "./LapTimeViolinChart";
import LineMap from "./LineMap";
import PitStopsTable from "./PitStopsTable";
import PointsTable from "./PointsTable";
import PositionLineChart from "./PositionLineChart";
import RunDataWidget from "./RunDataWidget";
import RunTable from "./RunTable";
import Scanner from "./Scanner";
import VehicleDataWidget from "./VehicleDataWidget";
import VideoController from "./VideoController";
import Weather from "./Weather";

const componentMap = {
  filterLaps: { Component: FilterLaps, props: {} },
  lineMap: { Component: LineMap, props: {} },
  circleMap: { Component: CircleMap, props: {}, allowNewTab: true },
  runTable: { Component: RunTable, props: {}, allowNewTab: true },
  driverDetails: {
    Component: DriverDetails,
    props: {},
    includeDetailDriver: true,
    allowNewTab: true,
  },
  pitStops: { Component: PitStopsTable, props: {}, allowNewTab: true },
  points: { Component: PointsTable, props: {}, allowNewTab: true },
  video: { Component: VideoController, props: {}, flag: "video", allowNewTab: true },
  lapTimesTable: {
    Component: LapTimeTable,
    props: {},
    allowNewTab: true,
    includeDetailDriver: true,
  },
  lapTimesViolinChart: {
    Component: LapTimeViolinChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  lapTimeRankViolinChart: {
    Component: LapTimeRankViolinChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  lapTimesLineChart: {
    Component: LapTimeLineChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  positionsLineChart: {
    Component: PositionLineChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  deltaLineChart: {
    Component: DeltaLineChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  deltaToDetailLineChart: {
    Component: DeltaToDetailLineChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  jerkLineChart: {
    Component: JerkLineChart,
    props: {},
    flag: "jerkChart",
    includeFilterLaps: true,
    allowNewTab: true,
  },
  lapTimeRankLineChart: {
    Component: LapTimeRankLineChart,
    props: {},
    includeFilterLaps: true,
    allowNewTab: true,
  },
  weather: { Component: Weather, props: {}, allowNewTab: true },
  scanner: { Component: Scanner, props: {}, flag: "scanner", allowNewTab: true },
  runDataWidget: { Component: RunDataWidget, props: {}, minH: 50 },
  vehicleDataWidget: { Component: VehicleDataWidget, props: {}, minH: 50 },
  dTest: {
    Component: () => {
      const [ref, { width, height }] = useMeasure();
      return (
        <div
          ref={ref}
          className="flex flex-col inset-0 absolute justify-center items-center"
        >
          <div className="text-2xl">Width: {width}</div>
          <div className="text-2xl">Height: {height}</div>
          <button
            className="text-2xl"
            onClick={() =>
              navigator.clipboard.writeText(JSON.stringify({ width, height }))
            }
          >
            Copy Dimensions
          </button>
        </div>
      );
    },
    props: {},
  },
} as Record<string, Component>;

export default componentMap;
