/** @format */
import logo from "../assets/galaxie_logo.svg";

interface LoadingCircleProps {
  className?: string;
}

const LoadingCircle: React.FC<LoadingCircleProps> = ({ className = "" }) => (
  <img src={logo} alt="loading" className={className} />
);

export default LoadingCircle;
