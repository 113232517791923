/** @format */

import { formatPlus } from "./utility";

interface FormatDeltaProps {
  fixed?: number;
  formatOptions?: FormatOptions;
  className?: string;
  ltColor?: string;
  gtColor?: string;
  eqColor?: string;
  children: number | null;
}

const FormatDelta: React.FC<FormatDeltaProps> = ({
  fixed = 3,
  formatOptions,
  className = "",
  ltColor = "text-green-500",
  gtColor = "text-red-500",
  eqColor = "",
  children,
}) => {
  if (children === undefined || children === null || isNaN(children)) {
    return <span></span>;
  }
  const color = children > 0 ? gtColor : children < 0 ? ltColor : eqColor;
  const value = formatPlus(children, fixed, formatOptions);
  if (undefined === children || null === children || isNaN(children)) {
    return <span>{}</span>;
  } else if (typeof children === "number") {
    return <span className={color + " " + className}>{value}</span>;
  }
  return children;
};

export default FormatDelta;
