/** @format */

import { faPalette } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../api/userAPI";
import { groupBy, mapValues } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PLACEHOLDER, QUALIFYING, RACE, RUN_TYPES } from "../../utils/constants";

interface RaceDetailProps {
  race: Race;
}

const RaceDetail: React.FC<RaceDetailProps> = ({ race }) => {
  const [open, setOpen] = useState(false);
  const date = moment(race.scheduledDate);
  const raceRun = race?.raceRun;
  // const raceRun = race?.runs?.find(gRace => gRace?.type === RACE);
  const groupedRuns = groupBy(
    race?.runs.filter(run => run.type !== PLACEHOLDER),
    "type"
  );
  const { data: { isStaff } = {} } = useUser();
  const placeholderRun = race?.runs?.find(
    (gRace: RaceRun) => gRace?.type === PLACEHOLDER
  );
  return (
    <div className="my-4 rounded bg-gray-900 py-4 px-3 flex flex-col sm:flex-row">
      <div className="hidden sm:flex flex-row grow overflow-hidden max-h-[5rem] max-w-[5rem]">
        {date.isBefore() ? (
          <div className="rounded border grow schedule-complete aspect-square m-2" />
        ) : (
          <div className="rounded grow aspect-square m-2 justify-center flex flex-col ">
            <div className="text-xl text-center align-middle">
              <b>{date.diff(moment(), "days")}</b>
              <br /> Days
            </div>
          </div>
        )}
      </div>
      <div className="sm:p-0 sm:pl-2 sm:ml-2 sm:border-l-2 grow">
        <div className="flex flex-col sm:flex-row">
          <div>
            <p className="text-2xl">
              {date.isBefore() && raceRun ? (
                <Link to={`/run/${raceRun?.id}/`} className="">
                  {race.name}
                </Link>
              ) : (
                <span className="">{race.name}</span>
              )}
              {isStaff && placeholderRun?.id && (
                <Link to={`/run/${placeholderRun?.id}/color/`}>
                  <FontAwesomeIcon
                    icon={faPalette}
                    size="xs"
                    inverse
                    className="ml-2"
                  />
                </Link>
              )}
            </p>
            <p className="text-lg text-gray-300 space-x-1">
              <span>{race.trackName}</span>
              {/* <Moment
                date={date}
                format={"dddd MMM Do @ h:mm a"}
                className="text-gray-400 text-sm italic"
              /> */}
              <span className="text-gray-400 text-sm">
                {race.televisionBroadcaster} | {race.radioBroadcaster}
              </span>
            </p>
            {/* {date.isBefore() && ( */}
            {/* <div className="mt-2 text-gray-800 dark:text-gray-400 text-sm">
              <Link
                className="mr-1 p-2 border rounded whitespace-nowrap my-1 inline-block"
                to={`/race/series_${race.series_id}/${race.race_id}`}
              >
                view full results
              </Link>
              <span
                className="cursor-pointer inline-block p-2 border rounded ml-1 whitespace-nowrap my-1"
                onClick={() => setOpen(!open)}
              >
                {open ? "less" : "more"}
              </span>{" "}
            </div> */}
            {/* )} */}
          </div>
        </div>
        {open &&
          Object.values(
            mapValues(groupedRuns, (runs: RaceRun[], type: number) =>
              runs.length > 1 ? (
                <React.Fragment key={type}>
                  <h4 className="font-semibold text-xl">{RUN_TYPES[type]}</h4>
                  <ul>
                    {runs.map(run => (
                      <li key={run.id} className="ml-5">
                        <Link to={`/run/${run.id}/`}>
                          {type === QUALIFYING
                            ? run.name
                                .replace(race.name, "")
                                .replace("Busch Pole", "")
                                .replace("Qualifying - ", "")
                            : run.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ) : (
                <React.Fragment key={type}>
                  <h4 className="text-xl">
                    <Link to={`/run/${runs[0].id}/`}>
                      <span className="font-semibold">{RUN_TYPES[type]}</span>
                      {runs[0].type === RACE && `: ${runs[0].name}`}
                    </Link>
                  </h4>
                </React.Fragment>
              )
            )
          )}
        {Object.values(groupedRuns).length > 0 && (
          <span
            className="cursor-pointer inline-block text-xs text-gray-400 ml-1 whitespace-nowrap my-1"
            onClick={() => setOpen(!open)}
          >
            {open ? "hide runs" : "view runs"}
          </span>
        )}
      </div>
    </div>
  );
};

export default RaceDetail;
