/** @format */

import { createContext, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useLiveRun } from "./api/runsApi";
import { useUser } from "./api/userAPI";
import { useWSData } from "./api/wsAPI";
import RootRouter from "./RootRouter";
import Header from "./utils/Header";
import Loading from "./utils/Loading";
import UpdateToast from "./utils/UpdateToast";

export const WebSocketContext = createContext(
  {} as {
    liveID: string;
    readyState: number;
    messages: string[];
  }
);

const LiveWrapper = () => {
  const {
    data: { id: liveID },
    status: liveStatus,
    error: liveError,
  } = useLiveRun();
  const { status: userStatus, error: userError } = useUser();
  const [wsData, setWsData] = useState({ readyState: 0 });
  useWSData(setWsData, liveID);
  return (
    <Loading
      statuses={[liveStatus, userStatus]}
      errors={[liveError, userError]}
      wrap={false}
    >
      <WebSocketContext.Provider
        value={{
          liveID,
          messages: [],
          ...wsData,
        }}
      >
        <Router>
          <Header />
          <RootRouter />
        </Router>
        <UpdateToast />
      </WebSocketContext.Provider>
    </Loading>
  );
};

export default LiveWrapper;
