/** @format */

import {
  faArrows,
  faCheckCircle,
  faClipboard,
  faClone,
  faCog,
  faHome,
  faLayerPlus,
  faLock,
  faLockOpen,
  faPencil,
  faSave,
  faServer,
  faSquarePlus,
  faStop,
  faTimesCircle,
  faTrashCan,
  faTrashUndo,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPalette,
  faRotateLeft,
  faRotateRight,
  faTimes,
} from "@fortawesome/sharp-regular-svg-icons";
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/sharp-solid-svg-icons";
import { Popover } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import { isDev } from "../../../index";
import { pick, pickBy } from "lodash";
import { useActiveFeatureFlags } from "posthog-js/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useMeasure from "react-use-measure";
import { API_HOST } from "../../../api/galaxieClient";
import {
  useAddView,
  useRemoveView,
  useUpdateView,
  useUpdateViews,
  useUser,
} from "../../../api/userAPI";
import { WebSocketContext } from "../../../LiveWrapper";
import Loading from "../../../utils/Loading";
import { stockIcons } from "../../../utils/stockIcons";
import useLiveParams from "../../../utils/useLiveParams";
import {
  capFirst,
  isInStandaloneMode,
  searchParamsToObj,
} from "../../../utils/utility";
import GridComponent from "./GridComponent";
import GridLayout from "./GridLayout";
import {
  componentStateDefault,
  defaultViews,
  devices,
  emptyLayout,
  landscape,
  portrait,
} from "./gridWrapperDefaults";
import { GridContext } from "../WidgetWrapper";
import componentMap from "../widgets/WidgetsMap";

// ToDo - Add way to rename layouts
// ToDo - Add way to share layouts
// ToDo - Add way to import layouts

const deviceOrientation = (width: number, height: number): Orientations => {
  if (width > height) {
    return "landscape";
  } else {
    return "portrait";
  }
};

const fullScreen = !!document.fullscreenElement || isInStandaloneMode();
const toggleFullScreen = () => {
  if (!fullScreen) {
    document.documentElement?.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
};

const filterLayouts = (
  layouts: Record<DeviceType, GridComponent[]>
): Record<DeviceType, GridComponent[]> => {
  const filteredLayouts: Record<string, any> = {};
  Object.keys(layouts).forEach((breakpoint: string) => {
    filteredLayouts[breakpoint as keyof typeof layouts] = layouts[
      breakpoint as keyof typeof layouts
    ].map(item => {
      return pick(item, ["i", "x", "y", "w", "h", "comps", "showHeader"]);
    });
  });
  return filteredLayouts;
};

const ConfigurableGridWrapper = () => {
  const flags = useActiveFeatureFlags();
  const { runID } = useLiveParams();
  const [componentState, setComponentState] =
    useState<ComponentState>(componentStateDefault);
  const [locked, setLocked] = useState(true);
  const [editingView, setEditingView] = useState<RunView | null>(null);
  const [activeDevice, setActiveDevice] = useState<DeviceType | null>();
  const [breakpoint, setBreakpoint] = useState<DeviceType>("desktop");
  const [dimensions, _setDimensions] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isRearranging, setIsRearranging] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [gridRef, { width: gridWidth, height: gridHeight }] = useMeasure();

  const isEditing = useMemo(() => !!editingView, [editingView]);

  const activeViewID = useMemo(
    () => searchParams.get("activeView") || "acc16d83-e92e-4d3b-aeab-6da4724d4baf",
    [searchParams]
  );

  const activeDeviceDetails = useMemo(
    () => (activeDevice ? devices[activeDevice] : null),
    [activeDevice]
  );

  const {
    data: { isStaff, id: userID, runViews: userViews = [] } = { runViews: [] },
    status: userStatus,
    error: userError,
  } = useUser();
  const addView = useAddView();
  const { mutate: updateViews } = useUpdateViews();
  const updateView = useUpdateView();
  const removeView = useRemoveView();
  const { readyState, messages } = useContext(WebSocketContext);

  const orientation = useMemo(
    () => deviceOrientation(dimensions.width, dimensions.height),
    [dimensions.width, dimensions.height]
  );

  const setDimensions = useCallback(
    ({ width, height }: { width: number; height: number }) => {
      window.requestAnimationFrame(() => {
        _setDimensions({
          width: Math.floor(width),
          height: Math.floor(height),
        });
      });
    },
    []
  );

  useEffect(() => {
    // if (width < )
    if (!isEditing) {
      if (orientation === "landscape") {
        setDimensions({
          width: gridWidth - 40,
          height: gridHeight,
        });
      } else {
        setDimensions({
          width: gridWidth,
          height: gridHeight - 40,
        });
      }
    }
  }, [gridWidth, gridHeight, orientation, isEditing]);

  const views = useMemo(
    () =>
      [
        ...defaultViews
          .filter(({ id }) => !userViews?.find(view => view.id === id))
          .map(view => ({ ...view, source: "default" })),
        ...userViews?.map(view => ({ ...view, source: "user" })),
      ].sort((a, b) => a.order - b.order),
    [userViews]
  );

  const activeView = useMemo(
    () => views.find(view => view.id === activeViewID),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeViewID]
  );

  const isExistingView = userViews?.find(view => view.id === editingView?.id);
  const editingLayout = editingView?.[orientation][breakpoint];
  const activeLayout = editingLayout || activeView?.[orientation][breakpoint];

  const { breakpoints, cols } = useMemo(
    () => ({
      breakpoints:
        orientation === "landscape" ? landscape.breakpoints : portrait.breakpoints,
      cols: orientation === "landscape" ? landscape.cols : portrait.cols,
    }),
    [orientation]
  );

  const margin = useMemo(
    () =>
      (orientation === "landscape"
        ? landscape.margin[breakpoint]
        : portrait.margin[breakpoint]) || [5, 5],
    [breakpoint, orientation]
  );

  const rowHeight = useMemo(
    () => Math.floor((dimensions.height - 101 * margin[1]) / 100),
    [dimensions.height, margin]
  ); // 101 to account for container padding

  const setActiveViewID = useCallback(
    (id: string) => {
      if (isEditing) {
        // alert unsaved changes will be lost
        const confirm = window.confirm(
          "You have unsaved changes. Are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
        setEditingView(views?.find(view => view.id === id) || null);
      }
      setSearchParams(
        { ...searchParamsToObj(searchParams), activeView: id },
        { replace: true }
      );
    },
    [isEditing, searchParams, setSearchParams, views]
  );

  const pxToRows = useCallback(
    (px: number) => {
      // compensate for margin between rows
      return Math.floor(px / (rowHeight + margin[1]));
    },
    [rowHeight, margin]
  );

  const toggleEditing = () => {
    if (isEditing) {
      setEditingView(null);
      setIsRearranging(false);
    } else {
      if (activeView) {
        setEditingView(activeView);
      } else {
        throw new Error("No active view to edit");
      }
    }
  };

  const saveView = () => {
    if (isExistingView && editingView) {
      updateView(editingView);
    } else if (editingView) {
      addView(editingView);
    } else {
      throw new Error("No view to save");
    }
    setEditingView(null);
  };

  const deleteView = () => {
    if (isExistingView && editingView) {
      removeView(editingView.id);
    }
  };

  const duplicateView = () => {
    if (activeView) {
      const newID =
        activeView.default && !userViews.map(({ id }) => id).includes(activeView.id)
          ? activeView.id
          : crypto.randomUUID();
      setEditingView({
        name: `${activeView.name} (Duplicate)`,
        id: newID,
        landscape: activeView.landscape,
        portrait: activeView.portrait,
        order: activeView.order,
        default: false,
      });
    } else {
      throw new Error("No active view to duplicate");
    }
  };

  const updateEditingLayout = (newLayout: GridComponent[]) => {
    if (!editingView) {
      throw new Error("No view to update layout for");
    }
    setEditingView({
      ...editingView,
      [orientation]: {
        ...editingView[orientation],
        [breakpoint]: newLayout.map(item => {
          const i = item?.i?.replace(".$", "");
          const oldItem = editingView[orientation][breakpoint].find(
            ({ i: key }) => key === i
          ) || { comps: [] };
          return {
            ...oldItem,
            ...item,
            i,
          };
        }),
      },
    });
  };

  const addComponent = () => {
    if (!editingLayout) {
      throw new Error("No layout to add component to");
    }
    const ncIDX = Math.max(
      ...editingLayout
        ?.filter(item => item.i.includes("New Component "))
        ?.map(({ i }) => parseInt(i.split("New Component ")[1]))
        .filter(i => !isNaN(i))
    );
    const newIdx =
      isNaN(ncIDX) || ncIDX === Infinity || ncIDX === -Infinity ? 0 : ncIDX;
    updateEditingLayout([
      ...editingLayout,
      {
        i: `New Component ${newIdx + 1}`,
        x: 0,
        y: 0,
        w: 1,
        h: 50 / rowHeight,
        comps: [],
      },
    ]);
  };

  const removeComponent = (i: string) => {
    if (!editingLayout) {
      throw new Error("No layout to remove component from");
    }
    updateEditingLayout(editingLayout.filter(item => item.i !== i));
  };

  return (
    <Loading statuses={[userStatus]} errors={[userError]} wrap={false}>
      <div
        ref={gridRef}
        className={`flex portrait:flex-col-reverse landscape:flex-row bg-[#100010] ${
          fullScreen || locked ? "fixed inset-0" : "h-screen w-screen"
        }`}
        id="grid-wrapper"
      >
        {isDev && (
          <div className="fixed top-0 z-10 mt-1 left-1/2 transform -translate-x-1/2 opacity-80 bg-purple-500 text-blue-200 rounded px-2">
            {breakpoint} {orientation} {cols[breakpoint]}
          </div>
        )}
        <div
          className="w-full h-full m-auto flex"
          style={{
            ...dimensions,
          }}
        >
          <GridContext.Provider
            value={{
              componentState,
              setComponentState,
            }}
          >
            <GridLayout
              onLayoutChange={(layout: GridComponent[], layouts: any) => {
                if (breakpoint && isEditing && layout.length > 0) {
                  updateEditingLayout(layout);
                }
              }}
              onBreakpointChange={(newBreakpoint: DeviceType) =>
                setBreakpoint(newBreakpoint)
              }
              breakpoints={breakpoints}
              cols={cols}
              compactType="vertical"
              rowHeight={rowHeight}
              autoSize={false}
              width={dimensions.width}
              height={dimensions.height}
              isDraggable={isRearranging}
              isResizable={isRearranging}
              isBounded={true}
              margin={margin}
              containerPadding={margin}
              layouts={{
                [breakpoint]: activeLayout || emptyLayout[orientation][breakpoint],
              }}
              resizeHandles={["nw", "ne", "sw", "se"]}
            >
              {activeLayout?.map(
                ({ i, x, y, w, h, comps = [], showHeader = false }) => {
                  const compies = comps
                    .map(({ key, ...rest }) => ({
                      key,
                      ...rest,
                      ...componentMap[key],
                    }))
                    .filter(({ flag }) => !flag || flags?.includes(flag));
                  const minH = Math.max(...compies.map(({ minH = 10 }) => minH));
                  return (
                    <GridComponent
                      key={i}
                      id={i} // ToDo - Add id to GridComponent
                      name={i}
                      components={compies}
                      comps={comps}
                      showHeader={showHeader}
                      isEditing={isEditing}
                      isRearranging={isRearranging}
                      onCompsChange={(newComps: GridWidget[]) => {
                        if (!editingLayout) {
                          throw new Error("No layout to update components for");
                        }
                        updateEditingLayout(
                          editingLayout.map(item =>
                            item.i === i ? { ...item, comps: newComps } : item
                          )
                        );
                      }}
                      availableComponents={Object.keys(
                        pickBy(
                          componentMap,
                          ({ flag }) => !flag || flags?.includes(flag)
                        )
                      )}
                      onNameChange={(newName: string) => {
                        if (!editingLayout) {
                          throw new Error("No layout to update name for");
                        }
                        updateEditingLayout(
                          editingLayout.map(item => {
                            if (item.i === i) {
                              return {
                                ...item,
                                i: newName,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                      onCompDelete={() => removeComponent(i)}
                      data-grid={{
                        i,
                        x,
                        y,
                        w,
                        h: Math.max(h, pxToRows(minH)),
                        minW: 1,
                        minH: pxToRows(minH),
                      }}
                    />
                  );
                }
              )}
            </GridLayout>
          </GridContext.Provider>
        </div>
        <div className="whitespace-nowrap relative portrait:h-[40px] portrait:w-full landscape:h-full landscape:w-[40px] bg-gray-950">
          <div className="absolute landscape:top-0 portrait:left-0 portrait:h-[40px] landscape:w-[40px] p-1 border-gray-800 portrait:border-t landscape:border-l landscape:left-auto landscape:bottom-0 right-0 portrait:gap-x-2 landscape:gap-y-2 flex landscape:flex-col text-left ">
            <SidebarButton href="/" type="link" tooltipContent="Home">
              <FontAwesomeIcon fixedWidth icon={faHome} />
            </SidebarButton>
            {isStaff && (
              <>
                <SidebarButton
                  href={`/run/${runID}/color`}
                  type="link"
                  tooltipContent="Run Colors"
                >
                  <FontAwesomeIcon fixedWidth icon={faPalette} />
                </SidebarButton>
                <SidebarButton
                  href={`${API_HOST}/admin/backend/run/${runID}/change/`}
                  type="link"
                  tooltipContent="Backend Run"
                >
                  <FontAwesomeIcon fixedWidth icon={faServer} />
                </SidebarButton>
              </>
            )}
            <SidebarButton tooltipContent={`WebSocket Status ${messages}`}>
              {readyState === 1 ? (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faCheckCircle}
                  className="text-green-500"
                />
              ) : (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faTimesCircle}
                  className="text-red-500"
                />
              )}
            </SidebarButton>
            {views.map(({ name, flag, id }, index) =>
              flag && !flags?.includes(flag) ? null : (
                <SidebarButton
                  key={name}
                  onClick={() => setActiveViewID(id)}
                  tooltipContent={name}
                  className={
                    id === activeViewID && (!isEditing || editingView?.id === id)
                      ? "bg-gray-600"
                      : "bg-gray-800"
                  }
                >
                  {name.slice(0, 2)}
                </SidebarButton>
              )
            )}
            {flags?.includes("editGrids") && userID && (
              <>
                {userViews.length > 0 && (
                  <Popover
                    as={SidebarButton}
                    tooltipContent="Views Settings"
                    className="relative"
                  >
                    <Popover.Button as={"span"}>
                      <FontAwesomeIcon fixedWidth icon={faCog} />
                    </Popover.Button>
                    <Popover.Panel className="bg-gray-800 shrink p-2 absolute right-0 z-20 rounded text-left">
                      {views.map(
                        ({ name, flag, id, order, default: isDefault, ...rest }) => (
                          <div
                            key={id}
                            className="rounded px-1 my-1 flex align-middle bg-gray-500 "
                          >
                            <div className="flex flex-col mr-1">
                              <button
                                type="button"
                                title="Move Up"
                                className="text-center truncate cursor-pointer text-xs font-semibold -mb-0.5 disabled:opacity-50"
                                onClick={() => {
                                  updateViews(
                                    views
                                      .map(view => {
                                        if (view.id === id) {
                                          return { ...view, order: order - 1 };
                                        }
                                        if (view.order === order - 1) {
                                          return { ...view, order: order };
                                        }
                                        return view;
                                      })
                                      .filter(view => !view.default)
                                  ); // ToDo - Account for default views
                                }}
                                disabled={order === 0 || isDefault}
                              >
                                <FontAwesomeIcon
                                  icon={faChevronUp}
                                  className="text-white text-xs"
                                />
                              </button>
                              <button
                                type="button"
                                title="Move Down"
                                className="text-center truncate cursor-pointer font-semibold text-xs -mt-0.5 disabled:opacity-50"
                                onClick={() => {
                                  updateViews(
                                    views
                                      .map(view => {
                                        if (view.id === id) {
                                          return { ...view, order: order + 1 };
                                        }
                                        if (view.order === order + 1) {
                                          return { ...view, order: order };
                                        }
                                        return view;
                                      })
                                      .filter(view => !view.default)
                                  ); // ToDo - Account for default views
                                }}
                                disabled={order === views.length - 1 || isDefault}
                              >
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="text-white text-xs"
                                />
                              </button>
                            </div>
                            <div
                              className="truncate whitespace-nowrap grow leading-normal"
                              contentEditable={!isDefault}
                              suppressContentEditableWarning
                              onBlur={e => {
                                updateView({
                                  ...rest,
                                  name: e.target.innerText,
                                  id,
                                  order,
                                  default: false,
                                });
                              }}
                            >
                              {name}
                            </div>
                          </div>
                        )
                      )}
                    </Popover.Panel>
                  </Popover>
                )}
                <SidebarButton
                  onClick={() => {
                    setEditingView({
                      name: `New View`,
                      ...emptyLayout,
                      default: false,
                      order: views.length,
                      id: crypto.randomUUID(),
                    });
                  }}
                  className={
                    isEditing && editingView?.id !== activeView?.id
                      ? "bg-gray-600"
                      : "bg-gray-800"
                  }
                  tooltipContent="Create View"
                >
                  <FontAwesomeIcon fixedWidth icon={faLayerPlus} />
                </SidebarButton>
                <SidebarButton onClick={duplicateView} tooltipContent="Duplicate View">
                  <FontAwesomeIcon fixedWidth icon={faClone} />
                </SidebarButton>
              </>
            )}
            <hr className="border-gray-500" />
            {!fullScreen && (
              <SidebarButton
                tooltipContent={locked ? "Unlock" : "Lock"}
                onClick={() => setLocked(!locked)}
              >
                {locked ? (
                  <FontAwesomeIcon fixedWidth icon={faLockOpen} />
                ) : (
                  <FontAwesomeIcon fixedWidth icon={faLock} />
                )}
              </SidebarButton>
            )}
            <SidebarButton
              onClick={toggleFullScreen}
              tooltipContent={fullScreen ? "Exit Full Screen" : "Full Screen"}
            >
              {fullScreen ? (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faDownLeftAndUpRightToCenter}
                  className="text-gray-600"
                />
              ) : (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faUpRightAndDownLeftFromCenter}
                  className="text-gray-200"
                />
              )}
            </SidebarButton>
            {flags?.includes("editGrids") && isEditing && !editingView?.default && (
              <SidebarButton tooltipContent="Save View">
                <FontAwesomeIcon fixedWidth onClick={saveView} icon={faSave} />
              </SidebarButton>
            )}
            {flags?.includes("editGrids") && userID && (
              <>
                <SidebarButton
                  onClick={toggleEditing}
                  tooltipContent={isEditing ? "Discard Changes" : "Edit View"}
                >
                  {isEditing ? (
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faTrashUndo}
                      className="text-red-500"
                    />
                  ) : (
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faPencil}
                      className="text-green-500"
                    />
                  )}
                </SidebarButton>
                {isEditing && (
                  <SidebarButton
                    onClick={() => setIsRearranging(!isRearranging)}
                    tooltipContent={isRearranging ? "Stop Rearranging" : "Rearrange"}
                  >
                    {isRearranging ? (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faStop}
                        className="text-red-500"
                      />
                    ) : (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faArrows}
                        className="text-blue-500"
                      />
                    )}
                  </SidebarButton>
                )}
              </>
            )}
            {isStaff && (
              <SidebarButton
                onClick={() => {
                  if (!editingView) {
                    throw new Error("No view to copy layout from");
                  }
                  navigator.clipboard.writeText(
                    JSON.stringify(filterLayouts(editingView[orientation])[breakpoint])
                  );
                }}
                tooltipContent="Copy Layout"
              >
                <FontAwesomeIcon fixedWidth icon={faClipboard} />
              </SidebarButton>
            )}
            {flags?.includes("editGrids") && isEditing && isExistingView && (
              <SidebarButton tooltipContent="Delete View">
                <FontAwesomeIcon
                  fixedWidth
                  onClick={deleteView}
                  className="text-red-500 hover:text-red-600"
                  icon={faTrashCan}
                />
              </SidebarButton>
            )}
            {flags?.includes("editGrids") && isEditing && (
              <>
                <SidebarButton onClick={addComponent} tooltipContent="Add Component">
                  <FontAwesomeIcon fixedWidth icon={faSquarePlus} />
                </SidebarButton>
                <hr className="border-gray-500" />
                {activeDevice && (
                  <SidebarButton
                    onClick={() => {
                      setDimensions({
                        width: gridWidth - 40,
                        height: gridHeight,
                      });
                      setActiveDevice(null);
                    }}
                    tooltipContent="Reset"
                  >
                    <FontAwesomeIcon fixedWidth icon={faTimes} />
                  </SidebarButton>
                )}
                {Object.keys(devices)
                  .map(deviceName => ({
                    deviceName,
                    ...devices[deviceName as DeviceType],
                  }))
                  .map(({ deviceName, ...orientations }) => (
                    <SidebarButton
                      key={deviceName}
                      onClick={() => {
                        setActiveDevice(deviceName as DeviceType);
                        const or =
                          orientations[orientation] || orientations["landscape"];
                        setDimensions({
                          width: or.width,
                          height: or.height,
                        });
                      }}
                      className={
                        activeDevice === deviceName ? "bg-gray-600" : "bg-gray-800"
                      }
                      tooltipContent={capFirst(deviceName)}
                    >
                      <FontAwesomeIcon fixedWidth icon={stockIcons[deviceName]} />
                    </SidebarButton>
                  ))}
                {activeDeviceDetails?.landscape && activeDeviceDetails?.portrait && (
                  <>
                    <hr className="border-gray-500" />
                    {orientation === "portrait" ? (
                      <SidebarButton
                        onClick={() =>
                          setDimensions({
                            width: activeDeviceDetails?.landscape.width,
                            height: activeDeviceDetails?.landscape.height,
                          })
                        }
                        tooltipContent="Landscape"
                      >
                        <FontAwesomeIcon fixedWidth icon={faRotateRight} />
                      </SidebarButton>
                    ) : (
                      <SidebarButton
                        onClick={() =>
                          setDimensions({
                            width: activeDeviceDetails.portrait.width,
                            height: activeDeviceDetails.portrait.height,
                          })
                        }
                        tooltipContent="Portrait"
                      >
                        <FontAwesomeIcon fixedWidth icon={faRotateLeft} />
                      </SidebarButton>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Loading>
  );
};

export default ConfigurableGridWrapper;

interface SidebarButtonProps {
  children: React.ReactNode;
  tooltipContent: string;
  onClick?: () => void;
  className?: string;
  type?: "button" | "link";
  href?: string;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  children,
  tooltipContent,
  onClick,
  className = "",
  type = "button",
  href = "",
}) => {
  return (
    <Tippy
      placement="left"
      // disable on touch devices
      touch={false}
      content={
        <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
          {tooltipContent}
        </span>
      }
    >
      {type === "link" ? (
        <a
          aria-label={tooltipContent}
          className={`cursor-pointer text-xl  rounded flex align-middle aspect-square shrink justify-start ${className}`}
          href={href}
        >
          <div className="leading-none w-full text-center my-auto">{children}</div>
        </a>
      ) : (
        <button
          type="button"
          aria-label={tooltipContent}
          className={`cursor-pointer text-xl  rounded flex align-middle aspect-square shrink justify-start ${className}`}
          onClick={onClick}
        >
          <div className="leading-none w-full text-center my-auto">{children}</div>
        </button>
      )}
    </Tippy>
  );
};
