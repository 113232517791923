/** @format */

import React, { useMemo } from "react";
import { Responsive } from "react-grid-layout";

interface GridLayoutProps {
  children: React.ReactNode;
  [key: string]: any;
}

const GridLayout: React.FC<GridLayoutProps> = ({ children, ...props }) => {
  const memoChildren = useMemo(() => {
    return React.Children.map(children, (child, i) => {
      return child;
    });
  }, [children]);
  return (
    <Responsive
      className="layout grow bg-[#00000d] h-full w-full overflow-hidden m-auto transition-all duration-300 ease-in-out"
      {...props}
    >
      {memoChildren}
    </Responsive>
  );
};

export default GridLayout;
