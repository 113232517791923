/** @format */

import { useParams } from "react-router-dom";
import { useLiveRun } from "../api/runsApi";

const useLiveParams = (): {
  [key: string]: string;
  runID: string;
} => {
  const { runID, ...params } = useParams();
  if (!runID) {
    throw new Error("runID is required");
  }
  const {
    data: { id: liveID },
  }: {
    data: { id: string | undefined };
  } = useLiveRun();
  if (runID === "live" && liveID) {
    return { ...params, runID: liveID };
  } else if (runID === "live") {
    window.location.href = "/";
  } else if (runID) {
    return { runID, ...params };
  }
  return { runID: runID || "", ...params };
};

export default useLiveParams;
