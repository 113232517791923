/** @format */

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useActiveFeatureFlags } from "posthog-js/react";
import { Navigate, useLocation } from "react-router-dom";
import { useLogin, useUser } from "../api/userAPI";
import LoadingCircle from "../utils/LoadingCircle";
const Login = ({
  setModalComponent,
  setModalOpen,
}: {
  setModalComponent: Function;
  setModalOpen: Function;
}) => {
  const { data: user, refetch } = useUser({ enabled: false });
  const location = useLocation();
  const flags = useActiveFeatureFlags();
  const login = useLogin();
  return user?.id || !flags?.includes("login") ? (
    <Navigate to={location.state?.pathname || "/"} replace state={location} />
  ) : (
    <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full mt-2 text-white">
      <h1 className="text-xl mb-2">Login</h1>
      <Formik
        initialValues={{ email: "", password: "", rememberMe: true }}
        validate={values => {
          const errors = {} as { email?: string };
          if (!values.email) {
            errors.email = "Required";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(
          { email, password, rememberMe },
          { setSubmitting, setFieldError, setErrors }
        ) => {
          setSubmitting(true);
          setTimeout(() => {
            login(email, password, rememberMe)
              .then(() => refetch())
              .then(() => {
                setSubmitting(false);
                setModalOpen(false);
                setModalComponent(null);
              })
              .catch(error => {
                // setErrors
                if (error.response?.status === 400) {
                  if (error.response?.data?.email) {
                    setFieldError("email", error.response.data.email);
                  } else if (error.response?.data?.password) {
                    setFieldError("password", error.response.data.password);
                  } else if (error.response?.data?.nonFieldErrors) {
                    setFieldError("password", error.response.data.nonFieldErrors);
                  } else {
                    setFieldError("password", "Something went wrong");
                  }
                } else {
                  setFieldError("password", "Something went wrong");
                }
                console.error(error);
                setSubmitting(false);
              });
          }, 400);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-200"
              >
                E-Mail
              </label>
              <div className="mt-1">
                <Field
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  required
                  className=" bg-black  appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                />
              </div>
              <ErrorMessage name="email" component="div" className="text-red-600" />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-200"
              >
                Password
              </label>
              <div className="mt-1">
                <Field
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border bg-black border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                />
              </div>
              <ErrorMessage name="password" component="div" className="text-red-600" />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Field
                  id="rememberMe"
                  name="rememberMe"
                  type="checkbox"
                  className="h-4 w-4 text-cPurple-600 focus:ring-cPurple-500 border-gray-500 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-400"
                >
                  Remember me
                </label>
              </div>
            </div>
            <div>
              <button
                aria-label="Login"
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                {isSubmitting ? (
                  <>
                    <LoadingCircle className="animate-spin h-5 w-5 mr-1 text-white inline-block" />
                    Loading
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="text-sm text-center text-gray-400 gap-1 grid grid-flow-col grid-cols-max justify-center">
              {/** //ToDo: Make this Prettier**/}
              <button
                aria-label="Reset Password"
                onClick={() => {
                  setModalComponent("resetPassword");
                }}
                className="font-medium text-cPurple-600 hover:text-cPurple-500"
              >
                Forgot your password?
              </button>
              {flags?.includes("register") && (
                <>
                  |
                  <button
                    aria-label="Register"
                    onClick={() => {
                      setModalComponent("register");
                    }}
                    className="font-medium text-cPurple-600 hover:text-cPurple-500"
                  >
                    Sign Up
                  </button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
