/** @format */

import {
  faArrowsDownToLine,
  faArrowsToLine,
  faArrowsUpToLine,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import galaxieClient from "../api/galaxieClient";
import { useUpdateUser, useUser } from "../api/userAPI";
import DriverMultiSelect from "../utils/DriverMultiSelect";
import Loading from "../utils/Loading";
import LoadingCircle from "../utils/LoadingCircle";

const UserProfile = ({
  setModalComponent,
  setModalOpen,
}: {
  setModalComponent: Function;
  setModalOpen: Function;
}) => {
  const { data: user, status: userStatus, error: userError } = useUser();
  const posthog = usePostHog();
  const {
    data: drivers,
    status: driverStatus,
    error: driverError,
  } = useQuery({
    queryKey: ["drivers"],
    queryFn: () => galaxieClient.get("/drivers/").then(res => res.data),
  });
  const { mutateAsync } = useUpdateUser();
  const [success, setSuccess] = useState(false);
  return success ? (
    <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-[800px] max-w-screen max-w-full">
      <h1 className="text-xl mb-2">User Profile</h1>
      <div className="text-green-700">
        <p>
          Your profile has been updated successfully.
          {/*If you changed your email, you will receive an email with a link to confirm your new email.*/}
        </p>
        <button
          type="button"
          onClick={() => {
            setModalComponent(null);
            setModalOpen(false);
          }}
          className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Close
        </button>
      </div>
    </div>
  ) : (
    <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-[800px] max-w-screen max-w-full">
      <h1 className="text-xl mb-2">User Profile</h1>
      <Loading
        statuses={[userStatus, driverStatus]}
        errors={[userError, driverError]}
        wrap={false}
      >
        <Formik
          initialValues={{
            shortName: user?.shortName || "",
            fullName: user?.fullName || "",
            email1: user?.email || "",
            email2: user?.email || "",
            favoriteDrivers: user?.favoriteDrivers || [],
            savedScanners: user?.savedScanners || [],
          }}
          validate={values => {
            const errors = {} as {
              shortName?: string;
              fullName?: string;
              email1?: string;
              email2?: string;
            };
            if (!values.fullName) {
              errors.fullName = "Required";
            }
            if (!values.shortName) {
              errors.shortName = "Required";
            }
            if (!values.email1) {
              errors.email1 = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email1)
            ) {
              errors.email1 = "Invalid email address";
            }
            if (!values.email2) {
              errors.email1 = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email2)
            ) {
              errors.email2 = "Invalid email address";
            }
            if (values.email1 !== values.email2) {
              errors.email2 = "Emails must match";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            setTimeout(async () => {
              try {
                const data = await mutateAsync(values);
                setSubmitting(false);
                setSuccess(true);
              } catch (error: any) {
                // Todo  - Fix any
                //Set error messages
                if (error.response) {
                  if (error.response.status === 400) {
                    if (error.response.data.email) {
                      setFieldError("email1", error.response.data.email.join("\n"));
                    } else if (error.response.data.nonFieldErrors) {
                      setFieldError(
                        "password2",
                        error.response.data.nonFieldErrors.join("\n")
                      );
                    }
                  } else {
                    setFieldError("email1", "Something went wrong");
                  }
                } else {
                  setFieldError("email1", "Something went wrong");
                }
                console.error(error);
                setSubmitting(false);
              }
            }, 400);
          }}
        >
          {({
            isSubmitting,
            errors,
            values: { favoriteDrivers, savedScanners },
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="space-y-6">
              <div className="grid grid-cols-2 space-x-2">
                <div className="grow-0">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="fullName"
                      id="fullName"
                      autoComplete="fullName"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-red-600 whitespace-pre-wrap"
                  />
                </div>
                <div className="grow-0">
                  <label
                    htmlFor="shortName"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Short Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="shortName"
                      id="shortName"
                      autoComplete="shortName"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="shortName"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 space-x-2 ">
                <div className="grow-0">
                  <label
                    htmlFor="email1"
                    className="block text-sm font-medium text-gray-200"
                  >
                    E-Mail
                  </label>
                  <div className="mt-1">
                    <Field
                      type="email"
                      name="email1"
                      id="email1"
                      autoComplete="email"
                      required
                      disabled
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-700"
                    />
                  </div>
                  <ErrorMessage
                    name="email1"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="grow-0">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Confirm E-Mail
                  </label>
                  <div className="mt-1">
                    <Field
                      type="email"
                      name="email2"
                      id="email2"
                      autoComplete="email"
                      required
                      disabled
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-700"
                    />
                  </div>
                  <ErrorMessage
                    name="email2"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
              {/* <div className="grid grid-cols-2 space-x-2"> */}
              <div className="grow-0">
                <label
                  htmlFor="favoriteDrivers"
                  className="block text-sm font-medium text-gray-200"
                >
                  Favorite Drivers
                </label>
                <div className="mt-1 bg-black">
                  <DriverMultiSelect
                    selectedDrivers={favoriteDrivers}
                    setSelectedDrivers={(drivers: string[]) =>
                      setFieldValue("favoriteDrivers", drivers)
                    }
                    drivers={drivers}
                  />
                </div>
              </div>
              <div className="gap-1 text-xl">
                <Tippy
                  placement="top"
                  touch={false}
                  content={
                    <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                      Copy favorite drivers to saved scanners
                    </span>
                  }
                >
                  <button
                    title="Copy favorite drivers to saved scanners"
                    type="button"
                    onClick={() => {
                      setFieldValue("savedScanners", favoriteDrivers);
                    }}
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={faArrowsDownToLine} />
                  </button>
                </Tippy>
                <Tippy
                  placement="top"
                  touch={false}
                  content={
                    <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                      Merge favorite drivers and saved scanners
                    </span>
                  }
                >
                  <button
                    title="Merge favorite drivers and saved scanners"
                    type="button"
                    onClick={() => {
                      // merge favorite drivers and saved scanners
                      const newValues = [
                        ...new Set([...favoriteDrivers, ...savedScanners]),
                      ];
                      setFieldValue("savedScanners", newValues);
                      setFieldValue("favoriteDrivers", newValues);
                    }}
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={faArrowsToLine} />
                  </button>
                </Tippy>
                <Tippy
                  placement="top"
                  touch={false}
                  content={
                    <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                      Copy saved scanners to favorite drivers
                    </span>
                  }
                >
                  <button
                    title="Copy saved scanners to favorite drivers"
                    type="button"
                    onClick={() => {
                      setFieldValue("favoriteDrivers", savedScanners);
                      // copy saved scanners to favorite drivers
                    }}
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={faArrowsUpToLine} />
                  </button>
                </Tippy>
              </div>
              <div className="grow-0">
                <label
                  htmlFor="favoriteScanners"
                  className="block text-sm font-medium text-gray-200"
                >
                  Saved Scanners
                </label>
                <div className="mt-1 bg-black">
                  <DriverMultiSelect
                    selectedDrivers={savedScanners}
                    setSelectedDrivers={(drivers: string[]) =>
                      setFieldValue("savedScanners", drivers)
                    }
                    drivers={drivers}
                  />
                </div>
              </div>
              {/* </div> */}
              <div className="flex justify-between gap-x-5">
                <button
                  onClick={() => {
                    setModalComponent(null);
                    setModalOpen(false);
                  }}
                  type="reset"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-700 disabled:hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-700 disabled:hover:bg-gray-700"
                >
                  {isSubmitting ? (
                    <>
                      <LoadingCircle className="h-5 w-5 mr-2" />
                      Saving
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="text-sm text-center text-gray-400">
                <button
                  type="button"
                  onClick={() => {
                    setModalOpen(true);
                    setModalComponent("resetPassword");
                  }}
                  className="font-medium text-cPurple-600 hover:text-cPurple-500"
                >
                  Change Password?
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {user?.isStaff && (
          <div className="flex">
            <button
              className="block px-4 py-2 w-full text-sm text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center"
              type="button"
              onClick={() => posthog.capture("Test Posthog")}
            >
              Test Posthog
            </button>
            <button
              className="block px-4 py-2 w-full text-sm text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-center"
              type="button"
              onClick={() => {
                const test = 1 / 0;
                console.log(test);
              }}
            >
              Test Sentry
            </button>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default UserProfile;
