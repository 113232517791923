/** @format */

import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import consoleMessage from "./console";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./utils/ErrorBoundary";
// import axe from "@axe-core/react";

export const isDev = import.meta.env.MODE === "development";
// hax to get the version from vite
declare const process: {
  env: {
    VERSION: string;
  };
};
console.info(consoleMessage);

// if (isDev) {
//   axe(React, ReactDOM, 1000);
// }

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

const rootElement = document.getElementById("root");

if (rootElement === null) {
  throw new Error("No root element found");
}

const root = ReactDOM.createRoot(rootElement);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: process.env.VERSION,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.galaxie\.app\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const FallbackComponent = () => <ErrorBoundary />;

root.render(
  <StrictMode>
    <PostHogProvider options={options} apiKey={import.meta.env.VITE_POSTHOG_CLIENT_ID}>
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <App />
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
