/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/sharp-regular-svg-icons";
import { Combobox } from "@headlessui/react";
import { useState } from "react";

const DriverMultiSelect = ({
  drivers,
  selectedDrivers,
  setSelectedDrivers,
}: {
  drivers: DriverList[];
  selectedDrivers: string[];
  setSelectedDrivers: Function;
}) => {
  const [query, setQuery] = useState("");
  const filteredDrivers = drivers.filter(
    driver =>
      driver.displayName?.toLowerCase()?.includes(query.toLowerCase()) ||
      driver.firstName?.toLowerCase()?.includes(query.toLowerCase()) ||
      driver.lastName?.toLowerCase()?.includes(query.toLowerCase())
  );
  return (
    <Combobox
      value={selectedDrivers}
      onChange={e => {
        setSelectedDrivers(e);
        setQuery("");
      }}
      multiple
    >
      <div className="relative mt-2">
        <div className="relative w-full h-full overflow-hidden align-middle select-none transition duration-200 ease-in rounded border-gray-500 border flex flex-col">
          <div className="flex flex-wrap gap-1.5 p-1">
            {selectedDrivers.length > 0 &&
              drivers
                .filter(driver => selectedDrivers.includes(driver.id))
                .sort(
                  (a, b) =>
                    a.lastName?.localeCompare(b.lastName) ||
                    a.firstName?.localeCompare(b.firstName)
                )
                .map(driver => (
                  <div
                    key={driver.id}
                    className="bg-gray-800 flex items-center whitespace-nowrap shrink px-0.5 py-0.5 rounded leading-none text-sm truncate border border-collapse my-auto"
                  >
                    <span className="text-sm whitespace-nowrap truncate grow text-left">
                      {driver.firstName} {driver.lastName}
                    </span>
                    <button
                      title="Remove"
                      type="button"
                      className="ml-1 pointer-events-auto"
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedDrivers(
                          selectedDrivers.filter(v => v !== driver.id)
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="h-4 w-4" />
                    </button>
                  </div>
                ))}
          </div>
          {/* <div className="flex grow"> */}
          <Combobox.Input
            className="bg-transparent border text-white h-full grow rounded border-gray-400 m-1 focus:appearance-none focus:ring-0 focus:border-white focus:outline-none"
            value={query}
            onChange={event => setQuery(event.target.value)}
            placeholder="Search for a driver..."
          />
          {/* </div> */}
        </div>
        <Combobox.Options className="bg-black text-white p-1 absolute gap-1.5 w-full flex z-50 mt-1 max-h-60 flex-wrap overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredDrivers
            .filter(driver => !selectedDrivers.includes(driver.id))
            .sort(
              (a, b) =>
                a.lastName?.localeCompare(b.lastName) ||
                a.firstName?.localeCompare(b.firstName)
            )
            .map(driver => (
              <Combobox.Option
                key={driver.id}
                value={driver.id}
                className={({ active, selected }) =>
                  `text-sm whitespace-nowrap border border-collapse rounded p-1 shrink cursor-pointer bg-gray-800 ${
                    selected ? "font-bold" : ""
                  }}`
                }
              >
                {driver.firstName} {driver.lastName}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default DriverMultiSelect;
