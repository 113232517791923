/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/sharp-light-svg-icons";
import { Dialog, Popover } from "@headlessui/react";
import { useActiveFeatureFlags } from "posthog-js/react";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFetchLiveFeed, useLiveRun } from "../api/runsApi";
import { logout, useUser } from "../api/userAPI";
import logo from "../assets/logo.png";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/SignUp";
import UserProfile from "../pages/UserProfile";
import LoadingCircle from "./LoadingCircle";

const Header = () => {
  // check react router for widget page
  const widgetPage = window.location.pathname.split("/").includes("widget");
  const { data: { id, flag, name, track } = {} } = useLiveRun();
  const { data: user, status } = useUser();
  const { refetch } = useFetchLiveFeed();
  const flags = useActiveFeatureFlags();
  const [searchParams] = useSearchParams();
  const modal = searchParams.get("modal");
  const [modalOpen, setModalOpen] = useState(!!modal);
  const [modalComponent, setModalComponent] = useState(modal || "login");
  return widgetPage ? null : (
    <header className="">
      <div
        className="grid grid-cols-3 z-10 w-screen justify-between items-center px-2 py-1"
        data-flag={flag || ""}
      >
        <div className="justify-start flex">
          <Link
            to="/"
            className="block bg-black bg-opacity-70 rounded-xl py-1 px-2 shrink"
          >
            {id ? (
              <LoadingCircle className="inline-block h-8 w-auto" />
            ) : (
              <img src={logo} alt="logo" className="inline-block h-8 w-auto" />
            )}
            <span className="text-md md:text-2xl mx-2 align-bottom">Galaxie</span>
          </Link>
        </div>
        {id && (
          <div className="flex justify-center">
            <Link
              to={`/run/${id}/`}
              className="bg-black bg-opacity-70 rounded-xl p-1 whitespace-nowrap max-w-full overflow-hidden"
              // as={Link}
            >
              <h1 className="mx-2 text-lg md:text-2xl">
                {name} @ {track}
              </h1>
            </Link>
          </div>
        )}
        <div className="flex justify-end col-start-3">
          {status === "pending" ? (
            <LoadingCircle className="h-5 w-5 animate-spin" />
          ) : (
            <>
              {flags?.includes("login") && user?.id ? (
                <Popover className="relative">
                  <Popover.Button className="text-left border flex items-center rounded-lg border-gray-500 py-1.5 px-3">
                    <span>{user.shortName}</span>
                    <FontAwesomeIcon icon={faBars} className="-mr-1 ml-2" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-30 origin-top-left right-0 rounded border border-gray-500 bg-gray-900 whitespace-nowrap text-right">
                    <button
                      type="button"
                      onClick={() => {
                        setModalOpen(true);
                        setModalComponent("profile");
                      }}
                      className="block px-4 py-2 w-full text-sm text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-right"
                    >
                      User Profile
                    </button>
                    {user.isStaff && (
                      <button
                        type="button"
                        className="block px-4 py-2 w-full text-sm text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-right"
                        onClick={() => refetch()}
                      >
                        Fetch Live Feed
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        logout();
                      }}
                      className="block px-4 w-full py-2 text-sm text-gray-200 hover:bg-gray-100 hover:text-gray-900 text-right"
                    >
                      Log Out
                    </button>
                  </Popover.Panel>
                </Popover>
              ) : (
                <>
                  {flags?.includes("login") ? (
                    <button
                      onClick={() => {
                        setModalOpen(true);
                        setModalComponent("login");
                      }}
                      className="font-bold leading-none border rounded border-gray-500 px-2 py-1 bg-black bg-opacity-70 mr-1"
                    >
                      Login
                    </button>
                  ) : null}
                  {flags?.includes("register") ? (
                    <button
                      onClick={() => {
                        setModalOpen(true);
                        setModalComponent("register");
                      }}
                      className="font-bold leading-none border rounded border-gray-500 px-2 py-1 bg-black bg-opacity-70"
                    >
                      Register
                    </button>
                  ) : null}
                </>
              )}
              <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="fixed inset-0 backdrop-blur-sm" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                  <Dialog.Panel>
                    {modalComponent === "login" ? (
                      <Login
                        setModalComponent={setModalComponent}
                        setModalOpen={setModalOpen}
                      />
                    ) : modalComponent === "register" ? (
                      <SignUp
                        setModalComponent={setModalComponent}
                        setModalOpen={setModalOpen}
                      />
                    ) : modalComponent === "resetPassword" ? (
                      <ResetPassword
                        setModalComponent={setModalComponent}
                        setModalOpen={setModalOpen}
                      />
                    ) : modalComponent === "profile" ? (
                      <UserProfile
                        setModalComponent={setModalComponent}
                        setModalOpen={setModalOpen}
                      />
                    ) : null}
                  </Dialog.Panel>
                </div>
              </Dialog>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
