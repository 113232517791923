/** @format */

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useActiveFeatureFlags } from "posthog-js/react";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { signUp, useUser } from "../api/userAPI";
import LoadingCircle from "../utils/LoadingCircle";
const SignUp = ({
  setModalComponent,
  setModalOpen,
}: {
  setModalComponent: Function;
  setModalOpen: Function;
}) => {
  const { data: user } = useUser({ enabled: false });
  const location = useLocation();
  const flags = useActiveFeatureFlags();
  const [success, setSuccess] = React.useState(false);
  return !flags?.includes("register") || user?.id ? (
    <Navigate to={location.state?.pathname || "/"} replace state={location} />
  ) : (
    <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-[800px] max-w-screen max-w-full">
      <h1 className="text-xl mb-2">Register</h1>
      {success ? (
        <div className="mt-2 bg-gray-800 p-4 rounded">
          <h1 className="text-xl mb-2">Registration Successful!</h1>
          <p className="text-gray-400 mb-2">
            Please check your email for a verification link.
          </p>

          <button
            onClick={() => {
              setModalOpen(true);
              setModalComponent("login");
            }}
            className="w-full flex justify-center my-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            Go to Login
          </button>
          <button
            onClick={() => {
              setModalOpen(false);
              setModalComponent(null);
            }}
            className="w-full flex justify-center my-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            Close
          </button>
        </div>
      ) : (
        <Formik
          initialValues={{
            email1: "",
            email2: "",
            password1: "",
            password2: "",
            fullName: "",
            shortName: "",
          }}
          validate={values => {
            const errors = {} as {
              email1?: string;
              email2?: string;
              password1?: string;
              password2?: string;
              fullName?: string;
              shortName?: string;
            };
            if (!values.fullName) {
              errors.fullName = "Required";
            }
            if (!values.shortName) {
              errors.shortName = "Required";
            }
            if (!values.email1) {
              errors.email1 = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email1)
            ) {
              errors.email1 = "Invalid email address";
            }
            if (!values.email2) {
              errors.email1 = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email2)
            ) {
              errors.email2 = "Invalid email address";
            }
            if (values.email1 !== values.email2) {
              errors.email2 = "Emails must match";
            }
            if (!values.password1) {
              errors.password1 = "Required";
            } else if (values.password1.length < 8) {
              errors.password1 = "Password must be at least 8 characters";
            }
            if (!values.password2) {
              errors.password2 = "Required";
            } else if (values.password2.length < 8) {
              errors.password2 = "Password must be at least 8 characters";
            }
            if (values.password1 !== values.password2) {
              errors.password2 = "Passwords must match";
            }
            return errors;
          }}
          onSubmit={(
            { email1, password1, password2, fullName, shortName },
            { setSubmitting, setFieldError }
          ) => {
            setSubmitting(true);
            setTimeout(() => {
              signUp(email1, password1, password2, fullName, shortName)
                .then(data => {
                  setSubmitting(false);
                  setSuccess(true);
                })
                .catch(error => {
                  //Set error messages
                  if (error.response) {
                    if (error.response.status === 400) {
                      if (error.response.data.email) {
                        setFieldError("email1", error.response.data.email.join("\n"));
                      }
                      if (error.response.data.password1) {
                        setFieldError(
                          "password1",
                          error.response.data.password1.join("\n")
                        );
                      }
                      if (error.response.data.password2) {
                        setFieldError(
                          "password2",
                          error.response.data.password2.join("\n")
                        );
                      } else if (error.response.data.nonFieldErrors) {
                        setFieldError(
                          "password2",
                          error.response.data.nonFieldErrors.join("\n")
                        );
                      }
                    } else {
                      setFieldError("password2", "Something went wrong");
                    }
                  } else {
                    setFieldError("password2", "Something went wrong");
                  }
                  console.error(error);
                  setSubmitting(false);
                });
            }, 400);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form className="space-y-6">
              <div className="grid grid-cols-2 space-x-2">
                <div className="grow-0">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="fullName"
                      id="fullName"
                      autoComplete="fullName"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-red-600 whitespace-pre-wrap"
                  />
                </div>
                <div className="grow-0">
                  <label
                    htmlFor="shortName"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Short Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="shortName"
                      id="shortName"
                      autoComplete="shortName"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="shortName"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 space-x-2 ">
                <div className="grow-0">
                  <label
                    htmlFor="email1"
                    className="block text-sm font-medium text-gray-200"
                  >
                    E-Mail
                  </label>
                  <div className="mt-1">
                    <Field
                      type="email"
                      name="email1"
                      id="email1"
                      autoComplete="email"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="email1"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="grow-0">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Confirm E-Mail
                  </label>
                  <div className="mt-1">
                    <Field
                      type="email"
                      name="email2"
                      id="email2"
                      autoComplete="email"
                      required
                      className=" bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="email2"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 space-x-2">
                <div className="grow-0">
                  <label
                    htmlFor="password1"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Field
                      type="password"
                      name="password1"
                      id="password1"
                      required
                      className="appearance-none block w-full px-3 py-2 border bg-black border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="password1"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="grow-0">
                  <label
                    htmlFor="password2"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-1">
                    <Field
                      type="password"
                      name="password2"
                      id="password2"
                      required
                      className="appearance-none block w-full px-3 py-2 border bg-black border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="password2"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <>
                      <LoadingCircle className="h-5 w-5 mr-2" />
                      Signing Up
                    </>
                  ) : (
                    "Sign Up"
                  )}
                </button>
              </div>
              <div className="text-sm text-center text-gray-400">
                <button
                  onClick={() => {
                    setModalOpen(true);
                    setModalComponent("resetPassword");
                  }}
                  className="font-medium text-cPurple-600 hover:text-cPurple-500"
                >
                  Forgot your password?
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default SignUp;
