/** @format */

import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css"; // Ensure you also include CSS for geosearch if available
import "leaflet/dist/leaflet.css";
import { useEffect, useMemo, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import mapPin from "../assets/map_pin.svg";

const GeoSearchConstructor: any = GeoSearchControl; // ToDo - Fix this

const customIcon = L.icon({
  iconUrl: mapPin,
  iconSize: [38, 95], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const MapWithSearch = ({
  setPosition,
}: {
  setPosition: (position: L.LatLng) => void;
}) => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl: any = new GeoSearchConstructor({
      provider: provider,
      style: "bar",
      autoComplete: true,
      autoCompleteDelay: 250,
      showMarker: false,
      showPopup: false,
      marker: {
        draggable: true,
      },
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: "Enter address",
      keepResult: true,
    });

    map.addControl(searchControl);
    map.on("geosearch/showlocation", (result: any) => {
      // ToDo - Fix this
      setPosition(L.latLng(result.location.y, result.location.x));
    });
    map.on("click", e => {
      setPosition(e.latlng);
    });

    // Add this code to disable browser autocomplete
    const intervalId = setInterval(() => {
      const input = document.querySelector(".leaflet-control-geosearch input");
      if (input) {
        input.setAttribute("autocomplete", "off");
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation");
      map.off("click");
    };
  }, [map]);

  return null;
};

interface MapComponentProps {
  initialPosition?: { lat: number | undefined; lng: number | undefined };
  updatePosition: (position: { lat: number; lng: number }) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({
  initialPosition,
  updatePosition,
}) => {
  const [position, setPosition] = useState<L.LatLng>(
    initialPosition?.lat && initialPosition?.lng
      ? L.latLng(initialPosition.lat, initialPosition.lng)
      : L.latLng(35.227085, -80.843124)
  );
  const positionSame = useMemo(
    () =>
      !!(
        initialPosition &&
        initialPosition.lat &&
        initialPosition.lng &&
        position.equals(L.latLng(initialPosition.lat, initialPosition.lng))
      ),
    [initialPosition, position]
  );
  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: "60vh", width: "100%" }}
      className="text-black"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapWithSearch setPosition={setPosition} />
      {position && (
        <Marker
          position={position}
          icon={customIcon}
          draggable={true}
          eventHandlers={{
            dragend: e => {
              setPosition(
                L.latLng(
                  parseFloat(e.target._latlng.lat.toFixed(4)),
                  parseFloat(e.target._latlng.lng.toFixed(4))
                )
              );
            },
          }}
        >
          <Popup>
            <br />
            Latitude: {position?.lat?.toFixed(4)}
            <br />
            Longitude: {position?.lng?.toFixed(4)}
            <br />
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-500 disabled:cursor-not-allowed"
              onClick={() => updatePosition(position)}
              disabled={positionSame}
            >
              {positionSame ? "Saved" : "Save"}
            </button>
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
