/** @format */

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useUser } from "./userAPI";
import { useServerVersion } from "./runsApi";
import { useState } from "react";

// hax to get the version from vite
declare const process: {
  env: {
    VERSION: string;
  };
};

const cleanVersion = (version: string): number[] => {
  if (version === undefined) return [0, 0, 0, 0];
  let parts = version?.split(".")?.map((part: string) => parseInt(part)) || [
    0, 0, 0, 0,
  ];
  while (parts.length < 4) parts.push(0);
  return parts.map(r => r);
};

export const useWebVersion = () =>
  useQuery({
    queryKey: ["webVersion"],
    queryFn: () =>
      import.meta.env.MODE === "development"
        ? "0.0.0"
        : axios.get("/version").then(res => res.data.replace("\n", "")),
    refetchInterval: 1000 * 60,
    initialData: { version: "0.0.0" },
  });

export const useVersion = () => {
  const [ignored, setIgnored] = useState<string[]>([]); // [version, version, ...
  const { data: { isStaff = false } = {} } = useUser();
  // eslint-disable-next-line no-undef

  const localVersion = process.env.VERSION;
  const {
    data: { version: webVersion = "0.0.0" },
  }: { data: { version: string } } = useWebVersion();
  const { data: serverVersion } = useServerVersion();
  const localParts = cleanVersion(localVersion || "");
  const webUpdate = cleanVersion(webVersion)
    .map((r, i) => r > localParts[i])
    .reduce((a, b) => a || b, false);
  const serverUpdate = cleanVersion(serverVersion)
    .map((r, i) => r < localParts[i])
    .reduce((a, b) => a || b, false);
  if (isStaff)
    console.debug({
      webVersion,
      serverVersion,
      localVersion,
      webUpdate,
      serverUpdate,
      ignored: ignored.includes(webVersion),
    });
  return {
    localVersion,
    webVersion,
    serverVersion,
    webUpdate: !ignored.includes(webVersion) && webUpdate,
    serverUpdate: !ignored.includes(serverVersion) && isStaff && serverUpdate,
    setIgnored: () => setIgnored([...ignored, webVersion]),
    ignored: ignored.includes(webVersion),
  };
};
