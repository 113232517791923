/** @format */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsLeftRight, faClone } from "@fortawesome/sharp-regular-svg-icons";
import {
  faChevronDown,
  faEyeSlash,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/sharp-solid-svg-icons";
import { Listbox, Popover, Tab } from "@headlessui/react";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import {
  useRun,
  useStaffVehicleConfigs,
  useUpdateColor,
  useUpdateRun,
} from "../../api/runsApi";
import { useUser } from "../../api/userAPI";
import compareColors from "../../utils/compareColors";
import { TRACK_TYPES } from "../../utils/constants";
import Loading from "../../utils/Loading";
import MapComponent from "../../utils/Map";
import useLiveParams from "../../utils/useLiveParams";
import WrappedColorsTable from "./ColorsTable";
import ReactDOM from "react-dom";

interface PreviousColorsProps {
  sameSponsor: boolean;
  previousColors: any;
  curSponsor: string;
  updateColor: (data: any) => void;
  id: string;
}

function cleanString(str: string) {
  return str
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .trim();
}

const PreviousColors: React.FC<PreviousColorsProps> = ({
  sameSponsor,
  previousColors,
  curSponsor,
  updateColor,
  id,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto-start",
  });
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          {/* ToDo Fix Button Size to be consistent */}
          <Popover.Button
            ref={setReferenceElement}
            className={`py-1 rounded px-1 w-14 ${
              sameSponsor ? "bg-purple-700" : "bg-gray-800"
            }`}
          >
            <span className="font-bold ml-2 text-right">
              {previousColors?.length.toFixed(0).padStart(2, "\u00A0")}
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`text-right ml-1 ${open ? "rotate-180 transform" : ""}`}
            />
          </Popover.Button>
          {ReactDOM.createPortal(
            <Popover.Panel
              className="z-10 bg-gray-900 border-gray-500 border rounded flex flex-col gap-2 p-2 max-h-[75vh] overflow-auto"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {previousColors
                ?.sort(
                  (
                    a: any,
                    b: any // ToDo Fix any
                  ) =>
                    a.sponsor === curSponsor ? -1 : b.sponsor === curSponsor ? 1 : 0
                )
                ?.map(
                  (
                    {
                      primaryColor,
                      secondaryColor,
                      race,
                      sponsor,
                    }: {
                      primaryColor: string;
                      secondaryColor: string;
                      race: string;
                      sponsor: string;
                    },
                    index: number
                  ) => (
                    <div
                      className={`flex items-center align-center gap-2 justify-end py-1 px-2 rounded ${
                        sponsor === curSponsor ? "bg-purple-700" : "bg-gray-800"
                      }`}
                      key={index}
                    >
                      <div className="whitespace-nowrap">
                        {sponsor} @ {race}
                      </div>
                      {primaryColor ? (
                        <div
                          className="h-8 w-8 rounded"
                          style={{
                            backgroundColor: primaryColor,
                          }}
                        />
                      ) : (
                        <div className="h-8 w-8 p-1 rounded border-gray-500 border bg-black">
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      )}
                      {secondaryColor ? (
                        <div
                          className="h-8 w-8 rounded"
                          style={{
                            backgroundColor: secondaryColor,
                          }}
                        />
                      ) : (
                        <div className="h-8 w-8 p-1 rounded border-gray-500 border bg-black">
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      )}
                      <FontAwesomeIcon
                        icon={faClone}
                        className="cursor-pointer"
                        onClick={() => {
                          updateColor({
                            id,
                            vehiclePrimaryColor: primaryColor,
                            vehicleSecondaryColor: secondaryColor,
                          });
                        }}
                      />
                    </div>
                  )
                )}
            </Popover.Panel>,
            document.body
          )}
        </>
      )}
    </Popover>
  );
};

const Colors = () => {
  const { runID } = useLiveParams();
  const {
    data = [],
    status: configStatus,
    error: configError,
  } = useStaffVehicleConfigs(runID);
  const { data: runData, status: runStatus, error: runError } = useRun(runID);
  const { data: { isStaff } = {} } = useUser();
  const { mutate: uc } = useUpdateColor(runID);
  const { mutate: updateRun } = useUpdateRun(runID);
  // eslint-disable-next-line
  const updateColor = useCallback(
    debounce(async data => {
      await uc(data);
    }, 500),
    [uc]
  );
  const updatePosition = ({ lat, lng }: { lat: number; lng: number }) =>
    updateRun({ lat, lng });
  const columns = [
    {
      Header: "#",
      accessor: "number",
      className: "text-right text-xl word-break-all w-fit max-w-fit px-1",
      // Cell: ({
      //   value,
      //   row: {
      //     original: { id },
      //   },
      // }) => (
      //   <div
      //     className="border rounded"
      //     contentEditable
      //     suppressContentEditableWarning
      //     onBlur={e => {
      //       updateColor({
      //         id,
      //         number: e.target.innerText,
      //       });
      //     }}
      //   >
      //     {value}
      //   </div>
      // ),
    },
    {
      Header: "F.Name",
      accessor: "firstName",
      className: "text-left text-xl word-break-all w-fit max-w-fit px-1",
      // Cell: ({
      //   value,
      //   row: {
      //     original: { id },
      //   },
      // }) => (
      //   <div
      //     className="border rounded"
      //     contentEditable
      //     suppressContentEditableWarning
      //     onBlur={e => {
      //       updateColor({
      //         id,
      //         firstName: e.target.innerText,
      //       });
      //     }}
      //   >
      //     {value}
      //   </div>
      // ),
    },
    {
      Header: "L.Name",
      accessor: "lastName",
      className: "text-left text-xl word-break-all w-fit max-w-fit px-1",
      // Cell: ({
      //   value,
      //   row: {
      //     original: { id },
      //   },
      // }) => (
      //   <div
      //     className="border rounded"
      //     contentEditable
      //     suppressContentEditableWarning
      //     onBlur={e => {
      //       updateColor({
      //         id,
      //         lastName: e.target.innerText,
      //       });
      //     }}
      //   >
      //     {value}
      //   </div>
      // ),
    },
    {
      Header: "Display Name",
      accessor: "displayName",
      className: "text-left text-xl word-break-all w-fit max-w-fit px-1",
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }: {
        value: string;
        row: {
          original: { id: string };
        };
      }) => (
        <div
          className="border rounded"
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            updateColor({
              id,
              displayName: e.target.innerText,
            });
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: "Sponsor",
      accessor: "sponsor",
      className: "text-left text-xl",
    },
    {
      Header: "Examples",
      id: "examples",
      Cell: ({
        row: {
          original: { realPrimaryColor, realSecondaryColor, number, displayName },
        },
      }: {
        row: {
          original: {
            realPrimaryColor: string;
            realSecondaryColor: string;
            number: string;
            displayName: string;
          };
        };
      }) => (
        <div className="flex justify-center items-center gap-2">
          <div
            className="w-4 h-4 leading-4 text-[.65rem] rounded border overflow-hidden font-sans text-center font-bold tracking-tighter align-middle"
            style={{
              color: realSecondaryColor,
              backgroundColor: realPrimaryColor,
              borderColor: realSecondaryColor,
            }}
          >
            {number}
          </div>
          <div
            className="whitespace-nowrap px-1 rounded leading-none py-1 border"
            style={{
              backgroundColor: realPrimaryColor,
              color: realSecondaryColor,
              borderColor: realSecondaryColor,
            }}
          >
            <b>
              <i>{number}</i>
            </b>{" "}
            {displayName}
          </div>
          <div
            className="rounded px-1 font-bold whitespace-nowrap"
            style={{
              backgroundColor: realPrimaryColor,
            }}
          >
            <span className="text-red-500">Red </span>
            <span className="text-green-500">Green </span>
            <span className="text-yellow-500">Gold </span>
          </div>
        </div>
      ),
    },
    {
      Header: "Driver Colors",
      id: "driver_colors",
      Cell: ({
        row: {
          original: { id, driverPrimaryColor, driverSecondaryColor },
        },
      }: {
        row: {
          original: {
            id: string;
            driverPrimaryColor: string;
            driverSecondaryColor: string;
          };
        };
      }) => (
        <ColorSelector
          id={id}
          runID={runID}
          primaryColor={driverPrimaryColor}
          secondaryColor={driverSecondaryColor}
          pKey="driverPrimaryColor"
          sKey="driverSecondaryColor"
        />
      ),
    },
    {
      Header: "Vehicle Colors",
      id: "vehicle_colors",
      Cell: ({
        row: {
          original: { id, vehiclePrimaryColor, vehicleSecondaryColor },
        },
      }: {
        row: {
          original: {
            id: string;
            vehiclePrimaryColor: string;
            vehicleSecondaryColor: string;
          };
        };
      }) => (
        <ColorSelector
          id={id}
          runID={runID}
          primaryColor={vehiclePrimaryColor}
          secondaryColor={vehicleSecondaryColor}
          pKey="vehiclePrimaryColor"
          sKey="vehicleSecondaryColor"
        >
          <FontAwesomeIcon
            icon={faClone}
            className="cursor-pointer"
            onClick={() => {
              updateColor({
                id,
                driverPrimaryColor: vehiclePrimaryColor,
                driverSecondaryColor: vehicleSecondaryColor,
              });
            }}
          />
        </ColorSelector>
      ),
    },
    {
      Header: "Previous Colors",
      accessor: ({
        previousColors,
        driverPrimaryColor,
        driverSecondaryColor,
        vehiclePrimaryColor,
        vehicleSecondaryColor,
        id,
        sponsor,
      }: {
        previousColors: any; // ToDo Fix any
        driverPrimaryColor: string;
        driverSecondaryColor: string;
        vehiclePrimaryColor: string;
        vehicleSecondaryColor: string;
        id: string;
        sponsor: string;
      }) => {
        // ToDo Can I move this to the backend?
        previousColors //ToDo Break Out Type Def
          .filter(
            (color: { id: string; primaryColor: string; secondaryColor: string }) =>
              color.primaryColor !== vehiclePrimaryColor &&
              color.secondaryColor !== vehicleSecondaryColor
          )
          .filter(
            (color: { id: string; primaryColor: string; secondaryColor: string }) =>
              color.primaryColor !== driverPrimaryColor &&
              color.secondaryColor !== driverSecondaryColor
          )
          .filter(
            (color: { id: string; primaryColor: string; secondaryColor: string }) =>
              color.id !== id
          );
        return {
          previousColors,
          id,
          curSponsor: sponsor,
          sameSponsor: previousColors.find(
            ({ sponsor: cSponsor }: { sponsor: string }) =>
              cleanString(sponsor) === cleanString(cSponsor)
          ),
        };
      },
      Cell: ({
        value: { previousColors, id, curSponsor, sameSponsor },
      }: {
        value: {
          previousColors: any;
          id: string;
          curSponsor: string;
          sameSponsor: boolean;
        };
      }) =>
        previousColors?.length > 0 ? (
          <PreviousColors
            id={id}
            previousColors={previousColors}
            curSponsor={curSponsor}
            sameSponsor={sameSponsor}
            updateColor={updateColor}
          />
        ) : null,
    },
  ];
  return isStaff ? (
    <Loading statuses={[configStatus, runStatus]} errors={[configError, runError]}>
      <div className="overflow-hidden overflow-x-auto w-full" id="tabPortal">
        <Tab.Group>
          <Tab.List className="flex gap-2 my-2 text-lg font-bold">
            <Tab className="bg-gray-800 text-white rounded px-2 py-1">Colors</Tab>
            <Tab className="bg-gray-800 text-white rounded px-2 py-1">
              Configuration
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {data?.length > 0 && <WrappedColorsTable data={data} columns={columns} />}
            </Tab.Panel>
            <Tab.Panel className="h-full">
              <div className="grid grid-cols-2 gap-2 w-full h-full">
                <div>
                  <div className="text-2xl text-center">Map</div>
                  <div className="text-black">
                    <MapComponent
                      initialPosition={{
                        lat: runData?.lat,
                        lng: runData?.lng,
                      }}
                      updatePosition={updatePosition}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-2xl text-center">Track Info</div>
                  <div className="text-center">
                    {runData?.track} | {runData?.trackTz}
                  </div>
                  <div>
                    <label>Track Type:</label>
                    <Listbox
                      value={runData?.trackType}
                      onChange={value => {
                        updateRun({ trackType: value });
                      }}
                    >
                      <Listbox.Button className="bg-gray-800 text-white rounded px-2 py-1">
                        {TRACK_TYPES[runData?.trackType as string]}
                      </Listbox.Button>
                      <Listbox.Options className="bg-black text-white p-1 gap-1.5 z-50 mt-1 max-h-60 flex-wrap overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.entries(TRACK_TYPES).map(([key, value]) => (
                          <Listbox.Option
                            className={({ active }) =>
                              `text-sm whitespace-nowrap border border-collapse rounded p-1 shrink cursor-pointer bg-gray-800 ${
                                active ? "font-bold" : ""
                              }`
                            }
                            key={key}
                            value={key}
                          >
                            {value}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Listbox>
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Loading>
  ) : null;
};

export default Colors;

interface ColorSelectorProps {
  id: string;
  runID: string;
  primaryColor: string;
  secondaryColor: string;
  pKey: string;
  sKey: string;
  readOnly?: boolean;
  children?: React.ReactNode;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  id,
  runID,
  primaryColor,
  secondaryColor,
  pKey,
  sKey,
  readOnly = false,
  children = null,
}) => {
  const { mutate: uc } = useUpdateColor(runID);
  const unreadable = useMemo(() => {
    return compareColors(primaryColor || "#000000", secondaryColor || "#ffffff");
  }, [primaryColor, secondaryColor]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateColor = useCallback(
    debounce(data => uc(data), 500),
    [uc]
  );
  return (
    <div className="flex justify-center items-center gap-2">
      <input
        title="Primary Color"
        type="color"
        className={`w-10 h-10 rounded ${primaryColor === null ? "bg-red-600" : ""}`}
        value={primaryColor || "#000000"}
        onChange={e => {
          updateColor({ id, [pKey]: e.target.value });
        }}
        readOnly={readOnly}
      />
      {!readOnly && (
        <div className="w-6 flex-col justify-center items-center flex">
          {unreadable ? (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-red-500 cursor-pointer"
            />
          ) : null}
          <FontAwesomeIcon
            icon={faArrowsLeftRight}
            className="text-white cursor-pointer"
            onClick={() => {
              updateColor({
                id,
                [pKey]: secondaryColor || "#ffffff",
                [sKey]: primaryColor || "#000000",
              });
            }}
          />
        </div>
      )}
      <input
        title="Secondary Color"
        type="color"
        className={`w-10 h-10 rounded ${secondaryColor === null ? "bg-red-600" : ""}`}
        value={secondaryColor || "#ffffff"}
        onChange={e => {
          updateColor({ id, [sKey]: e.target.value });
        }}
        readOnly={readOnly}
      />
      {children}
      <FontAwesomeIcon
        icon={faTrash}
        className="cursor-pointer"
        onClick={() => {
          updateColor({ id, [pKey]: null, [sKey]: null });
        }}
      />
    </div>
  );
};
