/** @format */

import { faPlus, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
  faCog,
  faTimes,
} from "@fortawesome/sharp-regular-svg-icons";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";
import React, { ForwardedRef, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import CustomErrorBoundary from "../../../utils/ErrorBoundary";
import { capFirst, searchParamsToObj } from "../../../utils/utility";

const FallbackErrorBoundary = () => <CustomErrorBoundary />;

interface GridComponentProps {
  style?: React.CSSProperties;
  name: string;
  className?: string;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLDivElement>) => void;
  onHeightChange?: (height: number) => void;
  children?: React.ReactNode;
  components: (Component & GridWidget)[];
  availableComponents: string[];
  showHeader?: boolean;
  comps: { name: string; config: {}; key: string }[];
  isEditing: boolean;
  isRearranging: boolean;
  onCompDelete: () => void;
  onCompsChange: (comps: { name: string; config: {}; key: string }[]) => void;
  onNameChange: (name: string) => void;
  id: string;
}

const GridComponent = forwardRef(
  (
    {
      style,
      name,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onHeightChange,
      children,
      components,
      availableComponents,
      showHeader,
      comps,
      isEditing,
      isRearranging,
      onCompDelete,
      onCompsChange,
      onNameChange,
      id,
      ...props
    }: GridComponentProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParam = name.trim().toLowerCase().replace(/\s+/g, "-");
    const defaultTab = components[0]?.name;
    const tab = searchParams.get(searchParam) || defaultTab;
    const detailVehicleID = searchParams.get("detailVehicleID");
    const setTab = (tab: string) =>
      setSearchParams(
        {
          ...searchParamsToObj(searchParams),
          [searchParam]: tab,
        },
        { replace: true }
      );
    if (components.length === 0 && !isEditing) {
      return null;
    }
    return (
      <div
        style={style}
        className={`${className} shadow-xl rounded-xl relative overflow-hidden bg-black border-2 border-[#000040] ${
          isRearranging
            ? "border-2 border-dashed border-gray-500 cursor-events-none"
            : ""
        }`}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        {...props}
      >
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 ${
            isEditing ? "overflow-y-hidden" : "overflow-y-auto"
          } overflow-x-auto flex flex-col`}
        >
          {components.length > 1 || showHeader ? (
            <div className="flex divide-x divide-gray-500 bg-black shrink border-b border-gray-500">
              {components.map(({ name, key, allowNewTab }) => (
                <button
                  type="button"
                  title={name}
                  key={name}
                  onClick={() => {
                    setTab(name);
                  }}
                  className={`text-center truncate px-1 cursor-pointer text-sm font-semibold grow ${
                    tab === name ? "bg-gray-800" : ""
                  }`}
                  data-ph-capture-attribute-name={name}
                >
                  {name}
                  {allowNewTab && (
                    <a
                      title="Open in New Tab"
                      href={`widget/${key}/?detailVehicleID=${detailVehicleID}`}
                      rel="noopener noreferrer"
                      target={`${id}_${key}`}
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `widget/${key}/?detailVehicleID=${detailVehicleID}`,
                          `${id}_${key}`,
                          "toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no"
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        className="text-white ml-1"
                      />
                    </a>
                  )}
                </button>
              ))}
              {isEditing && (
                <SettingsPopover
                  onNameChange={onNameChange}
                  availableComponents={availableComponents}
                  name={name}
                  onCompDelete={onCompDelete}
                  onCompsChange={onCompsChange}
                  comps={comps}
                  buttonClassName="text-center truncate px-1 cursor-pointer text-sm font-semibold grow pr-3"
                />
              )}
            </div>
          ) : isEditing ? (
            <SettingsPopover
              onNameChange={onNameChange}
              availableComponents={availableComponents}
              name={name}
              onCompDelete={onCompDelete}
              onCompsChange={onCompsChange}
              comps={comps}
              buttonClassName="text-center truncate px-1 cursor-pointer text-sm font-semibold fixed top-0 right-0 z-10 pl-1 pr-3"
            />
          ) : (
            components[0]?.allowNewTab && (
              <a
                href={`widget/${components[0]?.key}?detailVehicleID=${detailVehicleID}`}
                rel="noopener noreferrer"
                target={`${id}_${components[0]?.key}`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `widget/${components[0]?.key}?detailVehicleID=${detailVehicleID}`,
                    `${id}_${components[0]?.key}`,
                    "width=800,height=600,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no"
                  );
                }}
                className="text-center truncate px-1 cursor-pointer text-sm font-semibold fixed top-0 right-0 z-10 pl-1 pr-3"
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="text-white ml-1"
                />
              </a>
            )
          )}
          <div className={`flex-grow overflow-auto`}>
            {components.map(
              ({ name, Component, config }) =>
                (tab === name || components.length === 1) && (
                  <ErrorBoundary key={name} fallback={FallbackErrorBoundary}>
                    <Component
                      config={config}
                      isEditing={isEditing}
                      updateConfig={(config: {
                        data?: string;
                        saveId?: string;
                        detailOffset?: number;
                      }) => {
                        onCompsChange(
                          components.map(comp =>
                            comp.name === name ? { ...comp, config } : comp
                          )
                        );
                      }}
                    />
                  </ErrorBoundary>
                )
            )}
          </div>
        </div>
        {children}
      </div>
    );
  }
);

GridComponent.displayName = "GridComponent";

export default GridComponent;

interface SettingsPopoverProps {
  onNameChange: (name: string) => void;
  availableComponents: string[];
  name: string;
  onCompDelete: () => void;
  onCompsChange: (comps: { name: string; config: {}; key: string }[]) => void;
  comps: { name: string; config: {}; key: string }[];
  buttonClassName: string;
}

const SettingsPopover: React.FC<SettingsPopoverProps> = ({
  onNameChange,
  availableComponents,
  name,
  onCompDelete,
  onCompsChange,
  comps,
  buttonClassName,
}) => {
  const moveUp = (index: number) => {
    const updatedItems = Array.from(comps);
    const [removed] = updatedItems.splice(index, 1);
    updatedItems.splice(index - 1, 0, removed);
    onCompsChange(updatedItems);
  };
  const moveDown = (index: number) => {
    const updatedItems = Array.from(comps);
    const [removed] = updatedItems.splice(index, 1);
    updatedItems.splice(index + 1, 0, removed);
    onCompsChange(updatedItems);
  };
  return (
    <Popover>
      <Popover.Button className={buttonClassName}>
        <FontAwesomeIcon icon={faCog} className="text-white" />
      </Popover.Button>
      <Popover.Panel className="bg-gray-800 shrink fixed top-5 right-1 z-20 p-2 rounded max-h-full overflow-auto">
        <h1 className="text-center truncate px-1 cursor-pointer font-semibold mb-2">
          <span
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
              onNameChange(e.target.innerText);
            }}
          >
            {name}
          </span>
          <button type="button" title="Delete Component" onClick={onCompDelete}>
            <FontAwesomeIcon
              icon={faTrash}
              className="text-red-500 hover:text-red-600 ml-1 cursor-pointer"
            />
          </button>
        </h1>
        {comps.map((comp, index) => (
          <div key={index} className="rounded px-1 my-1 flex align-middle bg-gray-500 ">
            <div className="flex flex-col mr-1">
              <button
                type="button"
                title="Move Up"
                className="text-center truncate cursor-pointer text-xs font-semibold -mb-0.5 disabled:opacity-50"
                onClick={() => moveUp(index)}
                disabled={index === 0}
              >
                <FontAwesomeIcon icon={faChevronUp} className="text-white text-xs" />
              </button>
              <button
                type="button"
                title="Move Down"
                className="text-center truncate cursor-pointer font-semibold text-xs -mt-0.5 disabled:opacity-50"
                onClick={() => moveDown(index)}
                disabled={index === comps.length - 1}
              >
                <FontAwesomeIcon icon={faChevronDown} className="text-white text-xs" />
              </button>
            </div>
            <div
              className="truncate whitespace-nowrap grow"
              contentEditable
              suppressContentEditableWarning
              onBlur={e => {
                onCompsChange(
                  comps.map(c => {
                    if (c.name === comp.name) {
                      return { ...c, name: e.target.innerText };
                    }
                    return c;
                  })
                );
              }}
            >
              {comp.name}
            </div>
            <button
              type="button"
              title="Remove Component"
              className="text-center px-1 cursor-pointer text-sm font-semibold"
              onClick={() => {
                onCompsChange(comps.filter(c => c !== comp));
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="text-white" />
            </button>
          </div>
        ))}
        <Disclosure>
          <Disclosure.Button className="text-center truncate px-1 cursor-pointer text-sm font-semibold bg-gray-800 rounded m-1">
            Add Component
            <FontAwesomeIcon icon={faPlus} className="text-white bg-gray-800 ml-1" />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="flex flex-col flex-wrap justify-center">
              {availableComponents.map(value => (
                <button
                  key={value}
                  onClick={() => {
                    onCompsChange([...comps, { name: value, config: {}, key: value }]);
                  }}
                  className="text-center truncate px-1 cursor-pointer text-sm font-semibold bg-gray-800 rounded m-1"
                >
                  {capFirst(value.replace(/([A-Z])/g, " $1").trim())}
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-white bg-gray-800 ml-1"
                  />
                </button>
              ))}
            </Disclosure.Panel>
          </Transition>
        </Disclosure>
      </Popover.Panel>
    </Popover>
  );
};
