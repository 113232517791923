/** @format */

export const rangeFromStdDev = (
  laps: VehicleLapList[],
  key: NumericKeys<VehicleLapList>
) => {
  const data = laps
    .map(lap => lap[key])
    .sort((a, b) => a - b)
    .slice(2, -2);
  const mean = data.reduce((a, b) => a + b, 0) / data.length;
  const stdDev = Math.sqrt(
    data.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / data.length
  );
  return {
    min: mean - stdDev,
    max: mean + stdDev,
    debug: {
      mean,
      stdDev,
    },
  };
};

export const rangeForLapTime = (laps: VehicleLapList[], bestTime: number) => {
  const { min, max } = rangeFromStdDev(laps, "lapTime");
  return {
    min: min,
    max: max,
  };
};

export const rangeFromPercentile = (
  laps: VehicleLapList[],
  key: NumericKeys<VehicleLapList>,
  percentile: number
) => {
  const data = laps.map(lap => lap[key]);
  const sorted = data.sort((a, b) => a - b);
  const index = Math.floor(sorted.length * percentile);
  return {
    min: sorted[index],
    max: sorted[sorted.length - index],
  };
};

interface BarCellProps {
  data: number;
  range: { min: number; max: number };
  bgColor: string;
  split?: boolean;
  children: React.ReactNode;
}

const BarCell: React.FC<BarCellProps> = ({
  data,
  range: { min, max },
  bgColor,
  split = false,
  children,
}) => {
  return (
    <div className="relative">
      <span className="relative z-20">{children}</span>
      <div
        style={{
          width: `${
            split
              ? (data > 0 ? (data / max) * 100 : (data / min) * 100) / 2
              : ((data - min) / (max - min)) * 100
          }%`,
          maxWidth: split ? "50%" : "100%",
        }}
        className={`${split ? (data > 0 ? "bg-red-600" : "bg-green-600") : bgColor} ${
          split
            ? data > 0
              ? "left-1/2 rounded-r"
              : "right-1/2 rounded-l"
            : "left-0 rounded-r"
        } absolute h-full top-0 z-10 brightness-50
        transition-all duration-500 ease-in-out
        `}
      />
    </div>
  );
};

export default BarCell;
