/** @format */

import React from "react";
import { useRacesList } from "../../api/racesApi";
import Loading from "../../utils/Loading";
import RaceDetail from "./RaceDetail";

interface ScheduleProps {
  series: string;
  year: string;
}

const Schedule: React.FC<ScheduleProps> = ({ series, year }) => {
  const {
    data: schedule,
    status: scheduleStatus,
    error: scheduleError,
  } = useRacesList(series.replace("series_", ""), year);
  return (
    <Loading statuses={[scheduleStatus]} errors={[scheduleError]} wrap={false}>
      <div className="container mx-auto">
        {schedule
          ?.filter(race => race.scheduledDate)
          ?.map(race => (
            <RaceDetail key={race.id} race={race} />
          ))}
      </div>
    </Loading>
  );
};
export default Schedule;
