/** @format */

import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import Colors from "./pages/Run/Colors";
import Run from "./pages/Run/Run";
import WidgetWrapper from "./pages/Run/WidgetWrapper";
import VerifyEmail from "./pages/VerifyEmail";

const RootRouter = () => {
  return (
    <Routes>
      <Route path="/reset-password/:uid/:token" Component={ResetPasswordConfirm} />
      <Route path="/verify-email/:key" Component={VerifyEmail} />
      <Route path="run">
        <Route path=":runID">
          <Route path="widget/:widget" Component={WidgetWrapper} />
          <Route path="color" Component={Colors} />
          <Route path="" Component={Run} />
        </Route>
      </Route>
      <Route index Component={Home} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RootRouter;
