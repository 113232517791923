/** @format */

// determine if two colors contrast enough to be readable
// https://www.w3.org/TR/WCAG20/#contrast-ratiodef
// https://www.w3.org/TR/WCAG20/#relativeluminancedef
// https://www.w3.org/TR/WCAG20/#contrast-ratiodef
// https://www.w3.org/TR/WCAG20/#contrast-threshold

// https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast

// https://www.w3.org/TR/WCAG20/#larger-scaledef
// https://www.w3.org/TR/WCAG20/#contrast-enhancedef
// https://www.w3.org/TR/WCAG20/#contrast-minimum

// https://www.w3.org/TR/WCAG20/#text-presentation

// https://www.w3.org/TR/WCAG20/#larger-scaledef
// Calculate the brightness of a hex color
function calculateBrightness(hexColor: string) {
  const red = parseInt(hexColor.substr(1, 2), 16);
  const green = parseInt(hexColor.substr(3, 2), 16);
  const blue = parseInt(hexColor.substr(5, 2), 16);
  return (red * 299 + green * 587 + blue * 114) / 1000;
}

// Check if the contrast ratio between two colors meets the web standards
function isReadable(color1: string, color2: string) {
  const brightness1 = calculateBrightness(color1);
  const brightness2 = calculateBrightness(color2);
  const contrastRatio =
    (Math.max(brightness1, brightness2) + 0.05) /
    (Math.min(brightness1, brightness2) + 0.05);
  return contrastRatio >= 4.5;
}

// Normalize hex colors to 6 digits (e.g., #abc to #aabbcc)
function normalizeHexColor(hexColor: string) {
  return hexColor.length === 4 ? hexColor.replace(/(.)/g, "$1$1") : hexColor;
}

function compareColors(color1: string, color2: string) {
  // Normalize and compare the colors
  const normalizedColor1 = normalizeHexColor(color1);
  const normalizedColor2 = normalizeHexColor(color2);

  return !isReadable(normalizedColor1, normalizedColor2);
}

// Adjust the color brightness to meet the web standards
function adjustColorBrightness(hexColor: string, targetBrightness: number) {
  const red = parseInt(hexColor.substr(1, 2), 16);
  const green = parseInt(hexColor.substr(3, 2), 16);
  const blue = parseInt(hexColor.substr(5, 2), 16);

  const currentBrightness = calculateBrightness(hexColor);
  const brightnessDifference = targetBrightness - currentBrightness;

  const newRed = clamp(red + brightnessDifference, 0, 255);
  const newGreen = clamp(green + brightnessDifference, 0, 255);
  const newBlue = clamp(blue + brightnessDifference, 0, 255);
  return `#${componentToHex(newRed)}${componentToHex(newGreen)}${componentToHex(
    newBlue
  )}`;
}

// Helper function to clamp a value between a minimum and maximum
function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

// Helper function to convert a decimal component to a two-digit hex component
function componentToHex(component: number) {
  const hex = component.toString(16).substr(0, 2);
  return hex.length === 1 ? `0${hex}` : hex;
}

export function adjustColors(color1: string, color2: string) {
  // Normalize and compare the colors
  const normalizedColor1 = normalizeHexColor(color1);
  const normalizedColor2 = normalizeHexColor(color2);
  if (isReadable(normalizedColor1, normalizedColor2)) {
    return [normalizedColor1, normalizedColor2];
  }
  const brightness1 = calculateBrightness(normalizedColor1);
  const brightness2 = calculateBrightness(normalizedColor2);
  const targetBrightness = (brightness1 + brightness2) / 2;
  const adjustedColor1 = adjustColorBrightness(normalizedColor1, targetBrightness);
  const adjustedColor2 = adjustColorBrightness(normalizedColor2, targetBrightness);
  return [adjustedColor1, adjustedColor2];
}

//

export default compareColors;

export function colorTrace(msg: string, color: string) {
  console.log("%c" + msg, "color:" + color + ";font-weight:bold;");
}
