/** @format */

import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../api/userAPI";
import { searchParamsToObj, seriesLookup } from "../../utils/utility";
import { defaultViews } from "../Run/grid/gridWrapperDefaults";
import componentMap from "../Run/widgets/WidgetsMap";
import Schedule from "./Schedule";
import Standings from "./Standings";

declare global {
  interface Window {
    wkData: {
      components: string[];
      views: { name: string; id: string }[];
    };
  }
}

const series_keys = Object.keys(seriesLookup)
  .filter(value => isNaN(parseInt(value)))
  .sort();

const components: string[] = Object.keys(componentMap)
  .filter((key: string) => componentMap[key].allowNewTab)
  .reduce((obj: string[], key: string) => {
    obj.push(key);
    return obj;
  }, []);

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const series = searchParams.get("series") || series_keys[0];
  const setSeries = (series: string) =>
    setSearchParams({
      ...searchParamsToObj(searchParams),
      series,
    });
  const year = searchParams.get("year") || new Date().getFullYear().toString();
  // const [series, setSeries] = useState(series_keys[0]);
  const tab = searchParams.get("tab") || "standings";
  const setTab = (tab: string) =>
    setSearchParams({
      ...searchParamsToObj(searchParams),
      tab,
    });
  // const [tab, setTab] = useState("standings");
  const { data: { runViews: userViews = [] } = { runViews: [] } } = useUser();
  const views = useMemo(
    () =>
      [
        ...defaultViews
          .filter(({ id }) => !userViews?.find(view => view.id === id))
          .map(view => ({ ...view, source: "default" })),
        ...userViews?.map(view => ({ ...view, source: "user" })),
      ]
        .sort((a, b) => a.order - b.order)
        .map(({ name, id }) => ({ name, id })),
    [userViews]
  );

  window.wkData = {
    components,
    views,
  };

  return (
    <div>
      <div className="bg-black text-gray-100">
        <div className="grid grid-cols-3 divide-x divide-gray-500 bg-black border-b">
          {series_keys?.map(series_key => (
            <div
              onClick={() => setSeries(series_key)}
              className={`text-center xs:text-xl md:text-4xl font-bold py-3 cursor-pointer  ${
                series === series_key ? "bg-gray-800" : ""
              }`}
              key={series_key}
            >
              {seriesLookup[series_key]}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2">
          <h1
            className={`${
              tab === "standings" ? "bg-gray-800" : "bg-black"
            } xs:text-lg md:text-2xl py-2 border-b font-bold text-center cursor-pointer`}
            onClick={() => setTab("standings")}
          >
            Standings
          </h1>
          <h1
            className={`${
              tab === "schedule" ? "bg-gray-800" : "bg-black"
            } xs:text-lg md:text-2xl py-2 border-b font-bold text-center cursor-pointer`}
            onClick={() => setTab("schedule")}
          >
            Schedule
          </h1>
        </div>
      </div>
      <div className="overflow-auto">
        {/* <div className="w-screen bottom-0 overflow-auto"> */}
        {tab === "standings" ? (
          <Standings series={series} year={year} />
        ) : tab === "schedule" ? (
          <Schedule series={series} year={year} />
        ) : (
          "null"
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Home;
