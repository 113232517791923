/** @format */

import React from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useActiveFeatureFlags } from "posthog-js/react";
import { Navigate } from "react-router-dom";
import { resetPassword, useUser } from "../api/userAPI";
const ResetPassword = ({
  setModalComponent,
  setModalOpen,
}: {
  setModalComponent: Function;
  setModalOpen: Function;
}) => {
  const [success, setSuccess] = React.useState(false);
  const flags = useActiveFeatureFlags();
  const { data: { email } = {} } = useUser();
  return !flags?.includes("login") ? (
    <Navigate to="/" replace />
  ) : (
    <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full">
      <h1 className="text-xl mb-2">Reset Password</h1>
      {success ? (
        <div className="text-green-700">
          <p>
            If an account with that email exists, you will receive an email with a link
            to reset your password.
          </p>
          <button
            type="button"
            onClick={() => {
              setModalComponent(null);
              setModalOpen(false);
            }}
            className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            Close
          </button>
        </div>
      ) : (
        <Formik
          initialValues={{ email: email || "" }}
          validate={values => {
            const errors = {} as { email?: string };
            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={({ email }, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            setTimeout(() => {
              resetPassword(email)
                .then(() => {
                  setSubmitting(false);
                  setSuccess(true);
                })
                .catch(error => {
                  // setErrors
                  if (error.response?.status === 400) {
                    if (error.response?.data?.email) {
                      setFieldError("email", error.response.data.email);
                    } else if (error.response?.data?.nonFieldErrors) {
                      setFieldError("email", error.response.data.nonFieldErrors);
                    } else {
                      setFieldError("email", "Something went wrong");
                    }
                  } else {
                    setFieldError("email", "Something went wrong");
                  }
                  console.error(error);
                  setSubmitting(false);
                });
            }, 400);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-200"
                >
                  E-Mail
                </label>
                <div className="mt-1">
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className=" bg-black  appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                  />
                </div>
                <ErrorMessage name="email" component="div" className="text-red-700" />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >
                  Reset Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ResetPassword;
