/** @format */

import { useMemo } from "react";
import Plot from "react-plotly.js";
import { useRun, useVehiclesList } from "../../../api/runsApi";
import Loading from "../../../utils/Loading";
import { getContrastColor } from "../../../utils/colorUtils";
import { CAUTION_FLAG, GREEN_FLAG } from "../../../utils/constants";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, ordinal } from "../../../utils/utility";
import { useFilterLaps } from "./FilterLaps";
import { Data } from "plotly.js";

const JerkLineChart = () => {
  const { runID } = useLiveParams();
  const {
    data: { stages, flagPeriods } = { stages: [], flagPeriods: [] },
    status: runStatus,
    error: runError,
  } = useRun(runID);
  const {
    data: vehiclesData = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID);
  const {
    response: { status: lapsListStatus, error: lapsListError },
    lapRange,
    showLegend,
    filteredLaps,
  } = useFilterLaps();
  const lapFlags = useMemo(() => {
    return flagPeriods
      .map(flagPeriod => Array(flagPeriod.end - flagPeriod.start).fill(flagPeriod.flag))
      .flat();
  }, [flagPeriods]);
  const data = useMemo(
    () => [
      ...stages.map(stage => ({
        key: stage.name,
        fill: "tozeroy",
        type: "scatter",
        showlegend: false,
        name: stage.name,
        x: lapFlags.keys(),
        y: lapFlags.map((_, index) =>
          index < stage.end && index > stage.start ? -1 : 0
        ),
        yaxis: "y",
        hoverinfo: "none",
        line: {
          color: "white",
        },
      })),
      {
        key: "green",
        fill: "tozeroy",
        type: "scatter",
        showlegend: false,
        name: "Green Flag",
        x: lapFlags.keys(),
        y: lapFlags.map(lap => (lap === GREEN_FLAG ? 1 : 0)),
        yaxis: "y",
        hoverinfo: "none",
        line: {
          color: "green",
        },
      },
      {
        key: "yellow",
        fill: "tozeroy",
        type: "scatter",
        showlegend: false,
        name: "Caution Flag",
        x: lapFlags.keys(),
        y: lapFlags.map(lap => (lap === CAUTION_FLAG ? 1 : 0)),
        yaxis: "y",
        hoverinfo: "none",
        line: {
          color: "yellow",
        },
      },
      ...vehiclesData.map(vehicle => ({
        key: vehicle.id,
        type: "scatter",
        mode: "lines",
        yaxis: "y2",
        shape: "spline",
        showlegend: showLegend,
        line: {
          color: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          width: 2,
          opacity: vehicle.status === 1 ? 1 : 0.5,
        },
        name: `${vehicle.number} ${vehicle.displayName}`,
        x: filteredLaps
          .filter(lap => lap.vehicleId === vehicle.id)
          .filter(lap => lap.status === 1)
          .filter(lap => !lap.lapDown)
          .map(lap => lap.lapNumber),
        y: filteredLaps
          .filter(lap => lap.vehicleId === vehicle.id)
          .filter(lap => lap.status === 1)
          .filter(lap => !lap.lapDown)
          .map(lap => lap.jerk),
        hovertemplate: filteredLaps
          .filter(lap => lap.vehicleId === vehicle.id)
          .filter(lap => lap.status === 1)
          .filter(lap => !lap.lapDown)
          .map(lap =>
            [
              `<b>#${vehicle.number}</b> ${vehicle.displayName} <i>${ordinal(
                lap.runningPosition
              )}</i>`,
              `<b>Lap:</b> ${lap.lapNumber} <b>Time:</b> ${lap.lapTime}s`,
              lap.lapDown
                ? `<b>Laps Down:</b> ${lap.lapsDown}`
                : lap.delta === 0
                ? "Leader"
                : `<b>Delta:</b> +${lap.delta}s`,
              lap.lapDown
                ? `<b>Laps Down:</b> ${lap.lapsDown}`
                : lap.jerk === 0
                ? "Leader"
                : `<b>Jerk:</b> ${formatPlus(lap.jerk, 3)}s`,
              lap.jerk > 0 && `<b>Gap:</b> ${formatPlus(lap.gapAhead)}s`,
              "<extra></extra>",
            ]
              .filter(l => l)
              .join("<br>")
          ),
      })),
    ],
    [vehiclesData, stages, filteredLaps, showLegend, lapFlags]
  ) as Data[];

  return (
    <Loading
      statuses={[runStatus, lapsListStatus, vehiclesStatus]}
      errors={[runError, lapsListError, vehiclesError]}
      className="h-full w-full flex flex-row relative"
      wrap
    >
      {filteredLaps.length > 0 ? (
        <Plot
          data={data}
          layout={{
            autosize: true,
            margin: {
              l: 40,
              r: 5,
              b: 5,
              t: 5,
              pad: 10,
            },
            modebar: {
              orientation: "v",
            },
            xaxis: {
              showgrid: true,
              showticklabels: true,
              zeroline: true,
              tickprefix: "Lap ",
              gridcolor: "gray",
              tickfont: {
                size: 10,
                color: "white",
              },
              rangeslider: {
                range: [-1, 1],
                thickness: 0.05,
              },
              minor: {
                ticks: "inside",
                ticklen: 6,
                tickcolor: "gray",
              },
              range: [lapRange.min, lapRange.max],
            },
            yaxis: {
              showgrid: false,
              showticklabels: false,
              zeroline: false,
              color: "gray",
              range: [-10, -9],
              overlaying: undefined,
              fixedrange: true,
            },
            yaxis2: {
              showgrid: true,
              showticklabels: true,
              zeroline: true,
              tickformat: "%M:%S",
              tickcolor: "gray",
              gridcolor: "gray",
              // rangemode: "nonnegative",
              autorange: "reversed",
              fixedrange: false,
              tickfont: {
                size: 10,
                color: "white",
              },
              minor: {
                ticks: "inside",
                ticklen: 6,
                tickcolor: "gray",
              },
            },
            // showlegend: false,
            paper_bgcolor: "rgba(0,0,0,255)",
            plot_bgcolor: "rgba(0,0,0,255)",
          }}
          frames={[]}
          config={{
            displayModeBar: false,
            responsive: true,
          }}
          className="flex-grow"
        />
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <h1 className="text-2xl text-white">No laps found</h1>
        </div>
      )}
    </Loading>
  );
};

// whydid

export default JerkLineChart;
