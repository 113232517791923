/** @format */

import { memo, useMemo } from "react";
import Plot from "react-plotly.js";
import { useSearchParams } from "react-router-dom";
import { useVehiclesList } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, ordinal } from "../../../utils/utility";

const LineMap: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const {
    data: vehiclesData = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID, {
    select: (vehicles: VehicleList[]) =>
      vehicles.filter(vehicle => vehicle.lapDown === false),
  });
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const max = useMemo(
    () => Math.max(...vehiclesData.map(vehicle => vehicle.delta)),
    [vehiclesData]
  );
  return (
    <Loading
      statuses={[vehiclesStatus, favoriteVehiclesStatus]}
      errors={[vehiclesError, favoriteVehiclesError]}
      className="h-full w-full flex flex-col"
      wrap
    >
      <Plot
        className="flex-grow"
        data={[
          {
            x: vehiclesData.map(vehicle => vehicle.delta),
            y: vehiclesData.map(vehicle => 0),
            type: "scatter",
            mode: "text+markers",
            text: vehiclesData.map(vehicle => vehicle.number),
            marker: {
              symbol: vehiclesData.map(vehicle =>
                vehicle.id === detailVehicleID
                  ? "diamond"
                  : favoriteVehicleIDs?.includes(vehicle.id)
                  ? "square"
                  : "circle"
              ),
              color: vehiclesData.map(vehicle => vehicle.primaryColor),
              size: 15,
              line: {
                color: vehiclesData.map(vehicle => vehicle.secondaryColor),
                width: 1,
              },
            },
            hovertemplate: vehiclesData.map(vehicle =>
              [
                `<b>#${vehicle.number}</b> ${vehicle.displayName} <i>${ordinal(
                  vehicle.runningPosition
                )}</i>`,
                vehicle.delta === 0
                  ? ""
                  : `<b>Delta:</b> +${vehicle.delta}s <b>Gap:</b> ${formatPlus(
                      vehicle.gapAhead
                    )}s`,
                "<extra></extra>",
              ].join("<br>")
            ),
            textposition: "middle center",
            textfont: {
              color: vehiclesData.map(vehicle => vehicle.secondaryColor),
              size: 10,
            },
          },
        ]}
        layout={{
          autosize: true,
          margin: { l: 0, r: 0, t: 10, b: 20 },
          xaxis: {
            range: [-0.9, Math.ceil(max) + 0.9],
            showgrid: true,
            showticklabels: true,
            zeroline: false,
            tickprefix: "+",
            ticksuffix: "s",
            tickfont: {
              size: 10,
              color: "white",
            },
            minor: {
              ticks: "inside",
              ticklen: 6,
              tickcolor: "gray",
            },
          },
          yaxis: {
            range: [-1, 1],
            showgrid: false,
            showticklabels: false,
            zeroline: true,
          },
          showlegend: false,
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
        }}
        config={{
          displayModeBar: false,
          responsive: true,
        }}
      />
    </Loading>
  );
};

export default memo(LineMap);
