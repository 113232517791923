/** @format */

import {
  faArrowDown,
  faArrowUp,
  faBorderCenterH,
  faSave,
  faSync,
  faVolume,
  faVolumeXmark,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronUp } from "@fortawesome/sharp-regular-svg-icons";
import { Combobox } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useVideoMapping } from "../../../api/avAPI";
import { useRun } from "../../../api/runsApi";
import FormatArrow from "../../../utils/FormatArrow";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";

function classNames(...classes: (string | boolean)[]): string {
  return classes.filter(Boolean).join(" ");
}

const VideoController: React.FC<WidgetProps> = ({
  config: { saveId: savedId = null, detailOffset: savedDetailOffset = null } = {},
  updateConfig,
  isEditing,
}) => {
  const { runID } = useLiveParams();
  const { data: { series: seriesID } = {} } = useRun(runID);
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const [saveId, setSaveId] = useState<string | null>(savedId);
  const [streamKey, setStreamKey] = useState<number>(0);
  const [detailOffset, setDetailOffset] = useState(savedDetailOffset);

  const {
    data: streams,
    status: videoMappingStatus,
    error: videoMappingError,
  } = useVideoMapping(seriesID || 0, runID);

  const detailVehicleStream = streams?.find(
    stream => stream.vehicleId === detailVehicleID
  );
  const stream = saveId
    ? streams?.find(stream => stream.saveId === saveId)
    : detailOffset !== null
    ? streams?.find(
        stream =>
          stream?.position === (detailVehicleStream?.position || 0) + detailOffset
      )
    : undefined;
  const saveConfig = () => {
    if (!updateConfig) throw new Error("updateConfig is not defined");
    updateConfig({
      saveId: detailOffset != null ? null : saveId || null,
      detailOffset: detailOffset,
    });
  };
  console.log(stream);
  return (
    <Loading statuses={[videoMappingStatus]} errors={[videoMappingError]}>
      <VideoStreamPlayer feedData={stream} key={streamKey}>
        <div className="absolute top-0 z-10 mt-1 left-1/2 transform -translate-x-1/2 opacity-80 flex bg-gray-800 px-1 rounded-lg align-middle">
          {isEditing && (saveId !== savedId || detailOffset !== savedDetailOffset) && (
            <Tippy
              content={
                <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                  Save Changes
                </span>
              }
              placement="bottom"
              touch={false}
            >
              <button
                type="button"
                title="Save Changes"
                className="mx-1 cursor-pointer text-xl"
                onClick={() => {
                  saveConfig();
                }}
              >
                <FontAwesomeIcon icon={faSave} swapOpacity className="text-white" />
              </button>
            </Tippy>
          )}
          <StreamSelector
            activeStream={stream}
            streams={streams}
            setStream={id => {
              setSaveId(id);
              setDetailOffset(null);
            }}
          />
          <Tippy
            content={
              <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                Refresh Stream
              </span>
            }
            placement="bottom"
            touch={false}
          >
            <button
              type="button"
              title="Refresh Stream"
              className="ml-3"
              onClick={() => {
                setStreamKey(streamKey + 1);
              }}
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
          </Tippy>
          <Tippy
            content={
              <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                Selected Driver
              </span>
            }
            placement="bottom"
            touch={false}
          >
            <button
              type="button"
              title="Detail Driver"
              className="ml-3"
              onClick={() => {
                if (detailOffset === 0) {
                  setDetailOffset(null);
                } else {
                  setSaveId(null);
                  setDetailOffset(0);
                }
              }}
            >
              <FontAwesomeIcon
                icon={faBorderCenterH}
                className={detailOffset === 0 ? "text-indigo-500" : "text-white"}
              />
            </button>
          </Tippy>
          <div className="ml-3 flex flex-col">
            <Tippy
              content={
                <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                  Before Selected Driver
                </span>
              }
              placement="bottom"
              touch={false}
            >
              <button
                type="button"
                title="Increase Offset"
                className=""
                onClick={() => {
                  if (detailOffset !== null) {
                    setDetailOffset(detailOffset - 1);
                  } else {
                    setDetailOffset(-1);
                    setSaveId(null);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </button>
            </Tippy>
            <Tippy
              content={
                <span className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                  After Selected Driver
                </span>
              }
              placement="bottom"
              touch={false}
            >
              <button
                type="button"
                title="Decrease Offset"
                className=""
                onClick={() => {
                  if (detailOffset !== null) {
                    setDetailOffset(detailOffset + 1);
                  } else {
                    setDetailOffset(1);
                    setSaveId(null);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </button>
            </Tippy>
          </div>
          {![0, null].includes(detailOffset) && (
            <div className="text-white ml-1 my-auto">
              <FormatArrow>{detailOffset}</FormatArrow>
            </div>
          )}
        </div>
      </VideoStreamPlayer>
    </Loading>
  );
};

export default VideoController;

interface VideoStreamPlayerProps {
  feedData: VideoStream | undefined;
  children: React.ReactNode;
}

const VideoStreamPlayer: React.FC<VideoStreamPlayerProps> = ({
  feedData,
  children,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const node = videoRef.current;
    if (node !== null && feedData?.stream1) {
      if (Hls.isSupported()) {
        console.log("using HLS");
        var hls = new Hls();
        hls.loadSource(feedData?.stream1);
        hls.attachMedia(node);
      } else if (node.canPlayType("application/vnd.apple.mpegurl")) {
        console.log("Using HLS fallback");
        node.src = feedData?.stream1;
      } else {
        console.error("HLS not supported");
      }
      node.play().catch(error => {
        console.error("Play error:", error);
      });
    }
  }, [feedData?.stream1]);
  return (
    <div className="relative flex align-middle justify-center h-full">
      <button
        type="button"
        title="Toggle Mute"
        className="absolute top-0 left-0 m-2 bg-black bg-opacity-50 text-white rounded-full p-2 cursor-pointer"
        onClick={() => {
          if (!videoRef) throw new Error("Video element not found");
          videoRef.current!.muted = !videoRef.current!.muted;
        }}
      >
        {videoRef.current?.muted ? (
          <FontAwesomeIcon icon={faVolume} />
        ) : (
          <FontAwesomeIcon icon={faVolumeXmark} />
        )}
      </button>
      {children}
      <video
        ref={videoRef}
        muted
        autoPlay
        className="w-full aspect-video justify-center align-middle"
        poster="/crash_low.png"
      />
    </div>
  );
};

interface StreamSelectorProps {
  streams: VideoStream[] | undefined;
  activeStream: VideoStream | undefined;
  setStream: (id: string) => void;
}

const StreamSelector: React.FC<StreamSelectorProps> = ({
  streams,
  activeStream,
  setStream,
}) => {
  const [query, setQuery] = useState("");
  const filteredStreams =
    query === ""
      ? streams
      : streams?.filter(stream => {
          return stream.title.toLowerCase().includes(query.toLowerCase());
        });
  return (
    <Combobox
      as="div"
      value={streams?.find(stream => stream.saveId === activeStream?.saveId)}
      onChange={({ saveId }) => {
        setStream(saveId);
        setQuery("");
      }}
      className="my-auto"
    >
      <div className="relative">
        <Combobox.Input
          className="w-full px-2 rounded-full ml-1 bg-black bg-opacity-90 border-0 text-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 z-20"
          placeholder="Search for a stream..."
          onChange={event => setQuery(event.target.value)}
          displayValue={(stream: VideoStream) => stream?.title}
          style={{
            backgroundColor: activeStream?.primaryColor || undefined,
            color: activeStream?.secondaryColor || undefined,
            borderColor: activeStream?.secondaryColor || undefined,
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <FontAwesomeIcon icon={faChevronUp} />
        </Combobox.Button>
        {filteredStreams && filteredStreams.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-black py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredStreams.map(stream => (
              <Combobox.Option
                key={stream.saveId}
                value={stream}
                className={({ active }) =>
                  classNames(
                    "relative cursor-pointer select-none py-1 pl-2 pr-9 m-1 rounded-md z-20",
                    active ? "bg-indigo-600 text-white" : "text-white"
                  )
                }
                style={{
                  backgroundColor: stream.primaryColor || undefined,
                  color: stream.secondaryColor || undefined,
                }}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {stream.title}
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

// const VideoScoringTower = ({
//   flagClass,
//   runData,
//   vehiclesData,
//   className,
//   favoriteVehicleIDs,
// }) => (
//   <div className={`${className} rounded-xl bg-black bg-opacity-90 overflow-y-auto`}>
//     <div className={`sticky top-0 border-b text-center ${flagClass} bg-opacity-90`}>
//       <table className="w-full">
//         <tbody>
//           <tr>
//             <td className="text-right border-r pr-1">Race</td>
//             <td className="text-left border-l pl-1">
//               {runData?.lapNumber} of {runData?.totalLaps}
//             </td>
//           </tr>
//           <tr>
//             <td className="text-right border-r pr-1">{runData?.currentStage}</td>
//             <td className="text-left border-l pl-1">
//               {runData?.stageKey === "overtime"
//                 ? `${
//                     runData?.lapNumber - runData?.scheduledLaps >= 0
//                       ? runData?.lapNumber - runData?.scheduledLaps
//                       : 0
//                   } of ${runData?.totalLaps - runData?.scheduledLaps}`
//                 : `${runData?.lapNumber - (runData?.stageEnd - runData?.stageLaps)}
//               of ${runData?.stageLaps}`}
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//     <RunTable
//       tableClassName={"text-sm w-full h-auto font-mono"}
//       favoriteVehicleIDs={favoriteVehicleIDs}
//       columns={[
//         {
//           Header: "",
//           id: "blank",
//           columns: [
//             {
//               Header: "Pos",
//               id: "position",
//               filterable: false,
//               accessor: "runningPosition",
//               className: "text-right",
//             },
//             {
//               Header: "#",
//               accessor: "number",
//               className: "text-right px-1",
//               Cell: ({
//                 value,
//                 row: {
//                   original: { primaryColor, secondaryColor },
//                 },
//               }) => (
//                 <div
//                   className="w-5 h-5 ml-auto rounded flex items-center justify-center border overflow-clip font-sans"
//                   style={{
//                     color: secondaryColor,
//                     backgroundColor: primaryColor,
//                     borderColor: secondaryColor,
//                   }}
//                 >
//                   <div className="font-bold text-center text-sm">{value}</div>
//                 </div>
//               ),
//             },
//             {
//               Header: "Driver",
//               accessor: "displayName",
//               className:
//                 "text-left overflow-hidden whitespace-nowrap w-full max-w-0 truncate border-x px-1",
//               Cell: ({
//                 value,
//                 row: {
//                   original: { isOnDvp, isOnTrack, inPlayoffs },
//                 },
//               }) => (
//                 <span
//                   className={
//                     isOnDvp ? "text-red-500" : inPlayoffs ? "text-yellow-500" : ""
//                   }
//                 >
//                   {value}
//                 </span>
//               ),
//             },
//             {
//               Header: "Itvl",
//               accessor: "delta",
//               className: "text-center",
//               Cell: ({
//                 value,
//                 row: {
//                   original: { delta, isOnTrack, status, lapDown, runningPosition },
//                 },
//               }) =>
//                 !isOnTrack
//                   ? status === 1
//                     ? "PIT"
//                     : status === 2
//                     ? "BTW"
//                     : "OUT"
//                   : lapDown
//                   ? `${formatPlus(delta, 0)}L`
//                   : runningPosition === 1
//                   ? "Leader"
//                   : formatPlus(value, 3),
//             },
//             {
//               Header: "Stint",
//               id: "stintLength",
//               accessor: ({ lapNumber, lastStop }) => lapNumber - lastStop?.lap,
//               className: "hidden xl:table-cell text-center",
//               Cell: ({ value }) => (value ? value : "-"),
//             },
//           ],
//         },
//       ]}
//     />
//   </div>
// );
