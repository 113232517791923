/** @format */

import { faPlus, faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hls from "hls.js";
import { isDev } from "../../../index";
import { sortBy } from "lodash";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useAudioMapping } from "../../../api/avAPI";
import { useRun, useVehiclesList } from "../../../api/runsApi";
import {
  useAddSavedScanner,
  useRemoveSavedScanner,
  useSavedScannersForRun,
} from "../../../api/userAPI";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
const panMap = {
  l: -1,
  c: 0,
  r: 1,
} as Record<string, number>;

export const testingStream = false;

const filterTopStreams = (stream: AudioStream) =>
  isNaN(parseInt(stream?.driver_number)) && !stream?.driver_name.includes("Scan");

const Scanner: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const { data: { series: seriesID } = {} } = useRun(runID);
  const {
    data: vehiclesData = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID);
  const {
    data: savedScannerIDs,
    status: savedScannerStatus,
    error: savedScannerError,
  } = useSavedScannersForRun(runID);
  const addSavedScanner = useAddSavedScanner(runID);
  const removeSavedScanner = useRemoveSavedScanner(runID);
  const {
    data: audioMapping,
    status: audioMappingStatus,
    error: audioMappingError,
  } = useAudioMapping(seriesID || 0, { enabled: !!vehiclesData });
  const [sortOption, setSortOption] = useState("position");
  const addToFavorites = (stream: AudioStream) => {
    addSavedScanner(stream.driver_number);
  };
  const removeFromFavorites = (stream: AudioStream) => {
    removeSavedScanner(stream.driver_number);
  };
  const filterSavedScanners = useCallback(
    (stream: AudioStream) => savedScannerIDs?.includes(stream?.driver_number),
    [savedScannerIDs]
  );
  const filterOtherScanners = useCallback(
    (stream: AudioStream) =>
      !filterSavedScanners(stream) &&
      !filterTopStreams(stream) &&
      !stream?.driver_name.includes("Scan"),
    [filterSavedScanners]
  );
  return (
    <div className="p-2">
      <Loading
        statuses={[vehiclesStatus, savedScannerStatus, audioMappingStatus]}
        errors={[vehiclesError, savedScannerError, audioMappingError]}
        wrap={false}
      >
        <ul className="grid grid-cols-2 gap-x-1 gap-y-2">
          {audioMapping?.filter(filterTopStreams)?.map(stream => (
            <AudioStreamPlayer
              key={stream?.stream_number}
              muteKey={
                stream.driver_name === "Officials"
                  ? "O"
                  : stream.driver_name.includes("Radio")
                  ? "R"
                  : ""
              }
              feedData={stream}
              vehicleData={vehiclesData?.find(
                vehicle => vehicle.number === stream.driver_number
              )}
              def={true}
              defaultPan={stream.driver_name.includes("Radio") ? "l" : "c"}
            />
          ))}
        </ul>
        {(audioMapping?.filter(filterSavedScanners)?.length ?? 0) > 0 && (
          <ul className="grid grid-cols-2 gap-x-1 gap-y-1 mt-1 pt-1 border-t border-b mb-1 pb-1 border-gray-500">
            {sortBy(audioMapping, "stream.driver_name")
              .filter(filterSavedScanners)
              .map((stream, index) => (
                <AudioStreamPlayer
                  key={stream?.stream_number}
                  feedData={stream}
                  vehicleData={vehiclesData.find(
                    vehicle => vehicle.number === stream.driver_number
                  )}
                  favorite={true}
                  addToFavorites={() => addToFavorites(stream)}
                  removeFromFavorites={() => removeFromFavorites(stream)}
                  defaultPan="l"
                />
              ))}
          </ul>
        )}
        {(audioMapping?.filter(filterOtherScanners)?.length ?? 0) > 0 && (
          <>
            <div className="my-1 text-center">
              <div className="inline">
                Sort:
                <div className="inline-flex ml-1 text-xs">
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      setSortOption("name");
                    }}
                    className={`${
                      sortOption === "name"
                        ? "bg-white text-black"
                        : "border border-white"
                    } px-1 rounded-l`}
                  >
                    Name
                  </button>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      setSortOption("number");
                    }}
                    className={`${
                      sortOption === "number"
                        ? "bg-white text-black"
                        : "border border-white"
                    } px-1`}
                  >
                    Number
                  </button>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      setSortOption("position");
                    }}
                    className={`${
                      sortOption === "position"
                        ? "bg-white text-black"
                        : "border border-white"
                    } px-1 rounded-r`}
                  >
                    Position
                  </button>
                </div>
              </div>
            </div>
            <ul className="grid grid-cols-2 gap-x-1 gap-y-1 overflow-hidden">
              {sortBy(
                audioMapping,
                sortOption === "name"
                  ? "driver_name"
                  : sortOption === "number"
                  ? stream => !isNaN(parseInt(stream.driver_number))
                  : sortOption === "position"
                  ? stream =>
                      vehiclesData.find(
                        vehicle => vehicle.number === stream.driver_number
                      )?.runningPosition || Infinity
                  : "driver_name"
              )
                .filter(filterOtherScanners)
                .map(stream => (
                  <AudioStreamPlayer
                    key={stream?.stream_number}
                    feedData={stream}
                    vehicleData={vehiclesData.find(
                      vehicle => vehicle.number === stream.driver_number
                    )}
                    favorite={false}
                    addToFavorites={() => addToFavorites(stream)}
                    removeFromFavorites={() => removeFromFavorites(stream)}
                    defaultPan="l"
                  />
                ))}
            </ul>
          </>
        )}
      </Loading>
    </div>
  );
};

export default Scanner;

interface AudioStreamPlayerProps {
  feedData: AudioStream;
  vehicleData: VehicleList | undefined;
  def?: boolean;
  favorite?: boolean;
  addToFavorites?: () => void;
  removeFromFavorites?: () => void;
  defaultPan?: string;
  muteKey?: string;
}

const AudioStreamPlayer: React.FC<AudioStreamPlayerProps> = ({
  feedData,
  vehicleData,
  def = false,
  favorite,
  addToFavorites,
  removeFromFavorites,
  muteKey,
  defaultPan = "c",
}) => {
  const ref = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(false);
  const [panner, setPanner] = useState<any>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [levels, setLevels] = useState<number>(0);
  const [pan, setPan] = useState<string>(defaultPan);
  const [mediaElementSource, setMediaElementSource] =
    useState<MediaElementAudioSourceNode>();
  const [volume, setVolume] = useState<number>(
    feedData.driver_name.includes("Radio") ? 0.5 : 1
  );
  const initialize = () => {
    // don't initialize audio context until user clicks play
    if (!ref.current) return;
    if (!ref.current.src) {
      if (Hls.isSupported()) {
        console.debug("using HLS");
        var hls = new Hls();
        if (isDev && testingStream) {
          hls.loadSource(
            `http://amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest(format=m3u8-aapl)`
          );
        } else {
          hls.loadSource(`${feedData.base_url}${feedData.stream_ios}`);
        }
        hls.attachMedia(ref.current);
      } else if (ref.current.canPlayType("application/vnd.apple.mpegurl")) {
        console.debug("Using HLS fallback");
        ref.current.src = `${feedData.base_url}${feedData.stream_ios}`;
      } else {
        console.error("HLS not supported");
      }
      console.debug("init audio");
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      let track;
      if (!mediaElementSource) {
        track = audioContext.createMediaElementSource(ref.current);
        setMediaElementSource(track);
      } else {
        track = mediaElementSource;
      }
      const panner = audioContext.createPanner();
      const val = panMap[pan];
      panner.setPosition(val, 0, 1 - Math.abs(val));
      track.connect(analyser).connect(panner).connect(audioContext.destination);
      const pcmData = new Float32Array(analyser.fftSize);
      const onFrame = () => {
        if (ref?.current?.paused) {
          window.requestAnimationFrame(onFrame);
          return;
        }
        let sumSquares = 0.0;
        for (const amplitude of pcmData) {
          sumSquares += amplitude * amplitude;
        }
        const rms = Math.sqrt(sumSquares / pcmData.length);

        // Normalize rms to a range of 0 to 1
        const normalizedRms = Math.max(0, Math.min(1, rms));

        // Scale this to a range of 0 to 100
        const scaledRms = Math.round(normalizedRms * 100);

        setLevels(scaledRms);

        window.requestAnimationFrame(onFrame);
      };
      window.requestAnimationFrame(onFrame);
      setPanner(panner);
    }
  };
  useEffect(() => {
    if (ref?.current) {
      ref.current.volume = volume;
    }
  }, [ref, volume]);
  const handlePlay = () => {
    if (!ref.current) return;
    if (ref.current.duration && !testingStream) {
      ref.current.currentTime = ref.current.duration;
    }
    if (!ref.current.src) {
      initialize();
      ref.current.play();
    }
    setPlaying(true);
  };
  const handlePause = (e: SyntheticEvent | null = null) => {
    setPlaying(false);
    setLevels(0);
    setExpanded(false);
  };
  const togglePlaying = () => {
    if (ref?.current) {
      if (!ref.current.src) {
        initialize();
        ref.current.play();
      } else {
        if (!playing) {
          ref.current.play();
          handlePlay();
        } else {
          ref.current.pause();
          handlePause();
        }
      }
    }
  };
  const updatePan = useCallback(
    (e: SyntheticEvent, newPan: string) => {
      e.stopPropagation();
      if (panner) {
        const val = panMap[newPan];
        panner.positionX.setValueAtTime(val, 0);
        panner.positionY.setValueAtTime(0, 0);
        panner.positionZ.setValueAtTime(1 - Math.abs(val), 0);
        // panner.setPosition(val, 0, 1 - Math.abs(val));
        setPan(newPan);
      }
    },
    [panner]
  );
  return feedData ? (
    <li
      className={`flex flex-col cursor-pointer overflow-hidden ${
        playing ? "bg-gray-300" : "bg-gray-500"
      } rounded text-black`}
    >
      <div className="flex align-baseline flex-grow items-center">
        <audio
          ref={ref}
          // volume={volume}
          id={`audio-${feedData.driver_name}`}
          src={isSafari ? `${feedData.base_url}${feedData.stream_ios}` : undefined}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handlePause}
          onError={handlePause}
          onStalled={handlePause}
          onAbort={handlePause}
          onSuspend={handlePause}
        />
        <button
          title="Expand"
          type="button"
          className={`h-6 w-6 shrink-0 text-sm ${
            expanded ? "rounded-l rotate-90" : "rounded-l"
          } font-bold flex flex-col justify-center items-center mr-1 transition-all duration-300 ease-in-out`}
          onClick={e => {
            if (playing) {
              e.stopPropagation();
              setExpanded(exp => !exp);
            }
          }}
          style={{ backgroundColor: `hsl(0,${levels}%,50%)` }}
        >
          {isNaN(parseInt(feedData?.driver_number)) ? muteKey : feedData?.driver_number}
        </button>
        <button
          className="text-xs whitespace-nowrap truncate grow text-left h-full flex items-center"
          onClick={togglePlaying}
          data-ph-capture-attribute-vehicle-id={vehicleData?.id}
          data-ph-capture-attribute-vehicle-number={vehicleData?.number}
          data-ph-capture-attribute-driver-name={vehicleData?.displayName}
        >
          {vehicleData?.displayName || feedData.driver_name}
        </button>
        {!def ? (
          favorite ? (
            <FontAwesomeIcon
              icon={faX}
              onClick={e => {
                e.stopPropagation();
                if (removeFromFavorites) {
                  removeFromFavorites();
                }
              }}
              className="mr-1 text-sm"
            />
          ) : (
            <FontAwesomeIcon
              icon={faPlus}
              onClick={e => {
                e.stopPropagation();
                if (addToFavorites) {
                  addToFavorites();
                }
              }}
              className="mr-1 text-sm"
            />
          )
        ) : null}
      </div>
      <div
        className={`flex items-center ${
          expanded ? "h-6" : "h-0"
        } align-baseline text-sm transition-height duration-300 ease-in-out overflow-hidden`}
      >
        <div className="pl-1 flex items-center grow">
          V:
          <input
            aria-label="volume"
            type="range"
            min="0"
            max="1"
            step="0.01"
            className="w-full m-2"
            value={volume}
            onChange={e => {
              setVolume(parseFloat(e.target.value));
            }}
          />
        </div>
      </div>
      {!isSafari && (
        <div
          className={`flex items-center ${
            expanded ? "h-6" : "h-0"
          } align-baseline text-sm transition-height duration-300 ease-in-out overflow-hidden`}
        >
          {["l", "c", "r"].map(panOption => (
            <button
              key={panOption}
              className={`${
                pan === panOption ? "bg-green-400" : "bg-gray-500"
              } h-6 text-white text-xs grow text-center last:rounded-r first:rounded-l border border-gray-400`}
              onClick={e => updatePan(e, panOption)}
              value={panOption}
            >
              {panOption.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </li>
  ) : null;
};
