/** @format */

import { useTable } from "react-table";
import { isEqual, sortBy } from "lodash";
import { memo } from "react";

const ColorsTable = ({ columns: userColumns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns: userColumns, data: sortBy(data, ({ number }) => parseInt(number)) }
  );
  return (
    <table {...getTableProps()} className="table-auto table-hover w-[100vw] sm:w-full">
      <thead className="table-head">
        {headerGroups.map((headerGroup, idx) => {
          return (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={idx === 0 ? "hidden sm:table-row" : headerGroup.className}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    column.className || ""
                  } bg-black sticky border-collapse border-x border-gray-500 w-0 top-0 word-break-all`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody className="h-full text-sm" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${
                      cell.column.className || "text-center"
                    } border-collapse border-x border-gray-500 whitespace-nowrap truncate`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default memo(ColorsTable, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data);
});
