/** @format */

import { groupBy, mapValues, sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRun, useVehicleLapsList, useVehiclesList } from "../../../api/runsApi";
import FormatDelta from "../../../utils/FormatDelta";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, median } from "../../../utils/utility";
import BarCell, { rangeFromStdDev } from "../../../utils/BarCell";
import { useFilterLaps } from "./FilterLaps";

const LapTimeTable = () => {
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const { filteredVehicles } = useFilterLaps();
  const compareVehicles = filteredVehicles
    .filter(id => id !== detailVehicleID)
    .filter(id => id);
  const { runID } = useLiveParams();
  const { data: runData = {}, status: runStatus, error: runError } = useRun(runID);
  const {
    data: vehiclesData = [],
    isSuccess: isSuccessVehicles,
    error: vehiclesError,
    status: vehiclesStatus,
  } = useVehiclesList(runID);
  const {
    data: laps = [],
    isSuccess: isSuccessLaps,
    error: lapsError,
    status: lapsStatus,
  } = useVehicleLapsList(runID);
  const detailVehicle = useMemo(
    () => vehiclesData.find(v => v.id === detailVehicleID),
    [vehiclesData, detailVehicleID]
  );
  const isSuccess = isSuccessLaps && isSuccessVehicles;
  const [compareLaps, setCompareLaps] = useState([]);
  const data = useMemo(
    () =>
      isSuccess
        ? runData?.flagPeriods
            .map(period => {
              const periodLaps = sortBy(
                laps.filter(
                  lap => lap.lapNumber >= period.start && lap.lapNumber <= period.end
                ),
                "lapNumber"
              );
              const vehicleLaps = groupBy(periodLaps, "vehicleId");
              const compareVehicleLaps = compareVehicles.map(id => ({
                id,
                laps: vehicleLaps[id],
              }));
              return {
                ...period,
                periodLaps,
                vehicleDetails: mapValues(vehicleLaps, laps => {
                  const bestTime = Math.min(...laps.map(lap => lap.lapTime));
                  const medianTime = median(laps.map(lap => lap.lapTime));
                  return {
                    laps: laps
                      .map(lap => {
                        const compareDriversLapTimes = compareVehicleLaps.map(
                          ({ id, laps }) => ({
                            id,
                            lap: laps?.find(l => l.lapNumber === lap.lapNumber),
                            delta:
                              lap.lapTime -
                              laps?.find(l => l.lapNumber === lap.lapNumber)?.lapTime,
                          })
                        );
                        const compareLapTimes = compareLaps.map(lapNumber => ({
                          lapNumber,
                          delta:
                            lap.lapTime -
                            laps.find(l => l.lapNumber === lapNumber)?.lapTime,
                        }));
                        return {
                          // ToDo Can This All Go Into The DB?
                          ...lap,
                          compareDriversLapTimes,
                          compareLapTimes,
                        };
                      })
                      .reverse(),
                    bestTime,
                    medianTime,
                  };
                }),
              };
            })
            .reverse()
        : [],
    [laps, runData, compareVehicles, compareLaps, isSuccess]
  );
  return (
    <Loading
      statuses={[runStatus, vehiclesStatus, lapsStatus]}
      errors={[runError, vehiclesError, lapsError]}
      className="w-full"
      wrap
    >
      <div className="text-lg py-1">
        <b>
          {detailVehicle?.number} {detailVehicle?.displayName}
        </b>
      </div>
      <table className="w-full text-sm font-mono ">
        <thead className="text-base bg-black">
          <tr>
            <th colSpan={3} />
            <th colSpan={2} className="border-x border-gray-500">
              Self
            </th>
            {/* <th colSpan={2} className="border-x border-gray-500">
              Period
            </th>
            <th colSpan={2} className="border-x border-gray-500">
              Lap
            </th> */}
            <th colSpan={3 + compareVehicles.length}>Others</th>
          </tr>
          <tr className="text-base sticky top-0 bg-black z-20">
            <th className="border-r border-gray-500 bg-black">Lap #</th>
            <th className="border-x border-gray-500 bg-black">Pos</th>
            <th className="border-x border-gray-500 bg-black">Time</th>
            <th className="border-x border-gray-500 bg-black">∆ Med</th>
            <th className="border-x border-gray-500 bg-black">∆ Best</th>
            {/* <th className="border-x border-gray-500 bg-black">∆ Med</th>
            <th className="border-x border-gray-500 bg-black">∆ Best</th>
            <th className="border-x border-gray-500 bg-black">∆ Med</th>
            <th className="border-x border-gray-500 bg-black">∆ Best</th>
            <th className="border-x border-gray-500 bg-black">∆ Ahead</th> */}
            <th className="border-x border-gray-500 bg-black">∆ Behind</th>
            <th className="border-x border-gray-500 bg-black">∆ Leader</th>
            {compareVehicles.map(id => (
              <th key={id} className="border-x border-gray-500 bg-black">
                ∆ {vehiclesData.find(v => v.id === id).displayName}
              </th>
            ))}
            {compareLaps.map(lapNumber => (
              <th key={lapNumber} className="border-x border-gray-500 bg-black">
                ∆ {lapNumber}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-y border-gray-500">
          {data?.map(period => (
            <React.Fragment key={period?.start}>
              <tr className="text-lg border-y border-gray-500">
                <td colSpan={3} className="text-center border-r border-gray-500">
                  {period?.start} - {period?.end}
                </td>
                {period.flag === 1 ? (
                  <>
                    <td className="text-center border-x border-gray-500">
                      {period?.activeDriverMedian?.toFixed(3)}
                    </td>
                    <td className="text-center border-x border-gray-500">
                      {period?.activeDriverBest?.toFixed(3)}
                    </td>
                    <td className="text-center border-x border-gray-500">
                      {period?.medianTime?.toFixed(3)}
                    </td>
                    <td className="text-center border-x border-gray-500">
                      {period?.bestTime?.toFixed(3)}
                    </td>
                    <td className="text-center border-l border-gray-500" colSpan={5} />
                  </>
                ) : (
                  <td colSpan={9} className="text-center font-bold">
                    Caution Period
                  </td>
                )}
              </tr>
              {period.vehicleDetails?.[detailVehicleID]?.laps?.length > 0 &&
              period.flag === 1 ? (
                period.vehicleDetails?.[detailVehicleID]?.laps.map(lap => (
                  <tr
                    key={lap.lapNumber}
                    className=" even:bg-black odd:bg-gray-900 whitespace-nowrap"
                  >
                    <td className="text-right pr-2 border-r border-gray-500">
                      <input
                        type="checkbox"
                        id={`lap-${lap.lapNumber}`} // ToDo switch to id when added to serializer
                        className="rounded h-2.5 w-2.5 mr-1 align-baseline"
                        checked={compareLaps.includes(lap.lapNumber)}
                        onChange={() =>
                          setCompareLaps(cls =>
                            cls.includes(lap.lapNumber)
                              ? cls.filter(lapNumber => lapNumber !== lap.lapNumber)
                              : [...cls, lap.lapNumber]
                          )
                        }
                      />
                      {lap.lapNumber}
                    </td>
                    <td className="text-center border-x whitespace-nowrap border-gray-500">
                      {lap.runningPosition}
                      <FormatDelta
                        fixed={0}
                        formatOptions={{
                          padStart: 2,
                          zero: "",
                        }}
                        className="text-xs text-right"
                      >
                        {lap.positionChange}
                      </FormatDelta>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.lapTime}
                        bgColor="bg-green-600"
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "lapTime"
                        )}
                      >
                        {lap.lapTime.toFixed(3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToPersonalAverage}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToPersonalAverage"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToPersonalAverage, 3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToPersonalBest}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToPersonalBest"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToPersonalBest, 3)}
                      </BarCell>
                    </td>
                    {/* <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToPeriodAverage}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToPeriodAverage"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToPeriodAverage, 3)}|
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToPeriodBest}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToPeriodBest"
                        )}
                        maxWidth={"50%"}
                        split
                      >
                        {formatPlus(lap.deltaToPeriodBest, 3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToLapAverage}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToLapAverage"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToLapAverage, 3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToLapBest}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToLapBest"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToLapBest, 3)}
                      </BarCell>
                    </td> */}
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToAhead}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToAhead"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToAhead, 3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToBehind}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToBehind"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToBehind, 3)}
                      </BarCell>
                    </td>
                    <td className="text-center border-x relative border-gray-500">
                      <BarCell
                        data={lap.deltaToLeader}
                        maxWidth={"50%"}
                        range={rangeFromStdDev(
                          period.vehicleDetails?.[detailVehicleID]?.laps,
                          "deltaToLeader"
                        )}
                        split
                      >
                        {formatPlus(lap.deltaToLeader, 3)}
                      </BarCell>
                    </td>
                    {compareVehicles.map(id => (
                      <td
                        key={id}
                        className="text-center relative border-x border-gray-500"
                      >
                        <BarCell
                          data={
                            lap.compareDriversLapTimes.find(c => c.id === id)?.delta
                          }
                          maxWidth={"50%"}
                          range={{ min: -1, max: 1 }}
                          split
                        >
                          {formatPlus(
                            lap.compareDriversLapTimes.find(c => c.id === id)?.delta,
                            3
                          )}
                        </BarCell>
                      </td>
                    ))}
                    {compareLaps.map(lapNumber => (
                      <td
                        key={lapNumber}
                        className="text-center relative border-x border-gray-500"
                      >
                        <BarCell
                          data={
                            lap.compareLapTimes.find(c => c.lapNumber === lapNumber)
                              ?.delta
                          }
                          maxWidth={"50%"}
                          range={{ min: -1, max: 1 }}
                          split
                        >
                          {formatPlus(
                            lap.compareLapTimes.find(c => c.lapNumber === lapNumber)
                              ?.delta,
                            3
                          )}
                        </BarCell>
                      </td>
                    ))}
                  </tr>
                ))
              ) : period.flag === 1 ? (
                <tr className={"border-gray-500"}>
                  <td
                    colSpan={12}
                    className="text-center font-bold text-base border-gray-500"
                  >
                    No Clean Laps
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Loading>
  );
};

export default LapTimeTable;
