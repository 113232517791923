/** @format */

const consoleMessage = `
MMMMMMMMMMMMMMMWMMMMMMMWNNWWWMMMWMMMMMWKKWKxKWMMMNxlkXNNNKOkKNNNK0KNNX0KNNNNNNNNNNNXKo;,:dKKdcdKWMMMMN0xkKWMMM
MMMMMMMMMMMMMMXkKMMMMMMMKocclOWMMWWMMMMMWWWMW0dOKKl.;ldO0Oc,oXXKKXNXXKKXNNNXXK000Okkd:;:lkx:ckNWMMMNOdxKWMMMMM
MMMMMMMMMMMMMMNXNMMN00NMWKOxxKWMMWNK0KNWWKOK0l'.';,'codkxo:;lKXKXXXK000kdoodko;,'''',;:clc:o0XNWWXkod0NMWXNMMM
MMMMMMMMMMMMMMMMMMMXo;lOWMWWMMMMMMWk;,:co:',,.......'ck0x:lkOKK0Oxoll:,'..':l,......,;::,:dKNXNXkllkNMMMO:cOWM
MMMMMMMMMMMMMMMMMMMWKdlxNWXXWMMWMMXd'.............. ..cdl:ck0ko:,,:;,,;clodxkxxxxxl:cc:,,cdOXXOlcdXWMMMWx,':kN
MMMMMMMMMMMMMMMWNWMMMWNWMMWXKXKXXKx;..................'';c::lc::::loloxOOkkkkxxkkoclddl;coxkxl:l0WMMMMMXxlox0W
MMMMMMMMMMMMMMMXkXMMMMMMMMWNXk:,,'..............','',;:::lddxxkOOOO0OkkkxdxxollooloxOOodxxdl;;dKWMMMMMMWNNNWMM
MMMMMMMMMMMMMMMMWMMMMMMMMMNXNx'...........;:,,;;codxxkO00K00KKKXXK0Okdlccldkxx0KKK000kdddo:,ckKNNWWMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMWWWMWNNO:.........;;,;:coxO0XNNK00KK00KXK0Odc,':lol:coxkOKKK0Okdddoc;;oOKXXXXXWWWMMMMMMMM
MMMMMMMMMMMMMMMMMWWMMMWWWNKx:........':lddkO0KKXK0OOO0KXXXKX0o;......,oxddk0KOdodddoool:;:d0KXXOo:oXWMMMMMMMMM
MMMMMMMMMMMMMMMMXxlx0NWWMWk;.......,codxkOkkkkxxoclddoooox0k;.........'lkKK0kocclcloooooxO0KKKKd;;xNMMMMMMMMMM
MMMMMMMMMMMMMMMMW0dc:kNWWXkl,....,cdocoolol:;;,,...'...':xk;..,;;;,'',coddooloolcccloox00K0KKK0kk0XWWWMMMMMMMM
MMMMMMMMMMMMMMWMMMMN00kkXXx,...,;:lc;:ccc,''...  ..,,.,:dOo,..';;,;cokkkdlcoxdl:::cldxOO00O00KOOXNWWMMMMMMMMWN
MMMMMMMMMMMMMMMMWWMMMMWNX0d;.';:l:'....'. ........':;,cxko,'..':oxO00o:dklcoo:;;;:clodxkxxkkk0OOKNXNWMMMMNKkl;
MMMMMMMMMMMMMMMMMMWNXNNXkl:;'..''..   ............,,.,d0kc',;lx0KKkooc':o:::;,,,;::cclddddxxxxxdddxkOXWKd:'..:
MMMMMMMMMMMMMMMMMMNd;;cl:;cc:;;'',;:c::::,........,';lxxkkddOXXKK0o:cl;:c,'''',,;:::clodxxxkko,....':kNX0kdlo0
MMMMMMMMMMMMMMMMMMWk:,;cloooodxxxOKXK0Okkl.......';cdkxdddc;oOO0KOd;..'::'...'',,,;:cldxxxkkkxc'..,o0WMMMMMMMM
MMMMMMMMMMMMMMMWX0xollcc:,'.';;;,;codxxdoc,....,;:ldOOl,,,,;:oOOd;.....''....'''',;cloxkOOOOO0x;;lONWMMMMMMMMM
MMMMMMMMMMWK00xlcc;;;'...          .;llc:'...',:clxxxkxoc::::dOd;...............';:loodk00000KKOKXWMMMMMMMMMMM
MMMMMMMMMWOccc;,'.......,;;:cc:,..':l:'....',:oddddddOKXk:;lk0d:'',''.........'',::;'..',;:cldOXNWMMMMMMMMMMMM
MMMMMMMMNklll:'..',;:clx0XNNX0Oxollo;.....';:lolc::cd0K0kxxkkko:;::,'........'','..          ,kNWWWWWMMMMMMMMM
MMMMMMMWOooolccoxkO0KKXNNX0xolllldoc,''',:clc::,....'cdkO00kc;coooc,,''''.......           'okxdolccco0WMMMMMM
MMMMMMWKdodxkkk0NWNNNWNKOdollccclolc:;,;;::;;,...   ...':kXKx;';locc::;;;;;;,'....       'oKWXd,.  .'c0MMMMMMM
MMMMMMNd'.',;:clodxkxxdodxxdl:;:;,,,'.,;:c:,,............c000k:',:lllcc:::cllooolc::;,'';xKNWMWXkookKNWMMMMMMM
MMMMMMWd.    ....... ...';:,..........;ldxdl;. ..'.....'.;xK0Okl:;:cc:;;:clllloollllcc:;,,:kXXNNWMMMMMMMMMMMMM
MMMMMMWk,.....',,.    ..........'',:oxOOxddx;.  .''..';cc:d00O0Odlc::;'',;,,,,;:llc::::;,,lKMWWWWWMMMMMMMMMMMM
MMMMMMWkc:;;;,''......',,..;ccoxOOOkkkdddddxc...'''',;col:;lxkOOkdoc:;,''''....;oxxxddollxXMMMMMMMMMMMWWMMMMMM
MMMMMMMKc''',,,;;,,,,;::cdxOK0Okxddolllcoddkl'','';::lllc;'.',;:ccc:;;,''',,''..';lk0KXXXNWMMMMMMMMMMMMMMMMMMM
MMMMMMMNx,. ..........,'';lxxoolccc:;,'.;oxOl,,;:lllooc;,..................',;;;;;coodxk0XWMMMMMMMMMMMMMMMMMMM
NNXXKK0xc;,'.......      ..':loc,...'.'cxkO0o,;cllllc;'...                  ..,:loxO000Okk0XWMMMMMMMMMMMMMMMMM
kxxdoooc,;;,',;:,....   ... .'clc;',;;ldxkkkc............           ..     .';::ldk0KXNNWWNXNWMMMMMMMMMMMMMMMM
:;;;;,',;;;:lodo:'.''..''......;::;;;;;;:;;,.              .........'''....',:dkkO0XNWWWWMMMMMMMMMMMMMMMMMMMMM
::;;;;,',,,,,',,'.'',,,;::c::;;::::;'....      .....',''',,;:ccccclllodoc,,'';xKXXNWWMMWMMMMMMMMMMMMMMMMMMMMMM
XKKKK0Okxxolc::;,,,;;;;;;;;;;;;,,,,'..''',;:ccllllododxddlodxxkkxxOOO00K0d:'';kWWWWMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMWNNNNXNNNNNNNNNNNXXXXK0000Ok0K0000000KKKKKKKK000KK0o;cx0KKXXXXXNNWWWXkloKMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM

##############################################################################################################
#####                                                                                                    #####
#####  Welcome to the console!                                                                           #####
#####                                                                                                    #####
#####  Would love to chat with you about whatever reason you're digging around!                          #####
#####                                                                                                    #####
#####  Send me an email: console@galaxie.app                                                             #####
#####                                                                                                    #####
#####  Full Swagger site for the API is available at                                                     #####
#####  https://api.galaxie.app/api/swagger/                                                              #####
#####                                                                                                    #####
##############################################################################################################
`;

export default consoleMessage;
