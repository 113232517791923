/** @format */

import { useSearchParams } from "react-router-dom";
import { useVehiclesDetail } from "../../../api/runsApi";
import FormatArrow from "../../../utils/FormatArrow";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, ordinal } from "../../../utils/utility";

const VehicleDataWidget: React.FC<WidgetProps> = ({
  config: { vehicleId } = { vehicleID: null },
}) => {
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const { runID } = useLiveParams();
  const { data, status, error } = useVehiclesDetail(
    runID,
    vehicleId || detailVehicleID
  );
  const { positionChange, lapTime, delta, lapDown, lapsDown } = data?.laps[0] || {};
  if (!vehicleId && !detailVehicleID) {
    return "Select a driver by clicking on their row";
  }
  return (
    <Loading
      statuses={[status]}
      errors={[error]}
      className="flex flex-col absolute inset-0 gap-0 px-2 py-1 justify-center leading-none gap-y-1 truncate max-w-full overflow-auto"
      wrap
    >
      <div className="whitespace-nowrap">
        <b
          style={{
            backgroundColor: data?.primaryColor,
            color: data?.secondaryColor,
            borderColor: data?.secondaryColor,
          }}
          className="px-1 py-0.5 rounded-md border-2"
        >
          #{data?.number}
        </b>{" "}
        <i>{data?.runningPosition ? ordinal(data?.runningPosition) : ""}</i>{" "}
        <FormatArrow className="inline-block" showEq>
          {positionChange ?? null}
        </FormatArrow>
      </div>
      <div className="text-sm whitespace-nowrap">
        <div>
          <b>Time:</b>
          {lapTime?.toFixed(2)}s{" "}
          {lapDown ? (
            <span>
              <b>L Down:</b> {lapsDown}
            </span>
          ) : delta === 0 ? (
            <b>Leader</b>
          ) : (
            <span>
              <b>Delta:</b> {formatPlus(delta ?? 0, 1)}s
              {/* {delta > 0 && (
              <span>
                {" "}
                <b>Gap:</b> {formatPlus(gapAhead, 1)}s
              </span>
            )} */}
            </span>
          )}
        </div>
      </div>
    </Loading>
  );
};

export default VehicleDataWidget;
