/** @format */

import { isDev } from "../../../index";
import moment from "moment";
import Plot from "react-plotly.js";
import { useIsLiveRun, useRun, useUpdateRun, useWeather } from "../../../api/runsApi";
import { useUser } from "../../../api/userAPI";
import NoRain from "../../../assets/no_rain.png";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";
import MapComponent from "../../../utils/Map";

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Weather: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const _isLive = useIsLiveRun(runID); // || isDev;
  const isLive = isDev || _isLive;
  const { data: user } = useUser();
  const { data: runData } = useRun(runID);
  const { mutate: updateRun } = useUpdateRun(runID);
  const updatePosition = ({ lat, lng }: { lat: number; lng: number }) =>
    updateRun({ lat, lng });
  const {
    data: weatherData,
    status: weatherStatus,
    error: weatherError,
  } = useWeather(runID, { enabled: isLive });
  const minutely = weatherData?.minutely
    ?.map(minutely => {
      return {
        time: moment
          .unix(minutely.dt)
          .utcOffset(weatherData?.timezoneOffset / 60)
          .local(),
        precipitation: isDev ? getRandomInt(0, 100) / 100 : minutely.precipitation,
        group: false,
      };
    })
    .filter(minutely => minutely.time.isAfter(moment()));

  const first10MinutesData = minutely?.slice(0, 10) || [];
  const restData = minutely?.slice(10) || [];
  // Group the rest of the data into 5-minute intervals and calculate the average
  const groupedData: { time: moment.Moment; precipitation: number; group: boolean }[] =
    restData
      ?.reduce((acc: number[][], curr, index) => {
        const groupIndex = Math.floor(index / 5);
        if (!acc[groupIndex]) {
          acc[groupIndex] = [];
        }
        acc[groupIndex].push(curr.precipitation);
        return acc;
      }, [])
      ?.map(group => group.reduce((a, b) => a + b, 0) / group.length)
      ?.map((group, index) => ({
        time: moment().add(11 + index * 5, "minutes"),
        precipitation: group,
        group: true,
      }));
  const rainSoon = first10MinutesData.some(data => data.precipitation > 0) || isDev;
  const rainLater =
    weatherData?.hourly
      ?.slice(0, 6)
      ?.map(data => data.pop)
      .some(data => (data ?? 0) > 0) || isDev;
  const anyRain = rainSoon || rainLater || isDev;

  // bar chart for the next 10 minutes then grouped by 5 minutes after that with the average
  const data = [...first10MinutesData, ...groupedData];
  return isLive ? (
    <Loading
      statuses={[weatherStatus]}
      errors={[weatherError]}
      className="h-full w-full flex flex-col relative"
      wrap
    >
      {typeof weatherData === "object" ? (
        anyRain ? (
          <>
            <div
              className="
            text-white text-center
          "
            >
              <b>temp:</b>
              {weatherData?.current?.temp}f <b>feels like:</b>
              {weatherData?.current?.feelsLike}f<br />{" "}
              {weatherData?.current?.weather[0]?.main}{" "}
              {moment.unix(weatherData?.current?.sunset).isAfter(moment()) && (
                <> sunset {moment.unix(weatherData?.current?.sunset).fromNow()} </>
              )}
            </div>
            {rainSoon ? (
              <Plot
                data={[
                  {
                    x: data.map(data => data.time.format("HH:mm")),
                    y: data.map(data => data.precipitation),
                    // show values on top of the bars
                    text: data.map(data => data.precipitation.toFixed(2)),
                    name: "Precipitation",
                    type: "bar",
                    yaxis: "y",
                    marker: {
                      // blue for the group then light blue for the rest
                      color: "blue",
                      opacity: data.map(data => (data.group ? 0.5 : 1)),
                    },
                  },
                ]}
                layout={{
                  margin: {
                    l: 40,
                    r: 5,
                    b: 40,
                    t: 40,
                    pad: 5,
                  },
                  xaxis: {
                    // showgrid: false,
                    showticklabels: true,
                    zeroline: true,
                    tickprefix: "",
                    color: "grey",
                    autotypenumbers: "strict",
                    tickfont: {
                      size: 10,
                      color: "white",
                    },
                    rangemode: "nonnegative",
                    // format the time to show hours and minutes in 24-hour format
                  },
                  yaxis: {
                    showgrid: true,
                    showticklabels: true,
                    zeroline: true,
                    color: "gray",
                    rangemode: "nonnegative",
                    gridcolor: "rgba(255,255,255,0.5)",
                    fixedrange: true,
                    range: [0, 1],
                    // format tick as percentage
                    tickformat: ".0%",
                    tickfont: {
                      size: 10,
                      color: "white",
                    },
                  },
                  // showlegend: false,
                  paper_bgcolor: "rgba(0,0,0,255)",
                  plot_bgcolor: "rgba(0,0,0,255)",
                }}
                frames={[]}
                config={{
                  responsive: false,
                  displaylogo: false,
                  displayModeBar: false,
                }}
                className="flex-grow h-[33%]"
              />
            ) : (
              <p>No Rain Expected in the next 2 hours</p>
            )}
            {rainLater ? (
              <Plot
                data={[
                  {
                    x: weatherData?.hourly
                      ?.slice(0, 6)
                      ?.map(data => moment.unix(data.dt).format("HH:mm")),
                    y: weatherData?.hourly?.slice(0, 6)?.map(data => data.pop ?? 0),
                    // show values on top of the bars
                    name: "Precipitation",
                    type: "bar",
                    yaxis: "y",
                    marker: {
                      color: "blue",
                    },
                  },
                  {
                    x: weatherData?.hourly
                      ?.slice(0, 6)
                      ?.map(data => moment.unix(data.dt).format("HH:mm")),
                    y: weatherData?.hourly?.slice(0, 6).map(data => data.temp),
                    // show values on top of the bars
                    name: "Temperature",
                    type: "scatter",
                    line: {
                      color: "red",
                    },
                    yaxis: "y2",
                  },
                ]}
                layout={{
                  margin: {
                    l: 45,
                    r: 45,
                    b: 30,
                    t: 10,
                    pad: 5,
                  },
                  // legend at the top
                  legend: {
                    orientation: "h",
                    yanchor: "top",
                    xanchor: "center",
                    y: 1.1,
                    x: 0.5,
                    bgcolor: "rgba(0,0,0,0)",
                    bordercolor: "rgba(0,0,0,0)",
                    font: {
                      color: "white",
                    },
                  },
                  xaxis: {
                    // showgrid: false,
                    showticklabels: true,
                    zeroline: true,
                    tickprefix: "",
                    color: "grey",
                    autotypenumbers: "strict",
                    tickfont: {
                      size: 10,
                      color: "white",
                    },
                    rangemode: "nonnegative",
                  },
                  yaxis: {
                    showgrid: true,
                    showticklabels: true,
                    zeroline: true,
                    tickcolor: "white",
                    gridcolor: "white",
                    tickfont: {
                      size: 10,
                      color: "white",
                    },
                    // format tick as percentage
                    tickformat: ".0%",
                    rangemode: "nonnegative",
                    fixedrange: true,
                    range: [0, 1],
                    tick0: 0,
                    dtick: 1 / 6,
                  },
                  yaxis2: {
                    showgrid: true,
                    showticklabels: true,
                    zeroline: true,
                    tickcolor: "white",
                    gridcolor: "white",
                    tickfont: {
                      size: 10,
                      color: "white",
                    },
                    // include degree and fahrenheit symbol
                    ticksuffix: "°F",
                    tickformat: ".0f",
                    rangemode: "nonnegative",
                    fixedrange: true,
                    range: [40, 100],
                    overlaying: "y",
                    side: "right",
                  },
                  // showlegend: false,
                  paper_bgcolor: "rgba(0,0,0,255)",
                  plot_bgcolor: "rgba(0,0,0,255)",
                }}
                frames={[]}
                config={{
                  responsive: false,
                  displaylogo: false,
                  displayModeBar: false,
                }}
                className="flex-grow h-[25%]"
              />
            ) : (
              <p>No Rain Expected in the next 6 hours</p>
            )}
          </>
        ) : (
          <div
            className="text-white text-center
          flex flex-col justify-center items-center h-full w-full
        "
          >
            <img src={NoRain} alt="no rain" className="object-contain w-1/2" />
            <p className="text-center text-4xl justify-start font-bold mt-5 text-blue-600">
              No Rain Expected
            </p>
          </div>
        )
      ) : (
        <div
          className="
            text-black text-center flex flex-col justify-center items-center h-full
          w-full
          "
        >
          <p className="text-center text-4xl justify-start font-bold mt-5 text-blue-600">
            No Weather Data Available
          </p>
          {user?.isStaff && (
            <MapComponent
              initialPosition={{
                lat: runData?.lat,
                lng: runData?.lng,
              }}
              updatePosition={updatePosition}
            />
          )}
        </div>
      )}
    </Loading>
  ) : (
    <div className="text-white text-2xl text-center">
      Weather Data is only available when live
    </div>
  );
};

export default Weather;
