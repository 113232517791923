/** @format */

import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { toCamelCase } from "./galaxieClient";

const WS_HOST =
  import.meta.env.VITE_LOCAL === "True"
    ? `ws://${import.meta.env.VITE_SERVER_HOST}:` + import.meta.env.VITE_SERVER_PORT
    : `wss://${import.meta.env.VITE_SERVER_HOST}`;

export const useWSData = (setWsData: Function, runID: string) => {
  const queryClient = useQueryClient();
  const { readyState } = useWebSocket(runID ? `${WS_HOST}/ws/runs/${runID}/` : null, {
    onMessage: event => {
      const data = toCamelCase(JSON.parse(event.data)) as {
        type: string;
        data: any;
      };
      if (data?.data?.length === 0) {
        console.log(`data empty ${data.type}`);
      } else {
        switch (data.type) {
          case "run_detail":
            queryClient.setQueryData(["run", runID], data.data);
            break;
          case "vehicle_list":
            queryClient.setQueryData(["run", runID, "vehicles"], data.data);
            break;
          case "driver_results":
            queryClient.setQueryData(["run", runID, "points"], data.data);
            break;
          case "pit_stops":
            queryClient.setQueryData(["run", runID, "pitstops"], data.data);
            break;
          case "vehicle_laps":
            queryClient.setQueryData(["run", runID, "vehicle_laps"], data.data);
            break;
          case "vehicle_details":
            queryClient.setQueryData(
              ["run", runID, "vehicles", data.data?.id],
              data.data
            );
            break;
          default:
            console.error(`Unknown type ${data.type}`);
            break;
        }
      }
    },
    shouldReconnect: closeEvent => true,
    onOpen: () => console.debug("ws opened"),
    onClose: () => console.debug("ws closed"),
    onError: () => console.warn("ws error"),
    reconnectInterval: 1000,
    reconnectAttempts: runID ? Infinity : 0,
  });
  useEffect(() => {
    setWsData((oldData: any) => ({
      readyState,
      messages: (oldData?.messages || 0) + 1,
    }));
  }, [readyState]); // eslint-disable-line react-hooks/exhaustive-deps
};
