/** @format */

export const rgbToStyle = (rgb: number[]) => `rgb(${rgb.join(",")})`;

export const hexToRgbaStyle = (hex: string, alpha: number) => {
  const rgb = hexToRgb(hex);
  return `rgba(${rgb.join(",")}, ${alpha})`;
};

export function getContrastColor(hexColor1: string, hexColor2: string) {
  // Convert hex colors to RGB values
  const rgbColor1 = hexToRgb(hexColor1);
  const rgbColor2 = hexToRgb(hexColor2);

  // Calculate contrast ratios for both colors
  const contrastRatio1 = calculateContrastRatio(rgbColor1, [0, 0, 0]);
  const contrastRatio2 = calculateContrastRatio(rgbColor2, [0, 0, 0]);

  // Compare contrast ratios and return the color with better contrast
  if (contrastRatio1 > contrastRatio2) {
    return hexColor1;
  } else {
    return hexColor2;
  }
}

export function getNonContrastColor(hexColor1: string, hexColor2: string) {
  // Convert hex colors to RGB values
  const contrastColor = getContrastColor(hexColor1, hexColor2);

  if (contrastColor === hexColor1) {
    return hexColor2;
  } else {
    return hexColor1;
  }
}

// Helper function to convert hex color to RGB
function hexToRgb(hex: string) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
}

// Helper function to calculate contrast ratio between two colors
function calculateContrastRatio(rgbColor1: number[], rgbColor2: number[]) {
  const L1 = calculateRelativeLuminance(rgbColor1);
  const L2 = calculateRelativeLuminance(rgbColor2);
  const contrastRatio = (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
  return contrastRatio;
}

// Helper function to calculate relative luminance
function calculateRelativeLuminance(rgbColor: number[]) {
  const [r, g, b] = rgbColor.map(value => {
    const sRGBValue = value / 255;
    if (sRGBValue <= 0.03928) {
      return sRGBValue / 12.92;
    } else {
      return Math.pow((sRGBValue + 0.055) / 1.055, 2.4);
    }
  });

  const relativeLuminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return relativeLuminance;
}
