/** @format */

import * as Sentry from "@sentry/react";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { invert } from "lodash";
import { usePostHog } from "posthog-js/react";
import { useMemo } from "react";
import { queryClient } from "../App";
import galaxieClient from "./galaxieClient";
import { useDriverIDtoVehicleID, useDriverIDtoVehicleNumber } from "./runsApi";

const useSetUserContext = () => {
  const posthog = usePostHog();
  return (user: User) => {
    posthog.identify(user.id, {
      email: user.email,
      name: user.fullName,
      isStaff: user.isStaff,
      isAnonymous: false,
    });
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.fullName,
      isStaff: user.isStaff,
      isAnonymous: false,
    });
  };
};

export const useLogin = () => {
  return (email: string, password: string, rememberMe: boolean) =>
    galaxieClient
      .post("/token/", { email, password })
      .then(({ data: { access, refresh, user } }) => {
        if (rememberMe) {
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
        }
        galaxieClient.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        return { user };
      });
};

export const logout = () =>
  galaxieClient.post("/auth/logout/").then(response => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    galaxieClient.defaults.headers.common["Authorization"] = null;
    queryClient.invalidateQueries({
      queryKey: ["user"],
    });
    return response.data;
  });

export const changePassword = (newPassword1: string, newPassword2: string) =>
  galaxieClient
    .post("/auth/password/change/", {
      newPassword1,
      newPassword2,
    })
    .then(response => response.data);

export const resetPassword = (email: string) =>
  galaxieClient
    .post("/auth/password/reset/", { email })
    .then(response => response.data);

export const resetPasswordConfirm = (
  uid: string,
  token: string,
  newPassword1: string,
  newPassword2: string
) =>
  galaxieClient
    .post("/auth/password/reset/confirm/", {
      uid,
      token,
      newPassword1,
      newPassword2,
    })
    .then(response => response.data);

export const verifyEmail = (key: string) =>
  galaxieClient
    .post("/auth/registration/verify-email/", { key })
    .then(response => response.data);

export const signUp = (
  email: string,
  password1: string,
  password2: string,
  fullName: string,
  shortName: string
) =>
  galaxieClient
    .post("/auth/registration/", { email, password1, password2, fullName, shortName })
    .then(({ data: user }: { data: User }) => {
      queryClient.setQueryData(["user"], user);
      return user;
    });

export const getUser = () =>
  galaxieClient.get("/auth/user/").then(response => response.data);

export function useUser<TQueryFnData extends any = User>(
  options = {}
): UseQueryResult<TQueryFnData, Error> {
  const setUserContext = useSetUserContext();
  return useQuery<User, Error, TQueryFnData>({
    queryKey: ["user"],
    queryFn: async () =>
      await getUser()
        .then(user => {
          if (user?.id) {
            setUserContext(user);
          }
          return user;
        })
        .catch(error => {
          console.error(error);
          return {};
        }),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60,
    ...options,
  });
}

export const useUpdateUser = () =>
  useMutation({
    mutationFn: async (data: User) => await updateUser(data).then(res => res.data),
    onMutate: async (data: User) => {
      const previousUser: User | undefined = queryClient.getQueryData(["user"]);
      if (!previousUser) {
        // Handle the case when previousUser is undefined
        // For example, you can assign a default value or throw an error
        throw new Error("Previous user is undefined.");
      }
      await queryClient.cancelQueries({ queryKey: ["user"] });
      queryClient.setQueryData(["user"], {
        ...previousUser,
        ...(data as unknown as object),
      });
      return { previousUser };
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["user"], data);
      return data;
    },
    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(["user"], context.previousUser);
        return context.previousUser;
      } else {
        return error;
      }
    },
  });

export const useSavedScannersForRun = (runID: string, options = {}) => {
  const { data: driverIDtoVehicleNumber = [], isSuccess } =
    useDriverIDtoVehicleNumber(runID);
  return useUser<string[]>({
    select: ({ savedScanners = [] }) =>
      savedScanners.map(scanner => driverIDtoVehicleNumber[scanner]),
    ...options,
    enabled: isSuccess,
  });
};

const useSaveScanners = () =>
  useMutation<string[], unknown, string[], { previousUser: User }>({
    mutationFn: async scannerIDs =>
      galaxieClient
        .patch(`/auth/user/`, { savedScanners: scannerIDs })
        .then(res => res.data),
    onMutate: async scannerIDs => {
      await queryClient.cancelQueries({ queryKey: ["user"] });
      const previousUser: User | undefined = queryClient.getQueryData(["user"]);
      if (!previousUser) {
        // Handle the case when previousUser is undefined
        // For example, you can assign a default value or throw an error
        throw new Error("Previous user is undefined.");
      }
      queryClient.setQueryData(["user"], {
        ...previousUser,
        savedScanners: scannerIDs,
      });
      return { previousUser };
    },
    onSuccess: data => {
      queryClient.setQueryData(["user"], data);
    },
    onError: (_error, _variables, context) => {
      if (context) {
        queryClient.setQueryData(["user"], context.previousUser);
      }
    },
  });

export const useAddSavedScanner = (runID: string) => {
  const { data: driverIDtoVehicleNumber } = useDriverIDtoVehicleNumber(runID);
  const vehicleNumberToDriverID = invert(driverIDtoVehicleNumber || {});
  const { data: { savedScanners = [] } = {} } = useUser();
  const { mutate: saveScanners } = useSaveScanners();
  return async (scannerID: string) => {
    const driverID = vehicleNumberToDriverID[scannerID];
    return saveScanners([...savedScanners, driverID]);
  };
};

export const useRemoveSavedScanner = (runID: string) => {
  const { data: driverIDtoVehicleNumber } = useDriverIDtoVehicleNumber(runID);
  const vehicleNumberToDriverID = invert(driverIDtoVehicleNumber || {});
  const { data: { savedScanners = [] } = {} } = useUser();
  const { mutate: saveScanners } = useSaveScanners();
  return async (scannerID: string) => {
    const driverID = vehicleNumberToDriverID[scannerID];
    return saveScanners(savedScanners.filter((id: string) => id !== driverID));
  };
};

export const useFavoriteVehicleIDsForRun = (runID: string, options = {}) => {
  const { data: driverIDtoVehicleID, isSuccess } = useDriverIDtoVehicleID(runID);
  return useUser<string[]>({
    select: ({ favoriteDrivers }: { favoriteDrivers: string[] }) => {
      if (!driverIDtoVehicleID || !favoriteDrivers) return [] as string[];
      const faves = favoriteDrivers
        ?.map(driverID => driverIDtoVehicleID?.[driverID])
        ?.filter(a => a);
      return faves;
    },
    ...options,
    enabled: isSuccess,
  });
};

const useSaveFavoriteDrivers = () =>
  useMutation({
    mutationFn: async (favoriteDriverIDs: string[]) =>
      galaxieClient
        .patch(`/auth/user/`, { favoriteDrivers: favoriteDriverIDs })
        .then(res => res.data),
    onMutate: async (favoriteDriverIDs: string[]) => {
      const previousUser: User | undefined = queryClient.getQueryData(["user"]);
      if (!previousUser) {
        // Handle the case when previousUser is undefined
        // For example, you can assign a default value or throw an error
        throw new Error("Previous user is undefined.");
      }
      await queryClient.cancelQueries({ queryKey: ["user"] });
      queryClient.setQueryData(["user"], {
        ...previousUser,
        favoriteDrivers: favoriteDriverIDs,
      });
      return { previousUser };
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["user"], data);
    },
    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(["user"], context.previousUser);
      }
    },
  });

export const useAddFavoriteDriver = (runID: string) => {
  const { data: driverIDtoVehicleID } = useDriverIDtoVehicleID(runID);
  const vehicleIDToDriverID = useMemo(
    () => invert(driverIDtoVehicleID || {}),
    [driverIDtoVehicleID]
  );
  const { data: { favoriteDrivers = [] } = {} } = useUser();
  const { mutate: saveFavoriteDrivers } = useSaveFavoriteDrivers();
  return async (favoriteVehicleID: string) => {
    const driverIDs = [...favoriteDrivers, vehicleIDToDriverID[favoriteVehicleID]];
    return saveFavoriteDrivers(driverIDs);
  };
};

export const useRemoveFavoriteDriver = (runID: string) => {
  const { data: driverIDtoVehicleID } = useDriverIDtoVehicleID(runID);
  const vehicleIDToDriverID = useMemo(
    () => invert(driverIDtoVehicleID || {}),
    [driverIDtoVehicleID]
  );
  const { data: { favoriteDrivers = [] } = {} } = useUser();
  const { mutate: saveFavoriteDrivers } = useSaveFavoriteDrivers();
  return async (favoriteVehicleID: string) => {
    const driverIDs = favoriteDrivers.filter(
      (driverId: string) => driverId !== vehicleIDToDriverID[favoriteVehicleID]
    );
    return saveFavoriteDrivers(driverIDs);
  };
};

export const useUpdateViews = () =>
  useMutation<RunView[], unknown, RunView[], { previousUser: User }>({
    mutationFn: async (views: RunView[]) =>
      await updateUser({ runViews: views }).then(res => res.data),
    onMutate: async (views: RunView[]) => {
      const previousUser: User | undefined = queryClient.getQueryData(["user"]);
      if (!previousUser) {
        // Handle the case when previousUser is undefined
        // For example, you can assign a default value or throw an error
        throw new Error("Previous user is undefined.");
      }
      await queryClient.cancelQueries({ queryKey: ["user"] });
      queryClient.setQueryData(["user"], {
        ...previousUser,
        runViews: views,
      });
      return { previousUser };
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["user"], data);
    },
    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(["user"], context.previousUser);
      }
    },
  });

export const useAddView = () => {
  const { data: { runViews = [] } = {} as User } = useUser();
  const { mutate: updateViews } = useUpdateViews();
  return async (view: RunView) => {
    const views = [...runViews, view];
    return updateViews(views);
  };
};

export const useUpdateView = () => {
  const { data: { runViews = [] } = {} as User } = useUser();
  const { mutate: updateViews } = useUpdateViews();
  return async (updateView: RunView) => {
    const views = runViews.map((existingView: RunView) =>
      existingView.id === updateView.id ? updateView : existingView
    );
    return updateViews(views);
  };
};

export const useRemoveView = () => {
  const { data: { runViews = [] } = {} } = useUser();
  const { mutate: updateViews } = useUpdateViews();
  return async (viewID: string) => {
    const views = runViews.filter((v: RunView) => v.id !== viewID);
    return updateViews(views);
  };
};

export const updateUser = (data: User) =>
  galaxieClient.patch(`/auth/user/`, data).then(response => {
    queryClient.setQueryData(["user"], response.data);
    return response.data;
  });
