/** @format */

import chevy from "../assets/logos/chevy.png";
import ford from "../assets/logos/ford.png";
import toyota from "../assets/logos/toyota.png";
import {
  CAUTION_FLAG,
  CHECKERED_FLAG,
  GREEN_FLAG,
  POST_RACE,
  PRE_RACE,
  RED_FLAG,
} from "./constants";

export const capFirst = (str: string) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const titleCase = (str: string) => str?.split(" ")?.map(capFirst)?.join(" ");

export function invertColor(hex: string | null, bw: boolean) {
  if (hex === null) {
    return "#ffffff";
  }
  if (hex?.indexOf("#") === 0) {
    hex = hex?.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex?.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex?.length !== 6) {
    return "#ffffff";
  }
  var r = parseInt(hex?.slice(0, 2), 16),
    g = parseInt(hex?.slice(2, 4), 16),
    b = parseInt(hex?.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components

  // pad each with zeros and return
  return (
    "#" +
    padZero((255 - r).toString(16)) +
    padZero((255 - g).toString(16)) +
    padZero((255 - b).toString(16))
  );
}

function padZero(str: string, len: number = 2) {
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export const median = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const seriesLookup = {
  1: "NASCAR Cup Series",
  series_1: "NASCAR Cup Series",
  2: "Xfinity Series",
  series_2: "Xfinity Series",
  3: "Craftsman Trucks Series",
  series_3: "Craftsman Trucks Series",
} as Record<string, string>;

export const getFlagIcon = (flagState: number): string | null => {
  switch (flagState) {
    case POST_RACE:
    case CHECKERED_FLAG: //checkerboard
      return "/checker.png";
    case RED_FLAG: //red
      return "/red.png";
    case CAUTION_FLAG: //yellow
      return "/yellow.png";
    case GREEN_FLAG: //green
      return "/green.png";
    default:
      return null;
  }
};

export const getLogo = (manufacturer: string | undefined): string | null => {
  switch (manufacturer) {
    case "Chevrolet":
    case "Chv":
      return chevy;
    case "Ford":
    case "Frd":
      return ford;
    case "Toyota":
    case "Tyt":
      return toyota;
    default:
      return null;
  }
};

const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });

const suffixes = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
  zero: "th",
} as Record<string, string>;

export const ordinal = (number: number) =>
  number + suffixes[english_ordinal_rules.select(number)];

export const mergeById = (a1: any[], a2: any[], id_var: string) =>
  a1.map(a_item => ({
    ...a2.find(b_item => b_item[id_var] === a_item[id_var] && b_item),
    ...a_item,
  }));

const optionsDefault = {
  padEnd: 0,
  padEndChar: "\u2007",
  padStart: 0,
  padStartChar: "\u2007",
  zero: "",
} as DefaultFormatOptions;

export const formatPlus = (
  number: number,
  fixed: number = 3,
  options: FormatOptions = optionsDefault
) => {
  const { padEnd, padEndChar, padStart, padStartChar, zero } = {
    ...optionsDefault,
    ...options,
  };
  switch (true) {
    case number === 0:
      return zero
        ?.padStart(padStart || 0, padStartChar || "\u2007")
        ?.padEnd(padEnd, padEndChar || "0");
    case !isNaN(number):
      return (number > 0 ? `+${number?.toFixed(fixed)}` : number?.toFixed(fixed))
        ?.padStart(padStart || 0, padStartChar || "\u2007")
        ?.padEnd(padEnd, padEndChar || "0");
    case number === undefined:
    case number === null:
    default:
      return zero || "-";
  }
};

export const arrOfArrsToObj = (arr: any[]) =>
  arr.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

export const searchParamsToObj = (searchParams: Record<string, any>) =>
  arrOfArrsToObj(Array.from(searchParams.entries()));

export const isInStandaloneMode = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  (window.navigator as any).standalone ||
  document.referrer.includes("android-app://");
