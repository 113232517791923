/** @format */

import { useMemo } from "react";
import Plot from "react-plotly.js";
import { useVehiclesList } from "../../../api/runsApi";
import Loading from "../../../utils/Loading";
import {
  getContrastColor,
  getNonContrastColor,
  hexToRgbaStyle,
} from "../../../utils/colorUtils";
import useLiveParams from "../../../utils/useLiveParams";
import { useFilterLaps } from "./FilterLaps";
import { Data } from "plotly.js";

const LapTimeRankViolinChart = () => {
  const { runID } = useLiveParams();
  const {
    data: vehiclesData = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID);
  const {
    response: { status: lapsListStatus, error: lapsListError },
    filteredLaps,
    showLegend,
  } = useFilterLaps();
  const data = useMemo(
    () => [
      ...vehiclesData.map(vehicle => ({
        key: vehicle.id,
        showlegend: showLegend,
        type: "violin",
        points: "all",
        side: "positive",
        pointpos: -0.5,
        marker: {
          color: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          size: 3,
          opacity: vehicle.status === 1 ? 1 : 0.5,
        },
        box: {
          visible: true,
          fillcolor: hexToRgbaStyle(
            getNonContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
            0.75
          ),
          width: 1,
        },
        spanmode: "hard",
        meanline: {
          visible: true,
        },
        line: {
          color: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          width: 2,
          opacity: vehicle.status === 1 ? 1 : 0.5,
        },
        fillcolor: hexToRgbaStyle(
          getNonContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          0.5
        ),
        hoverlabel: {
          bgcolor: hexToRgbaStyle(
            getNonContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
            0.75
          ),
          bordercolor: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          font: {
            color: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
          },
        },
        hoveron: "violins+points",
        fillopacity: 0.75,
        name: `<b>${vehicle.number}</b> ${vehicle.displayName}`,
        hovertemplate: `<b>${vehicle.number}</b> ${vehicle.displayName}<br><b>Lap Time Rank:</b> %{y}<extra></extra>`,
        x: filteredLaps
          .filter(lap => lap.vehicleId === vehicle.id)
          .filter(lap => lap.status === 1)
          .map(lap => lap.vehicleNumber),
        y: filteredLaps
          .filter(lap => lap.vehicleId === vehicle.id)
          .filter(lap => lap.status === 1)
          .map(lap => lap.lapTimeRank),
      })),
    ],
    [vehiclesData, filteredLaps, showLegend]
  ) as Data[];
  return (
    <Loading
      statuses={[lapsListStatus, vehiclesStatus]}
      errors={[lapsListError, vehiclesError]}
      className="h-full w-full flex flex-row relative"
      wrap
    >
      {filteredLaps.length > 0 ? (
        <Plot
          data={data}
          layout={{
            autosize: true,
            margin: {
              l: 40,
              r: 5,
              b: 5,
              t: 5,
              pad: 10,
            },
            modebar: {
              orientation: "v",
            },
            xaxis: {
              showgrid: false,
              showticklabels: true,
              zeroline: false,
              tickprefix: "# ",
              color: "grey",
              autotypenumbers: "strict",
              tickfont: {
                size: 10,
                color: "white",
              },
              rangemode: "nonnegative",
            },
            yaxis: {
              showgrid: true,
              showticklabels: true,
              zeroline: true,
              tickformat: "%M:%S",
              color: "gray",
              rangemode: "nonnegative",
              gridcolor: "rgba(255,255,255,0.5)",
            },
            // showlegend: false,
            paper_bgcolor: "rgba(0,0,0,255)",
            plot_bgcolor: "rgba(0,0,0,255)",
            yaxis2: {
              showgrid: false,
              showticklabels: false,
              zeroline: false,
              color: "gray",
              range: [0, 4],
              fixedrange: true,
            },
          }}
          frames={[]}
          config={{
            responsive: true,
            displaylogo: false,
          }}
          className="flex-grow"
        />
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <h1 className="text-2xl text-white">No laps found</h1>
        </div>
      )}
    </Loading>
  );
};
export default LapTimeRankViolinChart;
