/** @format */

import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useIsLiveRun, useRun } from "../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../api/userAPI";
import ErrorBoundary from "../../utils/ErrorBoundary";
import Loading from "../../utils/Loading";
import useLiveParams from "../../utils/useLiveParams";
import { getFlagIcon, searchParamsToObj } from "../../utils/utility";
import ConfigurableGridWrapper from "./grid/ConfigurableGridWrapper";

const Run = () => {
  const { runID } = useLiveParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const setDetailVehicleID = (detailVehicleID: string) =>
    setSearchParams(
      {
        ...searchParamsToObj(searchParams),
        detailVehicleID,
      },
      { replace: true }
    );
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
    isSuccess,
  } = useFavoriteVehicleIDsForRun(runID);
  useEffect(() => {
    if (isSuccess) {
      if (!detailVehicleID && favoriteVehicleIDs.length > 0) {
        setDetailVehicleID(favoriteVehicleIDs[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, favoriteVehicleIDs]);
  const isLive = useIsLiveRun(runID);
  const { data: runData } = useRun(runID);
  useEffect(() => {
    if (runData?.flag) {
      const favicon = document.getElementById("favicon") as HTMLLinkElement;
      favicon.href = getFlagIcon(runData?.flag) || "/logo.png";
    }
  }, [runData?.flag]);
  useEffect(() => {
    if (runData?.name) {
      if (isLive) {
        document.title = `${runData?.lapNumber}/${runData?.totalLaps} | ${runData?.name} | Galaxie`;
      } else {
        document.title = `${runData?.name} | Galaxie`;
      }
    }
  }, [runData, isLive]);
  return (
    <Loading statuses={[favoriteVehiclesStatus]} errors={[favoriteVehiclesError]}>
      <ConfigurableGridWrapper />
    </Loading>
  );
};

export default Run;
