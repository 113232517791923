/** @format */

import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useVersion } from "../api/versionAPI";

const UpdateToast = () => {
  const [timeout, setTimeOut] = useState<number>(); // this naming is a bit confusing
  const { webUpdate, serverUpdate, setIgnored } = useVersion();
  const update = () => {
    window.location.reload();
  };
  const ignore = () => {
    clearTimeout(timeout);
    setIgnored();
  };
  useEffect(() => {
    if (webUpdate) {
      setTimeOut(
        setTimeout(() => {
          update();
        }, 5500)
      );
    }
  }, [webUpdate]);
  return (
    <div className="fixed bottom-4 left-4 z-50  duration-500 ease-in-out flex flex-col justify-end">
      <Transition
        show={serverUpdate}
        enter="duration-500 ease-in-out transition-transform"
        enterFrom="translate-y-36"
        enterTo="translate-y-0"
        leave="duration-500 ease-in-out transition-transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-36"
      >
        <div className="h-30 w-80 bg-red-600 rounded-md px-3 py-2 mx-auto overflow-hidden shadow text-center animate-bounce">
          Update the Server!
        </div>
      </Transition>
      <Transition
        show={webUpdate}
        enter="duration-500 ease-in-out transition-transform"
        enterFrom="translate-y-36"
        enterTo="translate-y-0"
        leave="duration-500 ease-in-out transition-transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-36"
      >
        <div className="h-30 w-80 bg-gray-800 rounded-md px-3 py-2 mx-auto overflow-clip shadow mt-2">
          <div className="flex items-center justify-between">
            <p className="text-lg font-medium">New Update!</p>
          </div>
          <div className="flex items-center justify-between mt-2 w-full gap-x-2">
            <button
              type="button"
              onClick={ignore}
              className="text-gray-400 hover:text-gray-200 rounded bg-gray-700 px-2 py-1 text-sm"
            >
              Dismiss
            </button>
            <button
              type="button"
              onClick={update}
              className="w-full rounded animate-pulse px-2 py-1 text-sm relative"
            >
              Updating
              <div className="absolute top-0 left-0 h-full bg-green-600 rounded text-center px-2 fill-bar whitespace-nowrap text-gray-200 -z-10" />
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default UpdateToast;
