/** @format */

import { sortBy } from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import useMeasure from "react-use-measure";
import { useVehiclesList } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import FormatArrow from "../../../utils/FormatArrow";
import FormatDelta from "../../../utils/FormatDelta";
import Loading from "../../../utils/Loading";
import {
  FOUR_WHEEL,
  PIT_STOP_TYPES,
  TWO_WHEEL_LEFT,
  TWO_WHEEL_RIGHT,
} from "../../../utils/constants";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, getLogo, searchParamsToObj } from "../../../utils/utility";
import BarCell from "../../../utils/BarCell";

const columns = [
  {
    Header: "",
    id: "blank",
    columns: [
      {
        Header: "Pos",
        id: "position",
        filterable: false,
        accessor: "runningPosition",
        className: "text-right w-0 whitespace-nowrap",
        showGt: 0,
        Cell: ({
          value,
          row: {
            original: { positionChange, isOnTrack, status, isOnDvp, inPlayoffs },
          },
        }) => (
          <div
            className={`${
              isOnTrack
                ? isOnDvp
                  ? "bg-red-600 text-white"
                  : inPlayoffs
                  ? "text-yellow-500"
                  : ""
                : status === 1
                ? `bg-pink-500 ${isOnDvp ? "text-red-600" : "text-white"}`
                : "bg-gray-600 text-white"
            } w-full h-full`}
          >
            {value}
            {isOnTrack ? (
              isOnDvp ? (
                "  DVP"
              ) : (
                <FormatArrow className="inline-block">{positionChange}</FormatArrow>
              )
            ) : status === 1 ? (
              "  PIT"
            ) : status === 2 ? (
              "  BTW"
            ) : (
              "  OUT"
            )}
          </div>
        ),
      },
      {
        Header: "#",
        accessor: "number",
        className: "px-2 w-0",
        showGt: 0,
        Cell: ({
          value,
          row: {
            original: { primaryColor, secondaryColor },
          },
        }) => (
          <div
            className="w-4 h-4 leading-4 text-[.65rem] rounded border overflow-hidden font-sans text-center font-bold tracking-tighter align-middle"
            style={{
              color: secondaryColor,
              backgroundColor: primaryColor,
              borderColor: secondaryColor,
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Driver",
        accessor: "displayName",
        className: "text-left font-sans whitespace-nowrap truncate w-1/3 max-w-0 px-1",
        showGt: 0,
      },
      {
        Header: "Mnfr",
        accessor: "manufacturer",
        className: "hidden md:table-cell text-center w-0 px-2",
        showGt: 768,
        Cell: ({ value }) => (
          <img
            src={getLogo(value)}
            alt={value}
            className="object-contain object-center mx-auto filter"
            style={{
              height: "1rem",
            }}
          />
        ),
      },
      {
        Header: "Itvl",
        accessor: "delta",
        className: "text-center",
        showGt: 0,
        Cell: ({
          value,
          row: {
            original: { lapDown, lapsDown, runningPosition },
          },
        }) =>
          lapDown
            ? `-${lapsDown}L`
            : runningPosition === 1
            ? "Leader"
            : formatPlus(value, 2, {
                padStart: 6,
                padStartChar: "\u00A0",
                padEnd: 7,
                padEndChar: "\u00A0",
                zero: "-",
              }),
      },
      {
        Header: "Gap ↑",
        className: "text-center",
        accessor: "gapAhead",
        showGt: 200,
        Cell: ({ value }) => formatPlus(value, 2, { zero: "-" }),
      },
      {
        Header: "Gap ↓",
        className: "text-center",
        accessor: "gapBehind",
        showGt: 400,
        Cell: ({ value }) => formatPlus(value, 2, { zero: "-" }),
      },
    ],
  },
  {
    Header: "Pos. ∆",
    className: "text-center",
    columns: [
      {
        Header: "L",
        id: "lapPositionChange",
        filterable: false,
        accessor: "positionChange",
        className: "text-center",
        showGt: 1280,
        Cell: ({ value }) => <FormatArrow>{value}</FormatArrow>,
      },
      {
        Header: "P",
        id: "periodPositionChange",
        filterable: false,
        accessor: "periodPositionChange",
        className: "text-center",
        showGt: 640,
        Cell: ({ value }) => <FormatArrow>{value}</FormatArrow>,
      },
      {
        Header: "R",
        id: "racePositionChange",
        filterable: false,
        accessor: "racePositionChange",
        className: "text-center",
        showGt: 640,
        Cell: ({ value }) => <FormatArrow>{value}</FormatArrow>,
      },
    ],
  },
  {
    Header: "Timing",
    className: "text-center",
    columns: [
      {
        Header: "Last",
        accessor: "lapTime",
        id: "lastLap",
        className: "text-center",
        showGt: 200,
        Cell: ({
          value,
          row: {
            original: { personalBest, periodBest, status, lapBest, lapWorst },
          },
        }) =>
          status === 1 ? (
            <BarCell
              data={value}
              range={{
                min: lapBest,
                max: lapWorst,
              }}
              bgColor="bg-green-600"
            >
              <span
                className={`leading-none ${
                  value === periodBest
                    ? "text-purple-500"
                    : value === personalBest
                    ? "text-green-500"
                    : ""
                }`}
              >
                {value?.toFixed(2)?.padEnd(5, 0)}
              </span>
            </BarCell>
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: "Rank",
        accessor: "lapTimeRank",
        id: "lapTimeRank",
        className: "text-center sm:table-cell hidden",
        showGt: 640,
      },
      {
        Header: "∆ ↑",
        accessor: "deltaToAhead",
        className: "hidden sm:table-cell text-center",
        showGt: 640,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? <FormatDelta>{value}</FormatDelta> : "-"),
      },
      {
        Header: "∆ ↓",
        accessor: "deltaToBehind",
        className: "hidden sm:table-cell text-center",
        showGt: 640,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? <FormatDelta>{value}</FormatDelta> : "-"),
      },
      {
        Header: "Δ Best",
        accessor: "deltaToPeriodBest",
        className: "hidden sm:table-cell text-center",
        showGt: 640,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? formatPlus(value, 2) || "-" : "-"),
      },
    ],
  },
  {
    Header: "Pit Stops",
    className: "hidden xl:table-cell text-center",
    columns: [
      {
        Header: "Lap",
        id: "atLap",
        accessor: "lastStopLap",
        className: "hidden xl:table-cell text-center",
        showGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 && value) || "-",
      },
      {
        Header: "Type",
        id: "pitType",
        accessor: "lastStopType",
        className: "hidden xl:table-cell text-center",
        showGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 && PIT_STOP_TYPES?.[value]) || "-",
      },
      {
        Header: "Stint",
        id: "stintLength",
        accessor: "lapsSinceStop",
        className: "text-center",
        showGt: 0,
        hideGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status, lastStopType },
          },
        }) => (
          <span
            className={
              lastStopType === FOUR_WHEEL
                ? "font-bold"
                : lastStopType === TWO_WHEEL_LEFT || lastStopType === TWO_WHEEL_RIGHT
                ? "italic"
                : ""
            }
          >
            {status === 1 ? value : "-"}
          </span>
        ),
      },
      {
        Header: "Left",
        id: "lapsOnLeft",
        accessor: "lapsOnLeft",
        className: "text-center",
        showGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? value : "-"),
      },
      {
        Header: "Right",
        id: "lapsOnRight",
        accessor: "lapsOnRight",
        className: "text-center",
        showGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? value : "-"),
      },
      {
        Header: "Fuel",
        id: "fuelAdded",
        accessor: "lapsOnFuel",
        className: "text-center",
        showGt: 1280,
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (status === 1 ? value : "-"),
      },
    ],
  },
];

const RunTable = ({ columns: userColumns = columns }) => {
  const { runID } = useLiveParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const setDetailVehicleID = detailVehicleID =>
    setSearchParams(
      {
        ...searchParamsToObj(searchParams),
        detailVehicleID,
      },
      { replace: true }
    );
  const {
    data = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID);
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable({
    columns: userColumns,
    data: sortBy(data, "runningPosition"),
  });
  const [tableRef, { width }] = useMeasure();
  useEffect(() => {
    setHiddenColumns(
      userColumns
        .map(columnGroup =>
          columnGroup.columns
            .filter(
              ({ showGt = 0, hideGt = Infinity }) => width <= showGt || width >= hideGt
            )
            .map(({ id, accessor }) => id || accessor)
        )
        .flat()
    );
  }, [width, setHiddenColumns, userColumns]);
  return (
    <Loading
      statuses={[vehiclesStatus, favoriteVehiclesStatus]}
      errors={[vehiclesError, favoriteVehiclesError]}
      wrap={false}
    >
      <table
        ref={tableRef}
        {...getTableProps()}
        className="font-mono table-auto table-hover w-full border-collapse leading-tight border border-gray-500"
      >
        <thead className="table-head font-sans">
          {headerGroups.map((headerGroup, idx) => {
            return (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={
                  idx === 0
                    ? width > 680
                      ? headerGroup.className
                      : "hidden"
                    : headerGroup.className
                }
              >
                {headerGroup.headers.map((column, idx, arr) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${
                      column.className || ""
                    } bg-black sticky border-collapse ${
                      idx === 0
                        ? "border-r"
                        : idx === arr.length - 1
                        ? "border-l"
                        : "border-x"
                    } border-b border-gray-500 top-0 whitespace-nowrap truncate`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody className="min-h-full text-sm leading-tight" {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                data-ph-capture-attribute-vehicle-id={row.original.id}
                data-ph-capture-attribute-driver-name={row.original.displayName}
                data-ph-capture-attribute-vehicle-number={row.original.number}
                className={`${
                  favoriteVehicleIDs?.includes(row.original.id)
                    ? "bg-blue-700"
                    : " even:bg-black odd:bg-gray-900"
                }
                ${row.original.id === detailVehicleID && "border-2 border-blue-700"}
                  hover:bg-gray-800 hover:brightness-150 cursor-pointer transition-colors duration-200 ease-in-out whitespace-nowrap`}
                onClick={() => setDetailVehicleID(row.original.id)}
                style={{
                  backgroundColor: favoriteVehicleIDs?.includes(row.original.id)
                    ? row.original.primaryColor
                    : "",
                  color: favoriteVehicleIDs?.includes(row.original.id)
                    ? row.original.secondaryColor
                    : "",
                  borderColor: row.original.secondaryColor,
                }}
              >
                {row.cells.map((cell, idx, arr) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`border-collapse ${
                        idx === 0
                          ? "border-r"
                          : idx === arr.length - 1
                          ? "border-l"
                          : "border-x"
                      } border-gray-500 ${cell.column.className}`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Loading>
  );
};

export default RunTable;
