/** @format */

// FLAG_STATES
export const CHECKERED_FLAG = 9;
export const PRE_RACE = 8;
export const POST_RACE = 4;
export const RED_FLAG = 3;
export const CAUTION_FLAG = 2;
export const GREEN_FLAG = 1;
export const FLAGS = {
  [RED_FLAG]: "Red Flag",
  [CAUTION_FLAG]: "Caution Flag",
  [GREEN_FLAG]: "Green Flag",
  [CHECKERED_FLAG]: "Checkered Flag",
  [POST_RACE]: "Checkered Flag",
} as Record<number, string>;

// RUN_TYPES
export const PLACEHOLDER = -1;
export const PRACTICE = 1;
export const QUALIFYING = 2;
export const RACE = 3;
export const RUN_TYPES = {
  [PLACEHOLDER]: "Placeholder",
  [PRACTICE]: "Practice",
  [QUALIFYING]: "Qualifying",
  [RACE]: "Race",
} as Record<number, string>;

// PIT_STOP_TYPES
export const FOUR_WHEEL = "FOUR_WHEEL_CHANGE";
export const TWO_WHEEL_LEFT = "TWO_WHEEL_CHANGE_LEFT";
export const TWO_WHEEL_RIGHT = "TWO_WHEEL_CHANGE_RIGHT";
export const ONE_WHEEL = "ONE_WHEEL_CHANGE";
export const OTHER = "OTHER";
export const BLANK = "";
export const PIT_STOP_TYPES = {
  [TWO_WHEEL_LEFT]: "2L",
  [TWO_WHEEL_RIGHT]: "2R",
  [ONE_WHEEL]: "1T",
  [FOUR_WHEEL]: "4T",
  [OTHER]: "OTH",
  [BLANK]: "-",
} as Record<string, string>;

// TRACK_TYPES
export const SUPER_SPEEDWAY = "ss";
export const INTERMEDIATE = "is";
export const SHORT_TRACK = "st";
export const ROAD_COURSE = "rc";
export const STREET_CIRCUIT = "sc";
export const DIRT = "d";
export const UNKNOWN = "u";

export const TRACK_TYPES = {
  [SUPER_SPEEDWAY]: "Super Speedway",
  [INTERMEDIATE]: "Intermediate",
  [SHORT_TRACK]: "Short Track",
  [ROAD_COURSE]: "Road Course",
  [STREET_CIRCUIT]: "Street Circuit",
  [DIRT]: "Dirt",
  [UNKNOWN]: "Unknown",
} as Record<string, string>;
