/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/sharp-regular-svg-icons";
import { faSortDown, faSortUp } from "@fortawesome/sharp-solid-svg-icons";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { usePoints } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import FormatArrow from "../../../utils/FormatArrow";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";

const columnHelper = createColumnHelper<RunPoints>();

const columns = [
  columnHelper.accessor("number", {
    header: "#",
    cell: ({ row: { original: point } }) => (
      <div
        className="w-4 h-4 leading-4 text-[.65rem] rounded border overflow-hidden font-sans text-center font-bold tracking-tighter align-middle mx-auto"
        style={{
          color: point.secondaryColor,
          backgroundColor: point.primaryColor,
          borderColor: point.secondaryColor,
        }}
      >
        {point.number}
      </div>
    ),
  }),
  columnHelper.accessor("driver.displayName", {
    header: "Driver",
    cell: ({ row: { original: point } }) => (
      <div
        className={`pl-1 truncate whitespace-nowrap px-1 ${
          point.clinched
            ? "text-yellow-500"
            : point.projectedIn
            ? "text-green-500"
            : point.isInRound && !point.projectedIn
            ? "text-red-500"
            : "text-gray-400"
        }`}
      >
        {point.driver.displayName}
      </div>
    ),
  }),
  columnHelper.accessor("currentPoints", {
    header: "cPt",
    cell: ({ row: { original: point } }) => (
      <div className="text-right">
        {point.currentPoints.toFixed(0).padStart(4, "\u00A0")}
      </div>
    ),
  }),
  columnHelper.accessor("pointsEarned", {
    header: "+Pt",
    cell: ({ row: { original: point } }) => (
      <div className="text-center">
        +{point.pointsEarned.toFixed(0).padStart(2, "\u00A0")}
      </div>
    ),
  }),
  columnHelper.accessor("projectedPoints", {
    header: "pPt",
    cell: ({ row: { original: point } }) => (
      <div className="text-left">
        {point.projectedPoints.toFixed(0).padStart(4, "\u00A0")}
      </div>
    ),
  }),
  columnHelper.accessor("currentPosition", {
    header: "cPos",
    cell: props => (
      <div className="text-right">
        {props.getValue().toFixed(0).padStart(2, "\u00A0")}
      </div>
    ),
  }),
  columnHelper.accessor(
    (point: RunPoints) => point.projectedPosition - point.currentPosition,
    {
      header: "∆Pos",
      cell: ({ getValue }) => (
        <FormatArrow showEq className="text-center">
          {getValue()}
        </FormatArrow>
      ),
    }
  ),
  columnHelper.accessor("projectedPosition", {
    header: "pPos",
    cell: ({ row: { original: point } }) => (
      <div className="text-left">
        {point.projectedPosition.toFixed(0).padStart(2, "\u00A0")}
      </div>
    ),
  }),
  columnHelper.accessor("cutoffDelta", {
    header: "Cut",
    sortingFn: ({ original: aPoint }, { original: bPoint }) =>
      aPoint.winsInRound - bPoint.winsInRound ||
      bPoint.cutoffDelta - aPoint.cutoffDelta,
    cell: ({ row: { original: point } }) =>
      point.clinched ? (
        <span
          className={
            point.isLeader
              ? "text-purple-500 whitespace-nowrap"
              : "text-yellow-500 whitespace-nowrap"
          }
        >
          {point.winsInRound} Win{point.winsInRound > 1 ? "s" : ""}
        </span>
      ) : point?.isLeader ? (
        <span className="text-purple-500">Leader</span>
      ) : (
        <FormatArrow showEq>{point.cutoffDelta}</FormatArrow>
      ),
  }),
  columnHelper.accessor("projectedPlayoffPoints", {
    header: "pPP",
    cell: ({ row: { original: point } }) => (
      <div className="text-left">
        {point.projectedPlayoffPoints?.toFixed(0)?.padStart(4, "\u00A0")}
      </div>
    ),
  }),
];

const PointsTable: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const {
    data: points = [] as RunPoints[],
    error: pointsError,
    status: pointsStatus,
  } = usePoints(runID);
  const table = useReactTable({
    columns,
    data: points,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //provide a sorting row model
    initialState: {
      sorting: [{ id: "projectedPosition", desc: false }],
    },
  });
  return (
    <Loading
      statuses={[favoriteVehiclesStatus, pointsStatus]}
      errors={[favoriteVehiclesError, pointsError]}
    >
      <table className="w-full font-mono text-sm overflow-x-auto leading-tight">
        <thead className="sticky text-base top-0 bg-black">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th className="border-x whitespace-nowrap" key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.getCanSort() ? "cursor-pointer select-none" : ""
                      }
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <button onClick={header.column.getToggleSortingHandler()}>
                        {"\u00A0"}
                        {{
                          asc: <FontAwesomeIcon icon={faSortUp} />,
                          desc: <FontAwesomeIcon icon={faSortDown} />,
                        }[header.column.getIsSorted() as string] ??
                          (header.column.getCanSort() && (
                            <FontAwesomeIcon icon={faSort} />
                          ))}
                      </button>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              className={`even:bg-black odd:bg-gray-900
              ${favoriteVehicleIDs?.includes(row.original.vehicleId) ? "border" : ""}
                hover:bg-gray-800 hover:brightness-150 transition-colors duration-200 ease-in-out
            `}
              style={{
                borderColor: favoriteVehicleIDs?.includes(row.original.vehicleId)
                  ? row.original.primaryColor
                  : "",
              }}
            >
              {row.getVisibleCells().map(cell => (
                <td className="px-1 border-x border-gray-500" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </Loading>
  );
};

export default PointsTable;
