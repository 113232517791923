/** @format */

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faDesktop,
  faLaptop,
  faTabletAlt,
  faTablet,
  faMobileAlt,
  faPhone,
  faRectangleWide,
} from "@fortawesome/pro-duotone-svg-icons";

export const stockIcons = {
  ultrawide: faRectangleWide,
  desktop: faDesktop,
  laptop: faLaptop,
  iPadPro: faTabletAlt,
  iPad: faTablet,
  iPhone: faMobileAlt,
  phone: faPhone,
} as Record<string, any>;

library.add(...Object.values(stockIcons));
