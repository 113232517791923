/** @format */

import { memo } from "react";
import Plot from "react-plotly.js";
import { useSearchParams } from "react-router-dom";
import { useVehiclesList } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import Loading from "../../../utils/Loading";
import useLiveParams from "../../../utils/useLiveParams";
import { formatPlus, ordinal } from "../../../utils/utility";

const CircleMap: React.FC<WidgetProps> = () => {
  const { runID } = useLiveParams();
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const {
    data: vehiclesData,
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID, {
    select: (vehicles: VehicleList[]) =>
      vehicles.filter(vehicle => vehicle.status === 1).reverse(),
  });
  const {
    data: favoriteVehicleIDs,
    status: favoriteVehiclesStatus,
    error: favoriteVehiclesError,
  } = useFavoriteVehicleIDsForRun(runID);
  const leaderLapTime = vehiclesData?.[0]?.lapTime || 1;
  return (
    <Loading
      statuses={[vehiclesStatus, favoriteVehiclesStatus]}
      errors={[vehiclesError, favoriteVehiclesError]}
      className="h-full w-full flex flex-col"
      wrap
    >
      <Plot
        className="flex-grow"
        data={[
          {
            r: vehiclesData?.map(vehicle => (vehicle.lapsDown === 0 ? 1 : 0.9)),
            theta: vehiclesData?.map(
              vehicle => -(vehicle.trueDelta / leaderLapTime) * 360 + 90 // 90 is to start at 12 o'clock #ToDo - switch to delta
            ),
            type: "scatterpolar",
            mode: "text+markers",
            text: vehiclesData?.map(vehicle => vehicle.number),
            marker: {
              symbol: vehiclesData?.map(vehicle =>
                vehicle.id === detailVehicleID
                  ? "diamond"
                  : favoriteVehicleIDs?.includes(vehicle.id)
                  ? "square"
                  : "circle"
              ),
              color: vehiclesData?.map(vehicle => vehicle.primaryColor),
              size: 15,
              line: {
                color: vehiclesData?.map(vehicle => vehicle.secondaryColor),
                width: 1,
              },
            },
            textposition: "middle center",
            textfont: {
              color: vehiclesData?.map(vehicle => vehicle.secondaryColor),
              size: 10,
            },
            hovertemplate: vehiclesData?.map(vehicle =>
              [
                `<b>#${vehicle.number}</b> ${vehicle.displayName} <i>${ordinal(
                  vehicle.runningPosition
                )}</i>`,
                vehicle.lapDown
                  ? `<b>Laps Down:</b> ${vehicle.lapsDown}`
                  : vehicle.delta === 0
                  ? "Leader"
                  : `<b>Delta:</b> +${vehicle.delta}s`,
                vehicle.delta > 0 && `<b>Gap:</b> ${formatPlus(vehicle.gapAhead)}s`,
                `<b>Lap Time:</b> ${vehicle.lapTime}`,
                "<extra></extra>",
              ]
                .filter(l => l)
                .join("<br>")
            ),
          },
        ]}
        layout={{
          polar: {
            bgcolor: "black",
            radialaxis: {
              range: [0, 1],
              visible: false,
              showgrid: false,
              showticklabels: false,
            },
            angularaxis: {
              visible: true,
              showgrid: false,
              showticklabels: false,
              ticks: "",
              linewidth: 5,
              layer: "below traces",
              color: "white",
            },
          },
          margin: { l: 10, r: 10, t: 10, b: 10 },
          autosize: true,
          showlegend: false,
          orientation: 0,
          paper_bgcolor: "rgba(0,0,0,0)",
        }}
        config={{
          displayModeBar: false,
          responsive: true,
        }}
      />
    </Loading>
  );
};

export default memo(CircleMap);
