/** @format */

import { useQuery } from "@tanstack/react-query";
import { useVehiclesList } from "./runsApi";

export const useAudioMapping = (seriesID: number, { enabled = true, ...options }) => {
  return useQuery<AudioStream[]>({
    queryKey: ["audioMapping", seriesID],
    queryFn: async () => {
      const response = await fetch(
        `https://cf.nascar.com/config/audio/audio_mapping_${seriesID}_3.json`
      );
      const data = await response.json();
      return (
        data?.audio_config.filter((stream: AudioStream) => {
          switch (true) {
            case ["All Scan", "Playoff Drivers", "Championship 4 Drivers"].includes(
              stream.driver_name
            ):
              return false;
            default:
              return true;
          }
        }) || []
      );
    },
    enabled: seriesID !== 0 && enabled,
    placeholderData: [] as AudioStream[],
    ...options,
  });
};

export const useVideoMapping = (seriesID: number, runID: string) => {
  const { data: vehicles } = useVehiclesList(runID);
  return useQuery<VideoStream[]>({
    queryKey: ["videoMapping", seriesID],
    queryFn: async () => {
      const response = await fetch(
        `https://cf.nascar.com/drive/${seriesID}/configs-ng.json`
      );
      const data = await response.json();
      return [
        ...(data?.data
          ?.find((component: VideoComponent) => component?.componentName === "fixed")
          .videos.map((fixed: VideoStream) => ({ ...fixed, driverID: fixed.title })) ||
          []),
        ...(data?.data?.find(
          (component: VideoComponent) => component?.componentName === "driver"
        ).videos || []),
      ].map(stream => {
        const vehicle = vehicles?.find(
          ({ driverNascarId }) => driverNascarId === stream.driverID
        );
        if (vehicle) {
          return {
            ...stream,
            title: `#${vehicle.number} ${vehicle.displayName}`,
            primaryColor: vehicle.primaryColor,
            secondaryColor: vehicle.secondaryColor,
            saveId: vehicle.driverId,
            vehicleId: vehicle.id,
            position: vehicle.runningPosition,
          };
        } else {
          return {
            ...stream,
            primaryColor: "#ffffff",
            secondaryColor: "#000000",
            saveId: `NV:${stream.driverID}`,
            vehicleId: null,
            position: null,
          };
        }
      });
    },
    enabled: seriesID !== 0 && !!vehicles,
    refetchInterval: 1000 * 60 * 10,
    placeholderData: [] as VideoStream[],
  });
};
