/** @format */

import {
  faArrowDown,
  faArrowUp,
  faGreaterThan,
  faLessThan,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/sharp-regular-svg-icons";
import { Disclosure } from "@headlessui/react";
import { ChangeEvent, useContext, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useRun, useVehicleLapsList, useVehiclesList } from "../../../api/runsApi";
import { useFavoriteVehicleIDsForRun } from "../../../api/userAPI";
import Checkbox from "../../../utils/Checkbox";
import Loading from "../../../utils/Loading";
import { getContrastColor } from "../../../utils/colorUtils";
import { GREEN_FLAG } from "../../../utils/constants";
import useLiveParams from "../../../utils/useLiveParams";
import DetailDriverSelector from "../../../utils/DetailDriverSelector";
import { GridContext } from "../WidgetWrapper";

export const useFilterLaps = () => {
  const {
    componentState: {
      activeStages,
      selectedLapsFilter,
      timeRange,
      lapOffset,
      lapFrom,
      filterInPit,
      filterInLap,
      filterOutLap,
      filterCaution,
      filterRestarts,
      filterImputed,
      selectedVehiclesFilter,
      detailOffset,
      manualVehicles,
      showLegend,
    },
  }: { componentState: ComponentState } = useContext(GridContext);
  const { runID } = useLiveParams();
  const [searchParams] = useSearchParams();
  const detailVehicleID = searchParams.get("detailVehicleID");
  const { data: favoriteVehicleIDs } = useFavoriteVehicleIDsForRun(runID);
  const {
    data: { stages, lapNumber, flagPeriods: rawPeriods } = {
      stages: [],
      lapNumber: 0,
      flagPeriods: [],
    },
  } = useRun(runID);
  const { data: vehicles = [] } = useVehiclesList(runID);
  const lapRange = useMemo(() => {
    const flagPeriods = rawPeriods?.filter(({ flag }) => [1, 2].includes(flag)) ?? [];
    const greenPeriods = flagPeriods?.filter(
      flagPeriod => flagPeriod.flag === GREEN_FLAG
    );
    const lastGreen = greenPeriods?.[greenPeriods?.length - 1];
    const curPeriod = flagPeriods?.[flagPeriods?.length - 1];
    switch (selectedLapsFilter) {
      case "all":
        return { min: 1, max: lapNumber };
      case "stage":
        const astg = stages?.filter(stage => activeStages.includes(stage.name)) ?? [];
        const min = Math.min(...astg.map(stage => stage.start));
        const max = Math.max(...astg.map(stage => stage.end));
        return { min, max };
      case "smart":
        return {
          min:
            lapNumber -
            (flagPeriods.length <= 1
              ? lapNumber + 1
              : curPeriod?.length < 10
              ? Math.max(lapNumber - lastGreen?.start + 1, 10)
              : curPeriod?.length - 1),
          max: lapNumber,
        };
      case "curPeriod":
        return { min: curPeriod?.start, max: lapNumber };
      case "lastGreen":
        return { min: lastGreen?.start, max: lapNumber };
      case "last":
        return { min: lapNumber - lapOffset, max: lapNumber };
      case "from":
        return { min: lapFrom, max: lapNumber };
      default:
        return { min: 1, max: lapNumber };
    }
  }, [
    stages,
    activeStages,
    lapNumber,
    lapOffset,
    selectedLapsFilter,
    rawPeriods,
    lapFrom,
  ]);
  const filteredVehicles = useMemo(() => {
    switch (selectedVehiclesFilter) {
      case "manual":
        return manualVehicles;
      case "favorites":
        return favoriteVehicleIDs;
      case "detailOffset":
        if (detailVehicleID) {
          const detailVehicle = vehicles.find(v => v.id === detailVehicleID);
          if (!detailVehicle) {
            return [];
          }
          const lowerBound = detailVehicle.runningPosition - detailOffset.ahead;
          const upperBound = detailVehicle.runningPosition + detailOffset.behind;
          return vehicles
            .filter(
              v => lowerBound <= v.runningPosition && v.runningPosition <= upperBound
            )
            .map(v => v.id);
        } else {
          return [];
        }
      case "detailPerLap":
      case "all":
      default:
        return vehicles.map(v => v.id);
    }
  }, [
    detailVehicleID,
    vehicles,
    detailOffset,
    selectedVehiclesFilter,
    manualVehicles,
    favoriteVehicleIDs,
  ]);
  const { data: vehicleLaps, ...rest } = useVehicleLapsList(runID);
  const filteredLaps = useMemo(
    () =>
      (vehicleLaps || [])
        .filter(lap => filteredVehicles?.includes(lap.vehicleId))
        .filter(lap => {
          if (selectedVehiclesFilter === "detailPerLap") {
            const detailVehicleLap =
              vehicleLaps?.find(
                l => l.vehicleId === detailVehicleID && l.lapNumber === lap.lapNumber
              )?.runningPosition ?? 0;
            const lowerBound = detailVehicleLap - detailOffset.ahead;
            const upperBound = detailVehicleLap + detailOffset.behind;
            return (
              lowerBound <= lap.runningPosition && lap.runningPosition <= upperBound
            );
          } else {
            return true;
          }
        })
        .filter(lap => (filterImputed ? !lap.imputed : true))
        .filter(lap => (filterInPit ? !lap.inPit : true))
        .filter(lap => (filterInLap ? !lap.inLap : true))
        .filter(lap => (filterOutLap ? !lap.outLap : true))
        .filter(lap => (filterCaution ? lap.flag !== 2 : true))
        .filter(lap => (filterRestarts ? !lap.flagChanged : true))
        .filter(lap => lap.lapNumber >= lapRange.min && lap.lapNumber <= lapRange.max)
        .filter(lap => timeRange.min <= lap.lapTime && lap.lapTime <= timeRange.max),
    [
      vehicleLaps,
      filteredVehicles,
      selectedVehiclesFilter,
      detailVehicleID,
      detailOffset,
      filterImputed,
      filterInPit,
      filterInLap,
      filterOutLap,
      filterCaution,
      filterRestarts,
      lapRange.min,
      lapRange.max,
      timeRange.min,
      timeRange.max,
    ]
  );
  return {
    response: { data: vehicleLaps, ...rest },
    lapRange,
    filteredVehicles,
    showLegend,
    filteredLaps,
  };
};

const FilterLaps: React.FC<WidgetProps> = ({ detailDriver = false }) => {
  const { runID } = useLiveParams();
  const {
    componentState: {
      activeStages,
      selectedLapsFilter,
      timeRange,
      lapOffset,
      lapFrom,
      filterInPit,
      filterInLap,
      filterOutLap,
      filterCaution,
      filterRestarts,
      filterImputed,
      selectedVehiclesFilter,
      detailOffset,
      manualVehicles,
      showLegend,
    },
    setComponentState,
  }: {
    componentState: ComponentState;
    setComponentState: React.Dispatch<React.SetStateAction<ComponentState>>;
  } = useContext(GridContext);
  const setSelectedLaps = (selectedLapsFilter: string) => {
    setComponentState(prev => ({
      ...prev,
      selectedLapsFilter,
    }));
  };
  const setActiveStages = (activeStages: string[]) => {
    setComponentState(prev => ({
      ...prev,
      activeStages,
    }));
  };
  const setTimeRange = (timeRange: { min: number; max: number }) => {
    setComponentState(prev => ({
      ...prev,
      timeRange,
    }));
  };
  const setLapOffset = (lapOffset: number) => {
    setComponentState(prev => ({
      ...prev,
      lapOffset,
    }));
  };
  const setLapFrom = (lapFrom: number) => {
    setComponentState(prev => ({
      ...prev,
      lapFrom,
    }));
  };
  const setFilterImputed = (filterImputed: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterImputed,
    }));
  };
  const setFilterInPit = (filterInPit: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterInPit,
    }));
  };
  const setFilterInLap = (filterInLap: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterInLap,
    }));
  };
  const setFilterOutLap = (filterOutLap: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterOutLap,
    }));
  };
  const setFilterCaution = (filterCaution: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterCaution,
    }));
  };
  const setFilterRestarts = (filterRestarts: boolean) => {
    setComponentState(prev => ({
      ...prev,
      filterRestarts,
    }));
  };
  const setDetailOffset = (detailOffset: { ahead: number; behind: number }) => {
    setComponentState(prev => ({
      ...prev,
      detailOffset,
    }));
  };
  const setFilterType = (selectedVehiclesFilter: string) => {
    setComponentState(prev => ({
      ...prev,
      selectedVehiclesFilter,
    }));
  };
  const setSelectedVehicles = (manualVehicles: string[]) => {
    setComponentState(prev => ({
      ...prev,
      manualVehicles,
    }));
  };
  const setShowLegend = (showLegend: boolean) => {
    setComponentState(prev => ({
      ...prev,
      showLegend,
    }));
  };
  const {
    data: vehiclesData = [],
    status: vehiclesStatus,
    error: vehiclesError,
  } = useVehiclesList(runID);
  const {
    data: { stages } = { stages: [], lapNumber: 0 },
    status: runStatus,
    error: runError,
    isSuccess,
  } = useRun(runID);
  useEffect(() => {
    if (isSuccess) {
      if (!activeStages) {
        setActiveStages(stages?.map(stage => stage.name) ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const {
    data: laps = [],
    status: lapsListStatus,
    error: lapsListError,
  } = useVehicleLapsList(runID, {});
  useEffect(() => {
    const min = Math.floor(
      Math.min(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime))
    );
    const max = Math.ceil(
      Math.max(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime))
    );
    if (laps.length > 0 && timeRange.min === -Infinity && timeRange.max === Infinity) {
      setTimeRange({ min, max });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laps]);
  const baseTimeRange = useMemo(() => {
    if (laps && laps.length > 0) {
      return {
        min: Math.floor(
          Math.min(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime))
        ),
        max: Math.ceil(
          Math.max(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime))
        ),
      };
    }
    return { min: 0, max: Infinity };
  }, [laps]);
  const minLapTime = useMemo(() => {
    if (laps && laps.length > 0) {
      return Math.min(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime));
    } else {
      return 0;
    }
  }, [laps]);
  const maxLapTime = useMemo(() => {
    if (laps && laps.length > 0) {
      return Math.max(...laps.filter(lap => !lap.slowLap).map(lap => lap.lapTime));
    } else {
      return 0;
    }
  }, [laps]);

  const { filteredVehicles } = useFilterLaps();
  return (
    <Loading
      statuses={[vehiclesStatus, runStatus, lapsListStatus]}
      errors={[vehiclesError, runError, lapsListError]}
      className="w-full h-full flex divide-gray-500 divide flex-col divide-y"
      wrap
    >
      <h3 className="text-left font-bold mb-1 text-lg sticky top-0 bg-black mx-1">
        Filter Laps
      </h3>
      {detailDriver && (
        <div className="p-1">
          <div className="font-bold mb-1">Selected Driver</div>
          <div className="flex flex-col gap-y-1">
            <DetailDriverSelector />
          </div>
        </div>
      )}
      <div className="p-1">
        <div className="font-bold mb-1">Laps</div>
        <div className="flex gap-1 flex-wrap">
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "all"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("all");
            }}
          >
            All
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "stage"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("stage");
            }}
          >
            Stage
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "smart"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("smart");
            }}
          >
            Smart
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "curPeriod"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("curPeriod");
            }}
          >
            Cur. Flag
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "lastGreen"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("lastGreen");
            }}
          >
            Last Green
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "last"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("last");
            }}
          >
            Last
            <input
              title="Lap Offset"
              type="text"
              className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[3ch] text-right inline-block ml-1"
              value={lapOffset}
              onClick={e => e.stopPropagation()}
              onChange={e => setLapOffset(parseInt(e.target.value))}
            />
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedLapsFilter === "from"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedLaps("from");
            }}
          >
            From
            <input
              title="Lap From"
              type="text"
              className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[3ch] text-right inline-block ml-1"
              value={lapFrom}
              onClick={e => e.stopPropagation()}
              onChange={e => setLapFrom(parseInt(e.target.value))}
            />
          </button>
        </div>
      </div>
      {selectedLapsFilter === "stage" && (
        <div className="p-1">
          <div className="font-bold mb-1">Stages</div>
          <div className="flex gap-1">
            {stages?.map(stage => (
              <button
                key={stage.name}
                className={` text-white font-bold rounded grow ${
                  activeStages?.includes(stage.name)
                    ? "bg-gray-600 hover:bg-gray-500"
                    : "bg-gray-800 hover:bg-gray-700"
                }`}
                onClick={() => {
                  if (activeStages?.includes(stage.name)) {
                    setActiveStages(activeStages.filter(s => s !== stage.name));
                  } else {
                    setActiveStages([...(activeStages || []), stage.name]);
                  }
                }}
              >
                {stage.name.replace("Stage ", "").replace("Overtime", "OT")}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="p-1">
        <div className="font-bold mb-1">Time</div>
        <div className="flex flex-col gap-y-1">
          <div className="flex align-middle">
            <FontAwesomeIcon icon={faGreaterThan} className="mr-1" fixedWidth />
            <input
              title="Min Lap Time"
              type="range"
              min={minLapTime}
              max={timeRange.max || baseTimeRange.max}
              step="0.1"
              className="w-full"
              value={timeRange.min || baseTimeRange.min}
              onChange={e => {
                setTimeRange({
                  ...timeRange,
                  min: parseInt(e.target.value),
                });
              }}
            />
            <input
              title="Min Lap Time"
              type="text"
              className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[4ch] text-right inline-block mx-1"
              value={timeRange.min || baseTimeRange.min}
              onChange={e =>
                setTimeRange({
                  ...timeRange,
                  min: parseInt(e.target.innerText),
                })
              }
            />
          </div>
          <div className="flex align-middle">
            <FontAwesomeIcon icon={faLessThan} className="mr-1" fixedWidth />
            <input
              title="Max Lap Time"
              type="range"
              min={timeRange.min || baseTimeRange.min}
              max={maxLapTime}
              step="0.1"
              className="w-full"
              value={timeRange.max || baseTimeRange.max}
              onChange={e => {
                setTimeRange({
                  ...timeRange,
                  max: parseInt(e.target.value),
                });
              }}
            />
            <input
              title="Max Lap Time"
              type="text"
              className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[4ch] text-right inline-block mx-1"
              value={timeRange.max || baseTimeRange.max}
              onChange={e =>
                setTimeRange({
                  ...timeRange,
                  max: parseInt(e.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="p-1">
        <div className="font-bold mb-1">
          Exclude Imputed
          <input
            title="Exclude Imputed Laps"
            type="checkbox"
            className="ml-1 rounded"
            checked={filterImputed}
            onChange={e => setFilterImputed(e.target.checked)}
          />
        </div>
      </div>
      <div className="p-1">
        <div className="font-bold mb-1">
          Show Legend
          <input
            title="Show Legend"
            type="checkbox"
            className="ml-1 rounded"
            checked={showLegend}
            onChange={e => setShowLegend(e.target.checked)}
          />
        </div>
      </div>
      <Disclosure className="p-1" as="div">
        <Disclosure.Button className="font-bold mb-1">
          {({ open }) => (
            <>
              {open ? (
                <FontAwesomeIcon icon={faCaretDown} className="mr-1" />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} className="mr-1" />
              )}
              Exclude Slow
              <Checkbox
                className="ml-1 rounded"
                checked={
                  filterInPit &&
                  filterInLap &&
                  filterOutLap &&
                  filterCaution &&
                  filterRestarts
                }
                indeterminate={
                  filterInPit ||
                  filterInLap ||
                  filterOutLap ||
                  filterCaution ||
                  filterRestarts
                }
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) {
                    setFilterInPit(true);
                    setFilterInLap(true);
                    setFilterOutLap(true);
                    setFilterCaution(true);
                    setFilterRestarts(true);
                  } else {
                    setFilterInPit(false);
                    setFilterInLap(false);
                    setFilterOutLap(false);
                    setFilterCaution(false);
                    setFilterRestarts(false);
                  }
                }}
              />
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="flex flex-col gap-y-0.5 text-sm">
          <div>
            <input
              title="Exclude In Pit Laps"
              type="checkbox"
              className="mr-1 rounded h-3 w-3"
              checked={filterInPit}
              onChange={e => setFilterInPit(e.target.checked)}
            />
            In Pit
          </div>
          <div>
            <input
              title="Exclude In Lap Laps"
              type="checkbox"
              className="mr-1 rounded h-3 w-3"
              checked={filterInLap}
              onChange={e => setFilterInLap(e.target.checked)}
            />
            In Lap
          </div>
          <div>
            <input
              title="Exclude Out Lap Laps"
              type="checkbox"
              className="mr-1 rounded h-3 w-3"
              checked={filterOutLap}
              onChange={e => setFilterOutLap(e.target.checked)}
            />
            Out Lap
          </div>
          <div>
            <input
              title="Exclude Caution Laps"
              type="checkbox"
              className="mr-1 rounded h-3 w-3"
              checked={filterCaution}
              onChange={e => setFilterCaution(e.target.checked)}
            />
            Cautions
          </div>
          <div>
            <input
              title="Exclude Restart Laps"
              type="checkbox"
              className="mr-1 rounded h-3 w-3"
              checked={filterRestarts}
              onChange={e => setFilterRestarts(e.target.checked)}
            />
            Restarts
          </div>
        </Disclosure.Panel>
      </Disclosure>
      <div className="p-1">
        <div className="font-bold mb-1">Drivers</div>
        <div className="flex gap-1 mb-1">
          <button
            onClick={() => {
              setFilterType("all");
            }}
            className={` text-white font-bold rounded grow ${
              selectedVehiclesFilter === "all"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
          >
            All
          </button>
          <button
            onClick={() => {
              setFilterType("detailOffset");
            }}
            className={` text-white font-bold rounded grow ${
              ["detailOffset", "detailPerLap"].includes(selectedVehiclesFilter)
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
          >
            Offset
          </button>
          <button
            onClick={() => {
              setFilterType("favorites");
            }}
            className={` text-white font-bold rounded grow ${
              selectedVehiclesFilter === "favorites"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
          >
            Faves
          </button>
          <button
            className={` text-white font-bold rounded grow ${
              selectedVehiclesFilter === "manual"
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-800 hover:bg-gray-700"
            }`}
            onClick={() => {
              setSelectedVehicles(filteredVehicles ?? []);
              setFilterType("manual");
            }}
          >
            Man.
          </button>
        </div>
        {["detailOffset", "detailPerLap"].includes(selectedVehiclesFilter) && (
          <div>
            <div>
              <button
                onClick={() => {
                  selectedVehiclesFilter === "detailOffset"
                    ? setFilterType("detailPerLap")
                    : setFilterType("detailOffset");
                }}
                className={` text-white font-bold rounded grow ${
                  ["detailPerLap"].includes(selectedVehiclesFilter)
                    ? "bg-gray-600 hover:bg-gray-500"
                    : "bg-gray-800 hover:bg-gray-700"
                }`}
              >
                Per Lap
              </button>
            </div>
            <div>
              <FontAwesomeIcon icon={faArrowUp} fixedWidth />
              <input
                title="Ahead"
                type="text"
                className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[3ch] text-right inline-block mx-1"
                value={detailOffset.ahead || ""}
                onChange={e =>
                  setDetailOffset({
                    ...detailOffset,
                    ahead: parseInt(e.target.value),
                  })
                }
              />
              <FontAwesomeIcon icon={faArrowDown} fixedWidth />
              <input
                title="Behind"
                type="text"
                className="bg-gray-700 text-white py-0 px-1 border-none rounded w-[3ch] text-right inline-block mx-1"
                value={detailOffset.behind || ""}
                onChange={e =>
                  setDetailOffset({
                    ...detailOffset,
                    behind: parseInt(e.target.value),
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="flex flex-col">
          {vehiclesData
            .filter(v =>
              selectedVehiclesFilter === "manual"
                ? true
                : filteredVehicles?.includes(v.id)
            )
            .sort(
              (a, b) =>
                (!filteredVehicles?.includes(a.id) ? 1 : -1) -
                (!filteredVehicles?.includes(b.id) ? 1 : -1)
            )
            .map(vehicle => (
              <div
                key={vehicle.id}
                className={`text-xs cursor-pointer ${
                  filteredVehicles?.includes(vehicle.id) ? "opacity-100" : "opacity-50"
                }`}
                style={{
                  color: getContrastColor(vehicle.primaryColor, vehicle.secondaryColor),
                }}
                onClick={() => {
                  if (selectedVehiclesFilter === "manual") {
                    if (manualVehicles.includes(vehicle.id)) {
                      setSelectedVehicles(
                        manualVehicles.filter(
                          selectedVehicle => selectedVehicle !== vehicle.id
                        )
                      );
                    } else {
                      setSelectedVehicles([...manualVehicles, vehicle.id]);
                    }
                  }
                }}
              >
                <hr
                  className="inline-block w-5 my-auto mr-1 border-2 rounded"
                  style={{
                    borderColor: getContrastColor(
                      vehicle.primaryColor,
                      vehicle.secondaryColor
                    ),
                  }}
                />
                {vehicle.number} {vehicle.displayName}
              </div>
            ))}
        </div>
      </div>
    </Loading>
  );
};

export default FilterLaps;
