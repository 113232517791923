/** @format */

import { Dialog } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { Field, Form, Formik } from "formik";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import galaxieClient from "../../api/galaxieClient";
import { useRacesList, useStandings } from "../../api/racesApi";
import { useUser } from "../../api/userAPI";
import Loading from "../../utils/Loading";
import StandingsTable from "./StandingsTable";

const bgOpacity = [
  "",
  "bg-opacity-10",
  "bg-opacity-20",
  "bg-opacity-30",
  "bg-opacity-40",
];

interface StandingsProps {
  series: string;
  year: string;
}

const EditResultsModal: React.FC<{
  result: StandingsResult | undefined;
  onClose: () => void;
  series: string;
  year: string;
}> = ({ result, onClose, series, year }) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateResultAsync,
    status,
    error,
  } = useMutation({
    mutationFn: (result: Partial<StandingsResult>) =>
      galaxieClient.patch(`/results/${result.id}/`, result).then(res => res.data),
    onSuccess: () => {
      console.log("success");
      queryClient.invalidateQueries({ queryKey: ["standings", series, year] });
    },
    onError: () => {
      console.log("error");
    },
  });
  return (
    <Dialog open={!!result} onClose={onClose}>
      <div className="fixed inset-0 backdrop-blur-sm" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel>
          {result && (
            <div className="bg-black p-4">
              <Formik
                initialValues={{
                  pointsPenalty: result.pointsPenalty,
                  cucumbered: result.cucumbered,
                }}
                validate={values => {
                  const errors = {} as {
                    pointsPenalty?: string;
                  };
                  if (values.pointsPenalty < 0) {
                    errors.pointsPenalty = "Points penalty must be greater than 0";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  return updateResultAsync({ ...result, ...values }).then(() => {
                    setSubmitting(false);
                  });
                }}
              >
                {({ isSubmitting, values, errors, setFieldValue, setFieldTouched }) => (
                  <Form className="">
                    <div className="flex flex-col mb-2">
                      <div>
                        <label htmlFor="cucumbered">Cucumbered</label>
                        <div
                          onClick={() => {
                            setFieldValue("cucumbered", !values.cucumbered);
                            setFieldTouched("cucumbered", true);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "50px",
                            height: "25px",
                            background: values.cucumbered ? "green" : "#ccc",
                            borderRadius: "25px",
                            position: "relative",
                            transition: "background-color 0.3s",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "3px",
                              left: values.cucumbered ? "27px" : "3px",
                              width: "19px",
                              height: "19px",
                              background: "white",
                              borderRadius: "50%",
                              transition: "left 0.3s",
                            }}
                          />
                        </div>{" "}
                      </div>
                      <div>
                        <label className="block" htmlFor="pointsPenalty">
                          Points Penalty
                        </label>
                        <div className="mt-1">
                          <Field
                            type="number"
                            name="pointsPenalty"
                            className="bg-black appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                          />
                        </div>
                        {errors.pointsPenalty && (
                          <div className="text-red-500">{errors.pointsPenalty}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between gap-x-5">
                      <button
                        onClick={onClose}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-green-500 text-white px-2 py-1 rounded"
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </div>
                    {/* {status === "error" && <div className="text-red-500">{error}</div>} */}
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const Standings: React.FC<StandingsProps> = ({ series, year }) => {
  const [editingResults, setEditingResults] = useState<[string, string] | null>(null);
  const { data: { isStaff } = {} } = useUser();
  const {
    data: schedule,
    status: scheduleStatus,
    error: scheduleError,
  } = useRacesList(series.replace("series_", ""), year);
  const {
    data: standings,
    status: standingsStatus,
    error: standingsError,
  } = useStandings(series.replace("series_", ""), year);
  const sortLeader = useMemo(
    () =>
      (
        rowA: any,
        rowB: any,
        columnId: string,
        desc: boolean // ToDo fix any
      ) =>
        rowA.values[columnId] === 0
          ? 1
          : rowA.values[columnId] > rowB.values[columnId]
          ? 1
          : -1,
    []
  );
  const sortPlayoffPosition = useMemo(
    () =>
      (
        rowA: any,
        rowB: any,
        columnId: string,
        desc: boolean // ToDo fix any
      ) =>
        rowA.values[columnId] === 0
          ? 1
          : rowA.values[columnId] > rowB.values[columnId]
          ? 1
          : -1,
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Driver",
        accessor: "displayName",
        className: "text-left whitespace-nowrap px-1",
        disableSortBy: true,
        Cell: ({
          row: {
            original: { clinchedPlayoffs, tentativeInPlayoffs },
          },
          value,
        }: {
          row: {
            original: Standings;
          };
          value: string;
        }) => (
          <span
            className={
              clinchedPlayoffs
                ? "text-yellow-300"
                : tentativeInPlayoffs
                ? "text-green-600"
                : ""
            }
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Pos",
        accessor: "position",
        sortInverted: true,
        sortDescFirst: true,
        className: "text-right",
        ff: true,
      },
      {
        Header: "Playoff Pos",
        accessor: "playoffRank",
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(2, "\u2007"),
        ff: true,
        sortInverted: true,
        sortDescFirst: true,
        sortType: sortPlayoffPosition,
      },
      {
        Header: "Points",
        ff: true,
        accessor: "points",
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(3, "\u2007"),
        sortDescFirst: true,
      },
      {
        Header: "Proj. PO Points",
        ff: true,
        accessor: "projectedPlayoffPoints",
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(3, "\u2007"),
        sortDescFirst: true,
      },
      {
        Header: "To Leader",
        ff: true,
        accessor: "deltaLeader",
        sortDescFirst: true,
        sortType: sortLeader,
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(4, "\u2007"),
      },
      {
        Header: "To Playoffs",
        ff: true,
        accessor: "deltaPlayoffs",
        disableSortBy: true, // ToDo Figure out how to sort this correctly (winners have 0 )
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value >= 0 ? "-" : value?.toFixed(0)?.padStart(4, "\u2007"),
      },

      {
        Header: "To Next",
        ff: true,
        accessor: "deltaNext",
        disableSortBy: true, //this makes no sense to sort
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(3, "\u2007"),
      },
      {
        Header: "Stage Points",
        ff: true,
        accessor: "stagePoints",
        sortDescFirst: true,
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(3, "\u2007"),
      },
      {
        Header: "Playoff Points",
        ff: true,
        accessor: "playoffPoints",
        className: "text-center",
        Cell: ({ value }: { value: number }) =>
          value?.toFixed(0)?.padStart(3, "\u2007"),
        sortDescFirst: true,
      },
      ...Object.values(
        groupBy(
          schedule
            ?.filter(
              ({ pointsRace, playoffRound }) => pointsRace && playoffRound !== null
            )
            ?.map(({ playoffRound, id, name, trackName, inspectionComplete }) => ({
              playoffRound: playoffRound,
              Header: (
                <>
                  <div className="text-[.8rem] leading-none max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-right">
                    {name}
                  </div>
                  <div className="text-[.6rem] font-light leading-none max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-right">
                    {trackName.replace(/^\s+|\s+$/g, "")}
                  </div>
                </>
              ),
              ff: true,
              id: id,
              className:
                playoffRound > 0
                  ? `bg-yellow-400 ${bgOpacity[playoffRound]} text-center`
                  : "bg-green-500 bg-opacity-10 text-center",
              accessor: ({ results }: Standings) =>
                results.find(({ raceId }) => raceId === id) || {},
              disableSortBy: true,
              Cell: ({
                value: {
                  id,
                  winner,
                  pointsEarned,
                  stage_1Winner,
                  stage_2Winner,
                  stage_3Winner,
                  stage_1Points,
                  stage_2Points,
                  stage_3Points,
                  stage_1Position,
                  stage_2Position,
                  stage_3Position,
                  finishPosition,
                  cucumbered,
                },
                row: {
                  original: { id: standingsId },
                },
              }: {
                value: StandingsResult;
                row: any;
              }) => (
                <Tippy
                  placement="top"
                  touch={false}
                  content={
                    inspectionComplete &&
                    (finishPosition ? (
                      <div className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                        {cucumbered ? (
                          <>
                            <span className="text-red-500 text-xs font-bold">
                              Encumbered
                            </span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {`Race: p${finishPosition} | ${pointsEarned} pts`}
                        <br />
                        {`Stage 1: p${
                          stage_1Position || finishPosition
                        } | ${stage_1Points} pts`}
                        <br />
                        {`Stage 2: p${
                          stage_2Position || finishPosition
                        } | ${stage_2Points} pts`}
                        <br />
                        {stage_3Position !== finishPosition
                          ? `Stage 3: p${
                              stage_3Position || finishPosition
                            } | ${stage_3Points} pts`
                          : ""}
                      </div>
                    ) : (
                      <div className="text-sm p-2 bg-gray-900 text-white bg-opacity-80">
                        Did not race
                      </div>
                    ))
                  }
                >
                  <button
                    onClick={() => {
                      if (isStaff) {
                        setEditingResults([standingsId, id]);
                      }
                    }}
                    className={`whitespace-nowrap
                ${
                  winner
                    ? "text-green-500"
                    : stage_1Winner || stage_2Winner || stage_3Winner
                    ? "text-blue-500"
                    : ""
                }
                ${winner ? "font-bold" : ""}
                ${stage_1Winner || stage_2Winner || stage_3Winner ? "italic" : ""}
                `}
                  >
                    {inspectionComplete && finishPosition ? pointsEarned || "0" : "-"}
                    {cucumbered ? (
                      <span className="align-super text-red-500 text-xs font-bold">
                        🥒
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </Tippy>
              ),
            })),
          "playoffRound"
        )
      )?.map((columns, idx) => ({
        Header: idx === 0 ? "Regular Season" : `Rd. ${idx}`,
        id: `playoff_${idx}`,
        className:
          idx > 0
            ? `bg-yellow-400 bg-opacity-${idx * 10} whitespace-nowrap`
            : "bg-green-500 bg-opacity-10 whitespace-nowrap",
        columns,
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedule]
  );
  return (
    <Loading
      statuses={[scheduleStatus, standingsStatus]}
      errors={[scheduleError, standingsError]}
      wrap={false}
    >
      <EditResultsModal
        result={standings
          ?.find(({ id }) => id === editingResults?.[0])
          ?.results.find(({ id }) => id === editingResults?.[1])}
        series={series}
        year={year}
        onClose={() => setEditingResults(null)}
      />
      <StandingsTable
        data={standings || []}
        columns={columns}
        initialState={{
          sortBy: [
            {
              id: "position",
              desc: true,
            },
          ],
        }}
        tableClassName="rotated-header text-[.8rem] font-mono max-w-screen w-screen overflow-x-auto overflow-y-auto min-h-full"
      />
    </Loading>
  );
};

export default Standings;
