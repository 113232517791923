/** @format */

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useActiveFeatureFlags } from "posthog-js/react";
import { useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { resetPasswordConfirm, useUser } from "../api/userAPI";

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();
  const flags = useActiveFeatureFlags();
  const { data: user = {} } = useUser();
  const [success, setSuccess] = useState(false);
  if (user.id || !uid || !token || !flags?.includes("login"))
    return <Navigate to="/" replace />;
  return success ? (
    <div className="absolute inset-0 flex items-center justify-center bg-black">
      <div className="self-center content-center bg-gray-800 rounded-xl p-4 w-80 max-w-full ">
        <div className="mt-2 bg-gray-800 p-4 rounded">
          <h1 className="text-xl mb-2">Password Reset Successfully!</h1>
          <Link
            to="/?modal=login"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="absolute inset-0 flex items-center justify-center bg-black">
      <div className="bg-gray-800 rounded-xl p-4 shrink w-80">
        <h1 className="text-xl mb-2">Reset Password</h1>
        <Formik
          initialValues={{ password1: "", password2: "" }}
          validate={values => {
            const errors = {} as {
              password1?: string;
              password2?: string;
            };
            if (!values.password1) {
              errors.password1 = "Required";
            } else if (values.password1.length < 8) {
              errors.password1 = "Password must be at least 8 characters";
            }
            if (!values.password2) {
              errors.password2 = "Required";
            } else if (values.password2.length < 8) {
              errors.password2 = "Password must be at least 8 characters";
            }
            if (values.password1 !== values.password2) {
              errors.password2 = "Passwords must match";
            }
            return errors;
          }}
          onSubmit={({ password1, password2 }, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            setTimeout(() => {
              resetPasswordConfirm(uid, token, password1, password2)
                .then(() => setSuccess(true))
                .then(() => {
                  setSubmitting(false);
                })
                .catch(error => {
                  if (error.response) {
                    if (error.response.status === 400) {
                      if (error.response.data.newPassword1) {
                        setFieldError(
                          "password1",
                          error.response.data.password1.join("\n")
                        );
                      }
                      if (error.response.data.newPassword2) {
                        setFieldError(
                          "password2",
                          error.response.data.newPassword2.join("\n")
                        );
                      }
                      if (error.response.data.nonFieldErrors) {
                        setFieldError(
                          "password2",
                          error.response.data.nonFieldErrors.join("\n")
                        );
                      }
                      if (error.response.data.token || error.response.data.uid) {
                        setFieldError(
                          "password2",
                          "This link has expired. Please request a new one."
                        );
                      }
                    } else {
                      setFieldError("password2", "Something went wrong");
                    }
                  } else {
                    setFieldError("password2", "Something went wrong");
                  }
                  console.error(error);
                  setSubmitting(false);
                });
            }, 400);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form className="space-y-6">
              <div className="flex flex-row space-x-2">
                <div className="flex-grow">
                  <label
                    htmlFor="password1"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Field
                      type="password"
                      name="password1"
                      id="password1"
                      required
                      className="appearance-none block w-full px-3 py-2 border bg-black border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="password1"
                    component="div"
                    className="text-red-700"
                  />
                </div>
              </div>
              <div>
                <div className="flex-grow">
                  <label
                    htmlFor="password2"
                    className="block text-sm font-medium text-gray-200"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-1">
                    <Field
                      type="password"
                      name="password2"
                      id="password2"
                      required
                      className="appearance-none block w-full px-3 py-2 border bg-black border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cPurple-500 focus:border-cPurple-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="password2"
                    component="div"
                    className="text-red-700"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >
                  Update Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;
