/** @format */

import * as Sentry from "@sentry/react";
import axios from "axios";

export const API_HOST =
  import.meta.env.VITE_LOCAL === "True"
    ? `http://${import.meta.env.VITE_SERVER_HOST}:` + import.meta.env.VITE_SERVER_PORT
    : `https://${import.meta.env.VITE_SERVER_HOST}`;

const galaxieClient = axios.create({
  baseURL: `${API_HOST}/api/`,
  withCredentials: true,
  timeout: 1000 * 30,
  responseType: "json",
  responseEncoding: "utf8",
  headers: {
    "Content-Type": "application/json",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

const authToken = localStorage.getItem("accessToken");

if (authToken) {
  galaxieClient.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
}

export const toCamelCase = (
  data: { [key: string]: any } | Array<any>
): { [key: string]: any } | Array<any> => {
  if (Array.isArray(data)) {
    return data.map(toCamelCase);
  } else if (data !== null && data.constructor === Object) {
    return Object.keys(data).reduce(
      (result: { [key: string]: any }, key) => ({
        ...result,
        [key.replace(/([-_][a-z])/g, group =>
          group.toUpperCase().replace("-", "").replace("_", "")
        )]: toCamelCase(data[key]),
      }),
      {}
    );
  }
  return data;
};

const toSnakeCase = (
  data: { [key: string]: any } | Array<any>
): { [key: string]: any } | Array<any> => {
  if (Array.isArray(data)) {
    return data.map(toSnakeCase);
  } else if (data !== null && data.constructor === Object) {
    return Object.keys(data).reduce(
      (result, key) => ({
        ...result,
        [key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)]: toSnakeCase(
          data[key]
        ),
      }),
      {}
    );
  }
  return data;
};

// Add a request interceptor and snakeCase POST data (for django)
galaxieClient.interceptors.request.use(
  config => {
    if (
      (config.method === "post" ||
        config.method === "put" ||
        config.method === "patch") &&
      config.data
    ) {
      config.data = toSnakeCase(config.data);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

galaxieClient.interceptors.response.use(
  response => {
    response.data = toCamelCase(response.data);
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const refresh = localStorage.getItem("refreshToken");
    console.error(error);
    if (
      error?.response?.status === 401 &&
      !originalRequest?._retry &&
      refresh &&
      error?.config?.url !== "/token/refresh/"
    ) {
      originalRequest._retry = true;
      try {
        const response = await galaxieClient.post("/token/refresh/", {
          refresh: localStorage.getItem("refreshToken"),
        });
        const { access, refresh: refresh_1 } = response.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh_1);
        originalRequest.headers["Authorization"] = `Bearer ${access}`;
        galaxieClient.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        return await galaxieClient(originalRequest);
      } catch (error_1: any) {
        if (error_1?.response?.status === 401) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          galaxieClient.defaults.headers.common["Authorization"] = null;
        }
        return await Promise.reject(error_1);
      }
    } else if (error?.response?.status === 401 && originalRequest?._retry) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      galaxieClient.defaults.headers.common["Authorization"] = null;
    } else {
      Sentry.captureException(error);
    }
    if (error?.response?.data) {
      error.response.data = toCamelCase(error.response.data);
    }
    return Promise.reject(error);
  }
);

export default galaxieClient;
